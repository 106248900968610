import React, {Component} from 'react';
import {connect} from 'react-redux';
import LastView from "../../../last-view/LastView";
import {medicineTypes} from "../../../../../constans/medicine";
import {endGrafting} from "../../../../../api/events/Grafting";
import {compose} from "redux";
import {Trans, withTranslation} from "react-i18next";
import moment from "moment";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        medicines: state.dictionary.medicine
    };
}

class RfidGraftingUnfinished extends Component {

    state = {
        show: false,
        error: null
    }

    componentDidMount() {
        const {location: {state}, farm} = this.props;
        if (!state || !this.getMedicine()) {
            this.props.history.push(`/${farm}/mobile`);
        }
    }

    getMedicine() {
        const {location: {state: {events}}, medicines} = this.props;
        let event = events[0];
        let medID = event.EvData.Medicine;
        return medicines.find(item => item.WordID === medID);
    }

    getStartTime() {
        const {location: {state: {events}}} = this.props;
        let event = events[0];
        return event.EvData.StartTime;
    }

    getName() {
        const {match: {params: {medicineType}}, t} = this.props;
        switch (+medicineType) {
            case medicineTypes.DOSATRON:
                return t("dosatron");
            case medicineTypes.FORAGE:
                return t("medicalFeed");
            default:
                return "";
        }
    }

    getLink() {
        const {match: {params: {medicineType}}} = this.props;
        switch (+medicineType) {
            case medicineTypes.DOSATRON:
                return "dosatron";
            case medicineTypes.FORAGE:
                return "forage";
            default:
                return "";
        }
    }

    getBreadcrumbs() {
        const {location: {state}, farm, t} = this.props;
        const {location} = state;
        let array = [{name: t("treatment"), link: `/${farm}/mobile/events/grafting`}, {
            name: this.getName(),
            link: `/${farm}/mobile/events/grafting/${this.getLink()}`
        }, {name: location.CName}];
        return array;
    }

    onSubmit = (startTime) => {
        const {location: {state: {events}}} = this.props;
        let data = events.map(event => ({
            EvID: event.EvID,
            EndTime: startTime
        }));
        return endGrafting(data).then(res => {
        }).catch(e => {
            this.setState({
                show: true,
                error: 0
            })
            throw e;
        })
    }

    onCloseClick = () => {
        this.setState({
            show: false
        })
    }

    getError() {
        const {t} = this.props;
        const {error} = this.state;
        switch (error) {
            case 0:
                return t("rfidApp.endGraftingGeneralError");
            default:
                return "";
        }
    }

    render() {
        const {location: {state}, farm, match: {params: {medicineType}}} = this.props;
        if (!state) return null;
        let medicine = this.getMedicine();
        if (!medicine) return null;
        const {show} = this.state;
        return (
            <LastView breadcrumbs={this.getBreadcrumbs()}
                      text={<Trans i18nKey={"rfidApp.endGraftingText"} values={{
                          medicine: medicine.WData.Name,
                          date: moment(this.getStartTime()).format("DD.MM.YYYY")
                      }} components={{bold: <strong/>}}/>}
                      animals={[]} eventType={"grafting"}
                      onSubmit={this.onSubmit} submitPath={`/${farm}/mobile/events/grafting/${medicineType}/add`}
                      additionalState={state} closePath={`/${farm}/mobile/locationScanner`}
                      closeError={this.onCloseClick} showError={show} error={this.getError()}/>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RfidGraftingUnfinished);