import React, {Component} from 'react';
import {connect} from 'react-redux';
import LastView from "../../last-view/LastView";
import {withTagReader} from "../../tag-reader-hoc/withTagReader";
import Carousel from "../../carousel/Carousel";
import "./_transfer-chamber.scss"
import CarouselItem from "../../carousel/CarouselItem";
import devicesDB from "../../../../database/devicesDB";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import {createTransfer} from "../../../../api/events/Transfer";
import buildingsDB from "../../../../database/buildingsDB";
import {withTranslation} from "react-i18next";
import {SectorType} from "../../../../constans/sectorTypes";
import moment from "moment";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        user: state.user.user
    };
}

class RfidTransferChamber extends Component {

    state = {
        animals: this.props.location.state ? [...this.props.location.state.animals] : [],
        page: 0,
        show: false,
        error: null,
        errorAddedAnimal: null,
        errorTag: null
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(this.props);
        if (this.props.tagReader.tag !== prevProps.tagReader.tag && this.props.tagReader.tag) {
            if (this.props.tagReader.animal) {
                const {location: {state: {location}}, tagReader: {animal}} = this.props;
                if (animal.PlcmntID === location.CID || (Array.isArray(animal.PlcmntID) && animal.PlcmntID.find(item => item.PlcmntID === location.CID))) {
                    this.setState({
                        show: true,
                        error: 3,
                        errorAddedAnimal: animal
                    })
                } else {
                    if (this.state.animals.filter(item => item.AnmID === animal.AnmID).length === 0) {
                        this.setState({
                            animals: [...this.state.animals, animal],
                            show: false,
                            page: this.state.animals.length % 15 === 0 ? this.state.page + 1 : this.state.page
                        });
                        this.props.tagReader.clearTag();
                    } else {
                        this.setState({
                            show: true,
                            error: 1,
                            errorAddedAnimal: animal
                        })
                    }
                }
            } else {
                this.setState({
                    show: true,
                    error: 2,
                    errorTag: this.props.tagReader.tag
                })
            }
        }
    }

    componentDidMount() {
        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.replace(`/${farm}/mobile`);
        }
    }

    onPageChange = page => {
        this.setState({
            page
        })
    };

    sliceAnimals(animals) {
        let array = [];
        for (let i = 0; i < animals.length; i += 15) {
            array.push(animals.slice(i, i + 15));
        }
        return array;
    }

    onSubmit = (startTime, clickedButton) => {
        const {location: {state: {location}}} = this.props;
        console.log(location);
        const {animals} = this.state;
        let devicesNewPlcmnt = devicesDB.getDevicesInPlcmnt(location, {showDevicesInChildren: false}).map(device => ({
            DevID: device.DevID,
            GwID: device.GatewayID
        }));
        let data = animals.map(animal => {
            let currentLocation = Array.isArray(animal.PlcmntID) ? animal.PlcmntID[0].PlcmntID : animal.PlcmntID;
            let loc = buildingsDB.getLocationByID(currentLocation);
            let oldDevices = devicesDB.getDevicesInPlcmnt(loc, {showDevicesInChildren: false}).map(dev => ({
                DevID: dev.DevID,
                GwID: dev.GatewayID
            }));
            let oldTree = buildingsDB.getTreeByLocationID(currentLocation);
            return {
                AnmID: animal.AnmID,
                transferTo: location.CID,
                amount: animal.AnmCnt,
                from: currentLocation,
                stand: false,
                DevIDsNewPlcmnt: devicesNewPlcmnt,
                DevIDsOldPlcmnt: oldDevices,
                GrID: getGrIDByAnimal(animal, startTime.getTime()),
                EvTime: startTime.getTime(),
                clearFeeding: true,
                feeding: oldTree.sector && oldTree.sector.SType === SectorType.DELIVERY ? {
                    curveNumber: 0,
                    doseCorrection: 0,
                    curveStart: +moment().startOf("day"),
                    punishment: 0,
                    stage: 0
                } : undefined
            }
        });
        console.log(data);
        return createTransfer(data).then(() => {
            this.props.dispatch({type: "ADD_TRANSFER", payload: data});
        }).catch(e => {
            this.setState({
                show: true,
                error: 0
            })
            throw e;
        })
    };

    onCloseClick = () => {
        this.setState({
            show: false
        });
        this.props.tagReader.clearTag();
    }

    getError() {
        const {t} = this.props;
        const {error, errorAddedAnimal, errorTag} = this.state;
        switch (error) {
            case 0:
                return t("transferFailure");
            case 1:
                return t("rfidApp.alreadyAddedAnimal", {AnmNo1: errorAddedAnimal.AnmNo1});
            case 2:
                return t("rfidApp.animalWithNumberNotFound", {errorTag});
            case 3:
                return t("rfidApp.animalAlreadyInLocation", {AnmNo1: errorAddedAnimal.AnmNo1});
            default:
                return "";
        }
    }

    getErrorType() {
        const {error} = this.state;
        switch (error) {
            case 1:
                return "info";
            case 2:
                return "warning";
            default:
                return "error";
        }
    }

    render() {
        const {location: {state}, farm, t} = this.props;
        if (!state) return null;
        const {animals, page, show} = this.state;
        const {status, location} = state;
        let sliced = this.sliceAnimals(animals);
        console.log(this.props);
        return (
            <LastView
                breadcrumbs={[{name: t("transfer"), link: `/${farm}/mobile/events/transfer`}, {name: t("pigPen")}]}
                text={
                    <div className="transfer-chamber">
                        <small>{t("rfidApp.scannedAmount")}: {animals.length}</small>
                        <Carousel page={page} onPageChange={this.onPageChange} title={t("rfidApp.scannedAnimals") + ":"}>
                            {
                                sliced.map((row, key) =>
                                    <CarouselItem key={key}>
                                        <div className="animals">
                                            {
                                                row.map((animal, key2) =>
                                                    <div key={key2}
                                                         className={key === sliced.length - 1 && key2 === row.length - 1 ? "font-weight-bold" : null}>
                                                        [<span>{animal.AnmNo1}</span>]
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </CarouselItem>
                                )
                            }
                        </Carousel>
                        <div>
                            {t("rfidApp.transferToChamber")} <span
                            className="font-weight-bold">{location.CName}</span>?
                        </div>
                    </div>
                }
                additionalText={t("rfidApp.transferToChamberAdditionalText")}
                animals={animals} eventType={"transfer"} status={status}
                additionalState={{
                    advancedAnimalScanner: true,
                    onlyLocationScanner: true,
                    animalScannerNextPath: `/${farm}/mobile/locationScanner`,
                    chamberChooser: true,
                    onlyAnimalScanner: true
                }} backPath={`/${farm}/mobile/locationScanner`} className="transfer"
                onSubmit={this.onSubmit} submitOnlyOnButton counterName="transfer" closeError={this.onCloseClick}
                error={this.getError()} showError={show} errorType={this.getErrorType()}/>
        );
    }
}

RfidTransferChamber = withTagReader({
    findAnimalInLocation: true
})(RfidTransferChamber);

RfidTransferChamber = withTranslation()(RfidTransferChamber);

export default connect(
    mapStateToProps,
)(RfidTransferChamber);