import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./_drawer.scss"
import Hammer from "hammerjs"

class Drawer extends Component {

    drawer = React.createRef();

    componentDidMount() {
        document.addEventListener("mousedown", this.onOutsideClick);
        let hammerTime = new Hammer(this.drawer.current);
        hammerTime.on("panleft panend panright", this.handleSwipe)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onOutsideClick);
    }

    handleSwipe = (event) => {
        if (event.type === "panend") {
            this.drawer.current.classList.add("animate-transform-x");
            if (this.drawer.current.style.left !== "-18px") {
                this.props.onCloseClick();
            }
            this.drawer.current.style.left = null;
        } else {
            this.drawer.current.classList.remove("animate-transform-x");
            let angle = Math.abs(event.angle);
            if (angle > 135 || angle < 45) {
                this.drawer.current.style.left = (event.deltaX > -18 ? -18 : event.deltaX) + "px";
            }
        }
    };

    onOutsideClick = e => {
        const {show, onCloseClick} = this.props;
        if (show && !this.drawer.current.contains(e.target)) {
            onCloseClick();
        }
    };

    render() {
        const {show, children, onCloseClick} = this.props;
        return (
            <div className={`drawer animate-transform-x ${show ? " show" : ""}`} ref={this.drawer}>
                <div className="chooser-search-close" onClick={onCloseClick}>
                    <i className="far fa-chevron-left"/>
                </div>
                {children}
            </div>
        );
    }
}

Drawer.propTypes = {
    show: PropTypes.bool,
    onCloseClick: PropTypes.func.isRequired
};

export default Drawer;