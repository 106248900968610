import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import RfidHeader from "../rfid-header/RFIDHeader";
import buildingsDB from "../../../database/buildingsDB";
import NotFound from "../../NotFound";
import {getLocationName} from "../../../utils/BuildingUtils";
import TableGrid from "../../../components/basics/table-grid/TableGrid";
import animalsDB from "../../../database/animalsDB";
import {Fade, ProgressBar} from "react-bootstrap";
import {getColorForPercentage} from "../../../utils/ColorUtils";
import "../events/search/_search.scss"
import {getUsageForPig} from "../../../IOT/device-functions/GatewayFunctions";
import {DevType} from "../../../constans/devices";
import {findIndex} from "lodash";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import "./_location-information.scss"
import Button from "../../../components/basics/button/Button";
import RfidLocationClimateChangesModal from "./RFIDLocationClimateChangesModal";
import devicesDB from "../../../database/devicesDB";
import NewIOT from "../../../IOT/NewIOT"
import {isUsingFakeData} from "../../../utils/SettingsUtils";
import fakeData from "../../../demo/feeding/getUsageForPig.json";
import sinon from "sinon";
import {
    fakeCreateAndSendMessageObject,
    fakeStartSendingDeviceState,
    refresh
} from "../../../demo/climate/fakeFunctions";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        gateways: state.farmDevices.devices.filter(item => item.DevType === DevType.GATEWAY),
        shadows: state.shadows.shadows
    };
}

class LocationInformation extends Component {

    state = {
        location: buildingsDB.getLocationByID(this.props.match.params.locationID),
        animals: animalsDB.getAllAnimalsByPlcmntID(this.props.match.params.locationID, {joinEvents: false})
            .map(animal => ({animal})),
        loading: true,
        show: false,
        climate: null
    }

    messages = {};

    constructor(props) {
        super(props);
        const {match: {params: {locationID}}} = this.props;
        const {animals} = this.state;
        let data = animals.map(row => {
            const {animal} = row;
            return {
                AnmID: animal.AnmID,
                PlcmntID: Array.isArray(animal.PlcmntID) ? animal.PlcmntID[0].PlcmntID : animal.PlcmntID,
                RFID: animal.RFID
            }
        });
        console.log(data);
        if (isUsingFakeData()) {
            this.messages.fake = true;
            setTimeout(() => {
                let array = [];
                for (let i = 0; i < animals.length; i++) {
                    array[i] = {
                        ...animals[i],
                        feeding: fakeData[i % fakeData.length].feeding
                    }
                }
                this.setState({
                    animals: this.sortArray(array),
                    loading: false
                });
            }, 1000)
        } else {
            for (let gw of this.props.gateways) {
                let id = getUsageForPig(gw, data, this.onGetUsageForPigSuccess, this.onGetUsageForPigFailure);
                this.messages[id] = true;
            }
        }
        let devices = devicesDB.getDevicesInPlcmntID(locationID);
        let climate = devices.find(item => item.DevType === DevType.CLIMATE) || null;
        if (climate) {
            if (isUsingFakeData()) {
                sinon.restore();
                sinon.stub(NewIOT, "startSendingDeviceState").callsFake(fakeStartSendingDeviceState);
                sinon.stub(NewIOT, "createAndSendMessageObject").callsFake(fakeCreateAndSendMessageObject);
                this.timer = setInterval(() => {
                    refresh([climate])
                }, 7 * 1000)
            }
            NewIOT.startSendingDeviceState([climate]);
        }
        this.state = {
            ...this.state,
            climate
        }
    }

    sortFunction(a, b) {
        if (!a.feeding) return 1;
        if (!b.feeding) return -1;
        let percentA = a.feeding.got / a.feeding.amount;
        let percentB = b.feeding.got / b.feeding.amount;
        return percentA - percentB;
    }

    sortArray(data) {
        return data.sort((a, b) => this.sortFunction(a, b));
    }

    onGetUsageForPigSuccess = msg => {
        console.log(msg);
        const {CAnsw} = msg;
        const animals = this.state.animals.slice(0);
        for (let row of CAnsw) {
            let index = findIndex(animals, o => o.animal.AnmID === row.AnmID);
            animals[index] = {
                ...animals[index],
                feeding: row.feeding
            }
        }
        this.messages[msg.MsgId] = false;
        this.setState({
            animals: this.sortArray(animals),
            loading: false
        })
    }

    onGetUsageForPigFailure = (error, msg) => {
        this.messages[msg.MsgId] = false;
        let hasLoading = false;
        for (let id in this.messages) {
            if (this.messages[id]) hasLoading = true;
        }
        if (!hasLoading) {
            this.setState({
                loading: false
            })
        }
    }

    feedingFormatter = value => {
        if (!value || !value.amount) return "-";
        let percent = (value.got / value.amount) * 100;
        return <ProgressBar now={percent}>
            <div role="progressbar" className={`progress-bar ${getColorForPercentage(percent)}`} aria-valuenow={percent}
                 aria-valuemin={0} aria-valuemax={100} style={{width: `${percent}%`}}/>
            <span>{percent.toFixed(1)}%</span>
        </ProgressBar>
    }

    onRowClick = row => {
        const {farm} = this.props;
        this.props.history.push({pathname: `/${farm}/mobile/information/${row.animal.AnmID}`, state: {back: true}});
    }

    onTemperatureClick = () => {
        this.setState({
            show: true
        })
    }

    onCloseClick = () => {
        this.setState({
            show: false
        })
    }

    getTemperature() {
        const {climate} = this.state;
        const {shadows} = this.props;
        try {
            let shadow = shadows.get(climate.DevID);
            return <>{shadow.ambientConditions.averageCurrentAirTemperature}&#176;C</>
        } catch (e) {
            return <>-&#176;C</>
        }
    }

    render() {
        const {shadows, t} = this.props;
        const {location, animals, loading, show, climate} = this.state;
        if (!location) return <NotFound/>;
        let shadow = shadows.get(climate ? climate.DevID : "");
        const headers = [
            {
                name: t("animalNumber"),
                field: "animal.AnmNo1"
            },
            {
                name: t("today"),
                field: "feeding[0]",
                valueFormatter: this.feedingFormatter,
                itemClassName: "d-flex"
            },
            {
                name: "-1",
                field: "feeding[1]",
                valueFormatter: this.feedingFormatter,
                itemClassName: "feeding",
                headerClassName: "feeding"
            },
            {
                name: "-2",
                field: "feeding[2]",
                valueFormatter: this.feedingFormatter,
                itemClassName: "feeding",
                headerClassName: "feeding"
            },
            {
                name: "-3",
                field: "feeding[3]",
                valueFormatter: this.feedingFormatter,
                itemClassName: "feeding",
                headerClassName: "feeding"
            },
            {
                name: "-4",
                field: "feeding[4]",
                valueFormatter: this.feedingFormatter,
                itemClassName: "feeding",
                headerClassName: "feeding"
            },
            {
                name: "-5",
                field: "feeding[5]",
                valueFormatter: this.feedingFormatter,
                itemClassName: "feeding",
                headerClassName: "feeding"
            },
            {
                name: "-6",
                field: "feeding[6]",
                valueFormatter: this.feedingFormatter,
                itemClassName: "feeding",
                headerClassName: "feeding"
            }
        ]
        return (
            <div className="search location">
                <LoadingComponent isLoading={loading}/>
                <RfidHeader breadcrumbs={[{name: getLocationName(location)}]} home/>
                <TableGrid data={animals} headers={headers} onRowClick={this.onRowClick}/>
                {
                    climate &&
                    <Button className="climate-info" onClick={this.onTemperatureClick} buttonStyle={"text"}
                            disabled={!shadow}>
                        <i className="far fa-thermometer-half"/>
                        <span>{this.getTemperature()}</span>
                    </Button>
                }
                <Fade in={show} unmountOnExit mountOnEnter>
                    <div>
                        <RfidLocationClimateChangesModal onCloseClick={this.onCloseClick} climate={climate}/>
                    </div>
                </Fade>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(LocationInformation);