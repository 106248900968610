import React, {Component} from 'react';
import {connect} from 'react-redux';
import RFIDHeader from "../../rfid-header/RFIDHeader";
import InfoBox from "../../../../components/basics/info-box/InfoBox";
import Carousel from "../../carousel/Carousel";
import CarouselItem from "../../carousel/CarouselItem";
import "../../../../components/basics/mobile-app-link/_mobile-app-link.scss"
import Button from "../../../../components/basics/button/Button";
import "./_treatment.scss"
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        medicines: state.dictionary.medicine
    };
}

class RfidTreatmentMedicine extends Component {

    state = {
        page: 0,
        selectedMedicines: []
    };


    componentDidMount() {
        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        }
    }

    onPageChange = page => {
        this.setState({
            page
        })
    };

    splitReasons(medicines) {
        let slicedMedicines = [];
        for (let i = 0; i < medicines.length; i += 3) {
            slicedMedicines.push(medicines.slice(i, i + 3));
        }
        return slicedMedicines;
    }

    onMedicineClick(medicine) {
        let medicines = this.state.selectedMedicines.slice(0);
        if (!!medicines.find(item => item.WordID === medicine.WordID)) {
            medicines = medicines.filter(item => item.WordID !== medicine.WordID);
        } else {
            medicines.push(medicine);
        }
        this.setState({
            selectedMedicines: medicines
        })
    }

    isSelected(medicine) {
        return !!this.state.selectedMedicines.find(item => item.WordID === medicine.WordID)
    }

    onAcceptClick = () => {
        this.props.history.push({
            pathname: `/${this.props.farm}/mobile/events/treatment/reason`,
            state: {
                medicines: this.state.selectedMedicines,
                animals: this.props.location.state.animals
            }
        })
    }

    render() {
        const {medicines, farm, t} = this.props;
        const {page, selectedMedicines} = this.state;
        return (
            <div className="event treatment">
                <RFIDHeader breadcrumbs={[
                    {
                        name: t("scheduleOfTreatment"),
                        link: {
                            pathname: `/${farm}/mobile/scanner`,
                            state: {
                                breadcrumbs: [
                                    {
                                        name: t("scheduleOfTreatment")
                                    }
                                ],
                                eventType: "treatment",
                                status: "medicine"
                            }
                        }
                    },
                    {
                        name: "Lek"
                    }
                ]} home/>
                {
                    medicines.length === 0 &&
                    <InfoBox boxColor={"warning"}>{t("rfidApp.medicinesNotFound")}</InfoBox>
                }
                {
                    medicines.length > 0 &&
                    <Carousel page={page} onPageChange={this.onPageChange} title={t("rfidApp.chooseMedicine") + ":"}>
                        {
                            this.splitReasons(medicines).map((meds, index) =>
                                <CarouselItem key={index}>
                                    {
                                        meds.map((medicine, index2) =>
                                            <div
                                                className={`fetura-mobile-link${this.isSelected(medicine) ? " selected" : ""} active`}
                                                key={index2}
                                                onClick={() => this.onMedicineClick(medicine)}>{medicine.WData.Name}</div>
                                        )
                                    }
                                </CarouselItem>
                            )
                        }
                    </Carousel>
                }
                <Button buttonStyle={"text"} type={"button"} className="submit"
                        disabled={selectedMedicines.length === 0} onClick={this.onAcceptClick}>
                    <i className="far fa-check-circle"/>
                </Button>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RfidTreatmentMedicine);