import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./_group-events.scss"
import EventItem from "../event-item/EventItem";
import Card from "../../../../components/basics/card/Card";
import {withCookies} from "react-cookie";

class GroupEvents extends Component {

    getColumnsNumber() {
        const {cookies, items, edit} = this.props;
        if (edit) {
            return Math.ceil((items.length) / 2);
        }
        let currentState = cookies.get("eventItems") || {};
        let disabledEventList = {};
        for (let item of items) {
            let value = currentState[item.eventName];
            if (value === undefined) value = true;
            disabledEventList[item.eventName] = value;
        }
        let disabledEvents = Object.keys(disabledEventList).reduce((a, b) => a + +(!disabledEventList[b]), 0);
        return Math.ceil((items.length - disabledEvents) / 2);
    }

    render() {
        const {name, items, menuOpened, edit} = this.props;
        let columns = this.getColumnsNumber();
        if (columns === 0) return null;
        return (
            <Card className="group-events">
                <div className="group-events-name">{name}</div>
                <div className="group-events-icons"
                     style={{gridTemplateColumns: `repeat(${columns}, 10rem`}}>
                    {
                        items.map((event, key) =>
                            <EventItem eventName={event.eventName} name={event.name} icon={event.icon} key={key}
                                       disablePointerEvents={menuOpened} edit={edit} path={event.path}
                                       state={event.state}/>
                        )
                    }
                </div>
            </Card>
        );
    }
}

GroupEvents.propTypes = {
    name: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    menuOpened: PropTypes.bool,
    edit: PropTypes.bool,
};

export default withCookies(GroupEvents);