import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types"
import "./_event-item.scss"
import Checkbox from "../../../../components/basics/checkbox/Checkbox";
import {Fade} from "react-bootstrap";
import {withCookies} from "react-cookie";
import moment from "moment";
import {Link} from "react-router-dom";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class EventItem extends Component {

    state = {
        width: 0,
        show: true,
    };
    icon = React.createRef();

    constructor(props) {
        super(props);

        const {cookies, eventName} = this.props;
        let currentState = cookies.get("eventItems");
        if (currentState) {
            this.state = {
                width: 0,
                show: currentState[eventName] !== undefined ? currentState[eventName] : true
            }
        }
    }

    componentDidMount() {
        this.setState({
            width: this.getHeightOfIcon()
        });
        window.addEventListener("resize", this.onWindowResize);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.edit !== this.props.edit && !this.state.show) {
            this.setState({
                width: this.getHeightOfIcon()
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onWindowResize);
    }

    onWindowResize = () => {
        console.log("resize");
        this.setState({
            width: this.getHeightOfIcon()
        })
    };

    getHeightOfIcon() {
        if (this.icon.current) {
            return this.icon.current.clientHeight;
        }
        return 0;
    }

    getClassName() {
        const {disablePointerEvents, edit} = this.props;
        const {show} = this.state;
        let className = "event-item";
        if (disablePointerEvents) className += " no-pointer-events";
        if (edit && !show) className += " opacity-50";
        return className;
    }

    onClick = e => {
        this.setState(state => ({
            show: !state.show
        }), () => {
            const {cookies, eventName} = this.props;
            let currentState = cookies.get("eventItems") || {};
            currentState[eventName] = this.state.show;
            cookies.set("eventItems", currentState, {
                path: "/",
                expires: moment().add(1, "year").toDate()
            })
        });
        e.preventDefault();
    };

    renderData(addOnClick) {
        const {name, icon, edit} = this.props;
        const {width, show} = this.state;
        return (
            <div className={this.getClassName()} onClick={addOnClick ? this.onClick : null}>
                <div className="event-item-icon">
                    <div className="event-item-icon-container" ref={this.icon} style={{width}}>
                        <img src={icon} alt={name}/>
                        <Fade in={edit} unmountOnExit>
                            <div className="event-item-icon-container-checkbox">
                                <Checkbox label={""} checked={show}/>
                            </div>
                        </Fade>
                    </div>
                </div>
                <div className="event-item-title">
                    {name}
                </div>
            </div>
        )
    }

    render() {
        const {edit, farm, eventName, path, state, disablePointerEvents} = this.props;
        const {show} = this.state;
        if (!edit && !show) return null;
        if (!edit) return (
            <Link to={{
                pathname: path || `/${farm}/mobile/events/${eventName}`,
                state
            }} className={`event-link${disablePointerEvents ? " no-pointer-events" : ""}`}>
                {this.renderData(false)}
            </Link>
        )
        return this.renderData(true);
    }

}

EventItem.propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    eventName: PropTypes.string.isRequired,
    disablePointerEvents: PropTypes.bool,
    edit: PropTypes.bool,
    path: PropTypes.string,
};

EventItem = withCookies(EventItem);

export default connect(
    mapStateToProps,
)(EventItem);