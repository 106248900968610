import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import RFIDHeader from "../../rfid-header/RFIDHeader";
import {Col, Row} from "react-bootstrap";
import Button from "../../../../components/basics/button/Button";
import Loader from "../../loader/Loader";
import {withTagReader} from "../../tag-reader-hoc/withTagReader";
import ErrorComponent from "../../error/ErrorComponent";
import {changeRFID} from "../../../../api/animal/ChangeRFID";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RFIDChangeRFID extends Component {

    state = {
        show: false,
        error: null,
        tag: null,
        submitting: false,
        submitted: false
    }

    constructor(props) {
        super(props);

        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.tagReader.tag !== prevProps.tagReader.tag) {
            if (this.props.tagReader.animal) {
                this.setState({
                    show: true,
                    error: 1,
                    tag: null
                })
            } else if (this.props.tagReader.location) {
                this.setState({
                    show: true,
                    error: 2,
                    tag: null
                })
            } else {
                this.setState({
                    tag: this.props.tagReader.tag,
                    show: false
                })
            }
        }
    }

    onCloseClick = () => {
        this.setState({
            show: false
        })
    }

    getError() {
        const {t} = this.props;
        const {error} = this.state;
        switch (error) {
            case 0:
                return t("rfidApp.changeRFIDGeneralError");
            case 1:
                return t("rfidApp.rfidAlreadyAssignedToAnimal");
            case 2:
                return t("rfidApp.rfidAlreadyAssignedToLocation");
            default:
                return "";
        }
    }

    onSaveClick = () => {
        this.setState({
            submitting: true,
            submitted: false
        });
        const {location: {state: {animals}}, farm} = this.props;
        let animal = animals[0];
        changeRFID(animal.AnmID, this.state.tag).then(res => {
            this.setState({
                submitted: true,
                submitting: false
            }, () => {
                setTimeout(() => {
                    this.props.history.replace(`/${farm}/mobile`);
                }, 1500)
            })
        }).catch(e => {
            this.setState({
                submitting: false,
                show: true,
                error: 0
            });
        })
    }

    onBackClick = () => {
        this.props.history.goBack();
    }

    render() {
        const {location: {state}, t} = this.props;
        if (!state) return null;
        const {show, tag, submitting, submitted, error} = this.state;
        const {animals} = state;
        let animal = animals[0];
        return (
            <div className="last-view">
                <RFIDHeader breadcrumbs={[
                    {
                        name: t("rfidApp.lostEarringTitle"),
                        onClick: this.onBackClick
                    },
                    {
                        name: animal.AnmNo1
                    }
                ]}/>
                <Row className="justify-content-center h-100">
                    <Col md={10} className="d-flex flex-column justify-content-center">
                        <h4 className="main-text">
                            {
                                !tag && t("rfidApp.lostEarringScanAnimal")
                            }
                            {
                                tag && t("rfidApp.lostEarring", {tag})
                            }
                        </h4>
                    </Col>
                </Row>
                <div className="last-view-close" onClick={this.onBackClick}>
                    <i className="far fa-times-circle"/>
                </div>
                {/*<Link  to={backButtonData} replace>*/}
                {/*</Link>*/}
                <Button className="last-view-save" buttonStyle={"text"} disabled={!tag || submitting || submitted}
                        onClick={this.onSaveClick}>
                    <Loader loading={submitting} success={submitted}/>
                </Button>
                <ErrorComponent onCloseClick={this.onCloseClick} error={this.getError()} show={show}
                                type={error === 0 ? "error" : "warning"}/>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withTagReader()
)(RFIDChangeRFID);