import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./_event-main-view.scss";
import {connect} from 'react-redux';
import RfidHeader from "../rfid-header/RFIDHeader";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {};
}

class EventMainView extends Component {
    render() {
        const {breadcrumbs, children, additionalData, secondPart, t} = this.props;
        return (
            <div className="event-main-view">
                <RfidHeader breadcrumbs={breadcrumbs} secondPart={secondPart} home/>
                <div className="event-main-view-container">
                    <div className="event-main-view-buttons">
                        {children}
                    </div>
                    {
                        additionalData &&
                        <div className="event-main-view-additional-data">{additionalData}</div>
                    }
                    {
                        !additionalData &&
                        <div className="event-main-view-text">
                            <h4>{t("rfidApp.chooseMode")}</h4>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

EventMainView.propTypes = {
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        link: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    })).isRequired,
    secondPart: PropTypes.string,
    additionalData: PropTypes.node
};

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(EventMainView);