import {SubmissionError} from "redux-form";
import {endGrafting} from "../../../../../api/events/Grafting";

export function submit(values, dispatch, props) {
    console.log(values);
    let errors = validate(values, props);
    if (errors._error) {
        throw new SubmissionError(errors);
    }
    const {history, farm, location: {state: {breadcrumbs}}, match: {params: {medicineType}}, t} = props;
    return endGrafting(values.grafting.EvID.map(id => ({
        EvID: id,
        EndTime: new Date().getTime()
    }))).then(res => {
        setTimeout(() => {
            history.replace({
                pathname: `/${farm}/mobile/locationScanner`,
                state: {
                    eventType: "grafting",
                    status: `${medicineType}/add`,
                    breadcrumbs,
                    medicineType: +medicineType,
                }
            });
        }, 1500)
    }).catch(e => {
        throw new SubmissionError({_error: t("rfidApp.endGraftingGeneralError")});
    })
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;

    if (!values.grafting) {
        errors._error = t("rfidApp.treatmentError", {error: t("required")});
    }

    return errors;
}