import React, {Component} from 'react';
import {connect} from 'react-redux';
import Carousel from "../../../carousel/Carousel";
import CarouselItem from "../../../carousel/CarouselItem";
import DataInput from "../../../data-input/DataInput";
import {Field, reduxForm} from "redux-form";
import moment from "moment";
import EventStatusView from "../../EventStatusView";
import {submit} from "./RFIDParturitionStatusSubmit"
import "./_parturition-status.scss"
import {getEventsForAnimal} from "../../../../../actions/eventsActions";
import LoadingComponent from "../../../../../components/loading/LoadingComponent";
import {isEqual} from "lodash"
import {EventTypes, PARTURITION_DIFFICULTY} from "../../../../../constans/eventTypes";
import {AnimalTypes} from "../../../../../constans/animalTypes";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        events: state.events.eventsForAnimals,
        loadingEvents: state.events.eventsForAnimalsLoading
    };
}

const formName = "parturition-status";

class RfidParturitionStatus extends Component {

    state = {
        page: 0,
        loading: true
    };

    constructor(props) {
        super(props);

        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            const {animals} = state;
            this.props.dispatch(getEventsForAnimal(animals[0]));
            this.props.initialize({
                alive: 14,
                dead: 0,
                mummy: 0,
                temperature: 37.2,
                falls: 0,
                type: 0,
                animals
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // wczytanie poprzedniego eventu
        if (!isEqual(this.props.loadingEvents, prevProps.loadingEvents)) {
            const {location: {state: {animals}}} = this.props;
            let loadingState = this.props.loadingEvents.get(animals[0].AnmID);
            if (!loadingState) {
                let events = this.props.events.get(animals[0].AnmID)
                    .filter(item => (item.EvCode === EventTypes.PARTURITION || item.EvCode === EventTypes.SEPARATION) && item.EvTime < new Date().getTime());
                events.sort((a, b) => b.EvTime - a.EvTime);
                if (events.length > 0 && events[0].EvCode === EventTypes.PARTURITION) {
                    let parturition = events[0];
                    this.props.initialize({
                        alive: parturition.EvData.HealthyCnt || 14,
                        dead: parturition.EvData.DeadCnt || 0,
                        mummy: parturition.EvData.MummyCnt || 0,
                        temperature: 37.2,
                        falls: 0,
                        type: 0,
                        animals
                    })
                }
                this.setState({
                    loading: false
                })
            }
        }
    }

    onPageChange = page => {
        this.setState({
            page
        })
    };

    typeFormatter = value => {
        const {t} = this.props;
        switch (value) {
            case PARTURITION_DIFFICULTY.LOW: return t("low");
            case PARTURITION_DIFFICULTY.MEDIUM: return t("medium");
            case PARTURITION_DIFFICULTY.HARD: return t("hard");
            default: return "";
        }
    }

    render() {
        const {page, loading} = this.state;
        const {handleSubmit, submitting, location: {state}, submitFailed, error, farm, submitSucceeded, t} = this.props;
        if (!state) return null;
        const {animals} = state;
        console.log(this.props);
        return (
            <EventStatusView
                breadcrumbs={[
                    {name: t("parturition"), link: `/${farm}/mobile/events/parturition`}, {name: t("rfidApp.status")}
                ]}
                handleSubmit={handleSubmit}
                additionalData={
                    <small>{t("rfidApp.parturitionStart", {date: moment().format("DD.MM.YYYY HH:mm")})}</small>
                } submitting={submitting} className={"parturition"} submitFailed={submitFailed}
                error={error}
                formName={formName} status={state.status} eventType={state.eventType}
                counterName="parturition" submitSucceeded={submitSucceeded}
                additionalState={{
                    animalType: [AnimalTypes.SOW]
                }}>
                <LoadingComponent isLoading={loading}/>
                {
                    animals.length === 1 &&
                    <div className="animal-info">
                        zwierzę nr: <span>{animals[0].AnmNo1}</span>
                    </div>
                }
                <Carousel page={page} onPageChange={this.onPageChange}>
                    <CarouselItem>
                        <Field
                            name="alive"
                            component={DataInput}
                            label={t("alive")}
                        />
                        <Field
                            name="dead"
                            component={DataInput}
                            label={t("dead")}
                        />
                        <Field
                            name="mummy"
                            component={DataInput}
                            label={t("mummies")}
                        />
                    </CarouselItem>
                    <CarouselItem>
                        <Field
                            name="temperature"
                            component={DataInput}
                            label={t("temperatureShort")}
                            step={.1}
                        />
                        <Field
                            name="falls"
                            component={DataInput}
                            label={t("menu.fall")}
                        />
                        <Field
                            name="type"
                            component={DataInput}
                            label={t("difficulty")}
                            min={0}
                            max={2}
                            valueFormatter={this.typeFormatter}
                        />
                    </CarouselItem>
                </Carousel>
            </EventStatusView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation(),
    reduxForm({
        form: formName,
        onSubmit: submit
    })
)(RfidParturitionStatus);
