import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {CurveDayShowingType, CurveTypesWithInsemination} from "../../../../constans/feedingTypes";
import DataInput from "../../data-input/DataInput";
import {getCurveDayShowingType} from "../../../../utils/FeedingUtils";
import {get} from "lodash";
import {withTranslation} from "react-i18next";
import moment from "moment";

class CurveDay extends Component {

    getMaxDate(showingType, curve) {
        const currentDay = moment().startOf("day");
        const daysLen = get(curve, "SetData.Days.length", 0);
        const repetitionDay = get(curve, "SetData.InseminationJumpTo", 0);
        switch (showingType) {
            case CurveDayShowingType.BEFORE:
                return currentDay.clone().add(daysLen + 1, "days").toDate().getTime();
            case CurveDayShowingType.AFTER:
                return currentDay.clone().add(1, "days").toDate().getTime();
            case CurveDayShowingType.BOTH:
                return currentDay.clone().add(repetitionDay, "days").toDate().getTime();
            case CurveDayShowingType.NORMAL:
                return daysLen;
            default:
                break;
        }
    }

    render() {
        const {curve, t, onChange, value} = this.props;
        let showingType = getCurveDayShowingType(curve);
        const curveType = get(curve, "SetData.Type");
        return (
            <DataInput
                label={showingType === CurveDayShowingType.NORMAL ? t("curveDay") : showingType === CurveDayShowingType.BEFORE ? (CurveTypesWithInsemination.includes(curveType) ? t("modals.feedingManageFeedingModal.plannedInseminationDate") : t("modals.feedingManageFeedingModal.plannedParturitionDate")) : CurveTypesWithInsemination.includes(curveType) ? t("modals.feedingManageFeedingModal.inseminationDate") : t("modals.feedingManageFeedingModal.parturitionDate")}
                input={{
                    onChange,
                    value
                }}
                date={showingType !== CurveDayShowingType.NORMAL}
                min={showingType === CurveDayShowingType.NORMAL ? 1 : undefined}
                max={this.getMaxDate(showingType, curve)}
            />
        );
    }
}

CurveDay.propTypes = {
    curve: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any
};

export default withTranslation()(CurveDay);
