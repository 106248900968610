import React, {Component} from 'react';
import {connect} from 'react-redux';
import EventMainView from "../EventMainView";
import MobileAppLink from "../../../../components/basics/mobile-app-link/MobileAppLink";
import {medicineTypes} from "../../../../constans/medicine";
import "../grafting/_grafting.scss";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        medicines: state.dictionary.medicine.filter(item => item.WData.Type === medicineTypes.FORAGE)
    };
}

class RFIDGraftingPigletsMainViewForage extends Component {
    render() {
        const {farm, medicines, t} = this.props;
        return (
            <EventMainView breadcrumbs={[{
                name: t("pigletsTreatment"),
                link: `/${farm}/mobile/events/graftingPiglets`
            }, {name: t("medicalFeed")}]} additionalData={
                <div className="medicine-list">
                    {
                        medicines.slice(0, 4).map((med, key) =>
                            <MobileAppLink key={key} name={med.WData.Name}
                                           href={`/${farm}/mobile/scanner`}
                                           state={{
                                               medicine: med,
                                               breadcrumbs: [{
                                                   name: t("pigletsTreatment"),
                                                   link: `/${farm}/mobile/events/graftingPiglets`
                                               }, {name: t("medicalFeed")}],
                                               eventType: "graftingPiglets",
                                               status: `${medicineTypes.FORAGE}/add`,
                                               advancedAnimalScanner: true
                                           }}>
                                <div className="color" style={{background: med.WData.Color || "#FFFFFF"}}/>
                            </MobileAppLink>
                        )
                    }
                    {
                        medicines.length > 4 &&
                        <MobileAppLink name={t("other")} href={`/${farm}/mobile/medicineChooser`} state={{
                            breadcrumbs: [{
                                name: t("pigletsTreatment"),
                                link: `/${farm}/mobile/events/graftingPiglets`
                            }, {name: t("medicalFeed")}],
                            eventType: "graftingPiglets",
                            status: `${medicineTypes.FORAGE}/add`,
                            medicineType: medicineTypes.FORAGE
                        }}/>
                    }
                </div>
            }>
                <MobileAppLink name={t("castration")} href={`/${farm}/mobile/events/graftingPiglets/castration`}/>
                <MobileAppLink name={t("grafting")} href={`/${farm}/mobile/events/graftingPiglets/vaccine`}/>
                <MobileAppLink name={t("medicalFeed")} href={`/${farm}/mobile/events/graftingPiglets/forage`} selected/>
            </EventMainView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RFIDGraftingPigletsMainViewForage);