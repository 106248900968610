import React, {Component} from 'react';
import {connect} from 'react-redux';
import LastView from "../../last-view/LastView";
import {withTagReader} from "../../tag-reader-hoc/withTagReader";
import {AnimalTypes} from "../../../../constans/animalTypes";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import {createHeat} from "../../../../api/events/Heat";
import {compose} from "redux";
import {Trans, withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        user: state.user.user
    };
}

class RFIDHeatExamination extends Component {

    state = {
        animals: this.props.location.state ? this.props.location.state.animals : [],
        animalsToSave: [],
        show: false,
        error: null,
        errorTag: null,
        errorAnimal: null
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.tagReader.tag && this.props.tagReader.tag !== prevProps.tagReader.tag) {
            const {tagReader: {animal, tag}} = this.props;
            if (animal) {
                if (!this.state.animals.find(item => item.AnmID === animal.AnmID)) {
                    this.setState({
                        animals: [animal],
                        animalsToSave: this.state.animals,
                        show: false
                    })
                } else {
                    this.setState({
                        show: true,
                        error: 2,
                        errorAnimal: animal
                    })
                }
            } else {
                this.setState({
                    show: true,
                    error: 1,
                    errorTag: tag
                })
            }
        }
    }

    componentDidMount() {
        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        }
    }

    onSubmit = (startDate, clickedButton) => {
        const {animalsToSave, animals} = this.state;
        let anms = clickedButton ? animals : animalsToSave;
        let data = anms.map(animal => ({
            AnmID: animal.AnmID,
            EvTime: startDate.getTime(),
            GrID: getGrIDByAnimal(animal, startDate.getTime())
        }));
        return createHeat(data).then(res => {
            this.props.dispatch({type: "ADD_HEAT", payload: data});
        }).catch(e => {
            this.setState({
                show: true,
                error: 0
            })
            throw e;
        })
    };

    onCloseClick = () => {
        this.setState({
            show: false
        })
    }

    getError() {
        const {t} = this.props;
        const {error, errorTag, errorAnimal} = this.state;
        switch (error) {
            case 0:
                return t("rfidApp.heatGeneralError");
            case 1:
                return t("rfidApp.animalWithNumberNotFound", {errorTag});
            case 2:
                return t("rfidApp.scannedCurrentlySelected", {AnmNo1: errorAnimal.AnmNo1});
            default:
                return "";
        }
    }

    render() {
        const {location: {state}, farm, t} = this.props;
        if (!state) return null;
        const {animals, show, error} = this.state;
        const {status} = state;
        return (
            <LastView
                breadcrumbs={[
                    {
                        name: t("heat"),
                        link: `/${farm}/mobile/events/heat`
                    },
                    {name: t("heatExamination")}
                ]}
                text={<Trans i18nKey={"rfidApp.heatText"} count={animals.length} values={{AnmNo1: animals[0].AnmNo1}}
                             components={{bold: <strong/>}}/>}
                additionalText={t("rfidApp.heatAdditionalText")}
                animals={animals} eventType={"heat"} status={status}
                additionalState={{
                    animalType: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW]
                }} onSubmit={this.onSubmit} counterName="heat" closeError={this.onCloseClick} showError={show}
                error={this.getError()} errorType={error === 0 ? "error" : "warning"}/>
        );
    }
}

RFIDHeatExamination = withTagReader({
    animalKind: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW],
    findAnimalInLocation: true
})(RFIDHeatExamination);

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RFIDHeatExamination);