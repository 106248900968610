import React, {Component} from 'react';
import {connect} from 'react-redux';
import "../_event-status-view.scss"
import {compose} from "redux";
import {Trans, withTranslation} from "react-i18next";
import LastView from "../../last-view/LastView";
import animalsDB from "../../../../database/animalsDB";
import {Level} from "../../../../constans/levelTypes";
import {getLocationName} from "../../../../utils/BuildingUtils";
import {getPigBalance} from "../../../../utils/EventUtils";
import {createSeparationByAnimal} from "../../../../api/events/Separation";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
    };
}

class RFIDSeparationToPiglethouseAmountLocation extends Component {

    state = {
        animals: [],
        piglets: 0,
        show: false,
        error: null
    }

    constructor(props) {
        super(props);

        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            const {location} = state;
            let animals = animalsDB.getAllAnimalsForLocation(location, Level.CHAMBER);
            let piglets = animals.reduce((a, b) => a + getPigBalance(b.events), 0);
            this.state = {
                animals,
                piglets
            }
        }
    }

    onSubmit = (startTime, clickedButton) => {
        const {farm, history} = this.props;
        const {animals} = this.state;
        const {location: {state: {group, locationTo, groupNumber, animalNumber}}} = this.props;
        let data = animals.map(animal => {
            let piglets = getPigBalance(animal.events);
            let obj = {
                AnmID: animal.AnmID,
                piglets,
                EvTime: new Date().getTime(),
                weight: 0, // TODO brak w evencie
                separateTo: locationTo.CID
            }
            if (group) {
                let animalsFromGroupInPlcmnt = group.AnmIDs.map(id => animalsDB.getAnimalById(id)).filter(animal => {
                    if (!animal) return false;
                    if (animal.DtaDthTime) return false;
                    if (Array.isArray(animal.PlcmntID)) {
                        return !!animal.PlcmntID.find(item => item.PlcmntID === locationTo.CID);
                    }
                    return animal.PlcmntID === locationTo.CID;
                });
                let addToAnimal = animalsFromGroupInPlcmnt[0];
                obj = {
                    ...obj,
                    addToAnimal: addToAnimal.AnmID,
                    addToGroup: group.AnmGrp
                }
            } else {
                obj = {
                    ...obj,
                    newGroupNumber: groupNumber + "",
                    newAnimalNumber: animalNumber + ""
                }
            }
            return obj;
        });
        return createSeparationByAnimal(data).then(res => {
            setTimeout(() => {
                history.push(`/${farm}/mobile/events/separationToPigletHouse`);
            }, 1500)
            this.props.dispatch({type: "ADD_SEPARATION_TO_PIGLETHOUSE", payload: data});
        }).catch(e => {
            this.setState({
                show: true,
                error: 0
            })
            throw e;
        })
    }

    getError() {
        const {t} = this.props;
        const {error} = this.state;
        switch (error) {
            case 0:
                return t("rfidApp.separationGeneralError");
            default:
                return "";
        }
    }

    onCloseClick = () => {
        this.setState({
            show: false
        });
    }

    render() {
        const {location: {state}, farm, t} = this.props;
        const {animals, piglets, show} = this.state;
        if (!state) return null;
        const {locationTo} = state;
        const separationBreadcrumb = {
            name: t("rfidApp.separation"),
            link: `/${farm}/mobile/events/separationToPigletHouse`
        };
        const chamberBreadcrumb = {
            name: t("grid.chamber"),
            link: {
                pathname: `/${farm}/mobile/locationScanner`,
                state: {
                    ...state,
                    breadcrumbs: [separationBreadcrumb, {name: t("grid.chamber")}],
                    locations: null,
                    locationNextPath: `/${farm}/mobile/groupScanner`
                }
            }
        };
        return (
            <LastView
                breadcrumbs={[
                    separationBreadcrumb,
                    chamberBreadcrumb,
                    {
                        name: t("group"),
                        link: {
                            pathname: `/${farm}/mobile/groupScanner`, state: {
                                ...state,
                                breadcrumbs: [separationBreadcrumb, chamberBreadcrumb]
                            }
                        }
                    },
                    {
                        name: t("rfidApp.toPlace"),
                        link: {pathname: `/${farm}/mobile/events/separationToPigletHouse/location`, state}
                    },
                    {name: getLocationName(locationTo)}
                ]}
                animals={animals}
                text={
                    piglets === 0 ? t("rfidApp.separationToPigletHouseNoPiglets")
                        : <Trans i18nKey={"rfidApp.separationToPigletHouseText"} count={piglets}
                                 values={{location: getLocationName(locationTo)}} components={{bold: <strong/>}}/>
                }
                eventType={"separationToPigletHouse"} status={"location"} onSubmit={this.onSubmit}
                counterName="separationToPigletHouse"
                error={this.getError()} showError={show} closeError={this.onCloseClick}
                errorType={"error"} closePath={`/${farm}/mobile/events/separationToPigletHouse`}
                submitPath={`/${farm}/mobile/events/separationToPigletHouse`} disableSubmit={piglets === 0}
            />
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RFIDSeparationToPiglethouseAmountLocation);
