import React, {Component} from 'react';
import {connect} from 'react-redux';
import MobileAppLink from "../../../../components/basics/mobile-app-link/MobileAppLink";
import EventMainView from "../EventMainView";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RFIDSearch extends Component {
    render() {
        const {farm, t} = this.props;
        return (
            <EventMainView breadcrumbs={[{name: t("searching")}]}>
                <MobileAppLink name={t("notEating")} href={`/${farm}/mobile/locationScanner`} state={{
                    breadcrumbs: [{
                        name: t("searching"),
                        link: `/${farm}/mobile/events/search`
                    }, {name: t("notEating")}],
                    eventType: "search",
                    status: "notEating"
                }}/>
                <MobileAppLink name={t("eating")} href={`/${farm}/mobile/locationScanner`} state={{
                    breadcrumbs: [{
                        name: t("searching"),
                        link: `/${farm}/mobile/events/search`
                    }, {name: t("eating")}],
                    eventType: "search",
                    status: "eating"
                }}/>
            </EventMainView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RFIDSearch);