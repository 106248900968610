import React, {Component} from 'react';
import {addZerosToRfid} from "../../../utils/AnimalsUtils";
import {connect} from "react-redux";
import animalsDB from "../../../database/animalsDB";
import buildingsDB from "../../../database/buildingsDB";
import {isNil} from "lodash";

/**
 * Dodaje do zwracanego komponentu obsługę tag readera.
 * Zwraca wartość do propsów komponentu jako tagReader props.
 * tagReader: {
 *     tag: "",
 *     animal: null,
 *     location: null
 * }
 * @param props {object | function}                     w przypadku funkcji jako parametr przekazywane są propsy komponentu
 * @param props.findAnimalInLocation {boolean}          okresla czy ma wyszukiwać zwierzę w lokalizacji
 * @param props.animalKind {number | Array<number>}     typ zwierzęcia, które może przyjąć (null oznacza wyłączone sprawdzanie)
 * @return {function(*): *}
 */
export const withTagReader = (props = {
    findAnimalInLocation: false,
    animalKind: null,
    getDeadAnimals: false
}) => (WrappedComponent) => {
    class WithTagReader extends Component {
        state = {
            tag: "",
            animal: null,
            location: null,
            notFoundAnimal: false,
            notFoundLocation: false,
            wrongAnimalKind: false,
            manyAnimalsInLocation: false
        };

        tag = "";
        timeout = null;
        additionalProps = typeof props === "function" ? props(this.props) : props;

        componentDidMount() {
            document.addEventListener("keydown", this.tagReaderListener)
        }

        componentWillUnmount() {
            document.removeEventListener("keydown", this.tagReaderListener);
        }

        tagReaderListener = event => {
            const {key} = event;
            if (!isNaN(+key)) {
                clearTimeout(this.timeout);
                this.tag += key;
                this.timeout = setTimeout(() => {
                    this.tag = "";
                }, 500);
            }
            if (key === "Enter" && this.tag.length === 15) {
                const {farm} = this.props;
                let tag = addZerosToRfid(this.tag);
                let animal = animalsDB.getAnimalByRfid(tag, farm, {findDead: this.additionalProps.getDeadAnimals}) || null;
                let location = buildingsDB.getLocationByRFID(tag, farm) || null;
                let manyAnimalsInLocation = false;
                if (location && this.additionalProps.findAnimalInLocation) {
                    let animalsInLocation = animalsDB.getAllAnimalsByPlcmntID(location.FarmID || location.BgID || location.SID || location.CID || location.BID, {joinEvents: false});
                    if (animalsInLocation.length === 1) {
                        animal = animalsInLocation[0];
                    } else {
                        if (animalsInLocation.length > 0) {
                            animal = null;
                            manyAnimalsInLocation = true;
                        }
                    }
                }
                if (animal && !this.additionalProps.getDeadAnimals && animal.DtaDthTime) {
                    animal = null;
                }
                let wrongAnimalKind = false;
                if (!isNil(this.additionalProps.animalKind) && animal) {
                    if (Array.isArray(this.additionalProps.animalKind)) {
                        if (!this.additionalProps.animalKind.includes(animal.AnimalKind)) {
                            animal = null
                            wrongAnimalKind = true;
                        }
                    } else {
                        if (animal.AnimalKind !== this.additionalProps.animalKind) {
                            animal = null
                            wrongAnimalKind = true;
                        }
                    }
                }
                clearTimeout(this.timeout);
                this.setState({
                    tag,
                    animal,
                    location,
                    notFoundAnimal: !animal,
                    notFoundLocation: !location,
                    wrongAnimalKind,
                    manyAnimalsInLocation
                });
                this.tag = "";
                event.preventDefault();
            }
        };

        clearTag = () => {
            this.setState({
                tag: "",
                animal: null,
                location: null,
                notFoundAnimal: false,
                notFoundLocation: false,
                wrongAnimalKind: false,
                manyAnimalsInLocation: false
            });
        }

        render() {
            return <WrappedComponent {...this.props} tagReader={{...this.state, clearTag: this.clearTag}}/>;
        }
    }

    WithTagReader.displayName = `WithTagReader(${WrappedComponent.displayName || WrappedComponent.name || "Component"})`;

    return connect(state => ({
        farm: state.location.farm
    }))(WithTagReader);
}