import React, {Component} from 'react';
import {connect} from 'react-redux';
import "./_rfid-scanner.scss"
import {Link} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import RfidHeader from "../rfid-header/RFIDHeader";
import {withTagReader} from "../tag-reader-hoc/withTagReader";
import _ from "lodash"
import ErrorComponent from "../error/ErrorComponent";
import {getLocationName} from "../../../utils/BuildingUtils";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RFIDAnimalScanner extends Component {

    state = {
        show: false,
        tag: null,
        error: null
    };

    componentDidMount() {
        const {location: {state}, history, farm} = this.props;
        if (!state) {
            history.push(`/${farm}/mobile`)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.tagReader.tag && this.props.tagReader.tag !== prevProps.tagReader.tag) {
            if (this.props.tagReader.notFoundAnimal || this.props.tagReader.wrongAnimalKind) {
                let obj = {
                    tag: this.props.tagReader.tag,
                    show: true
                }
                if (this.props.tagReader.wrongAnimalKind) {
                    obj.error = 0;
                } else if (this.props.tagReader.notFoundAnimal) {
                    if (!this.props.tagReader.notFoundLocation) {
                        const {location: {state: {onlyAnimalScanner}}} = this.props;
                        if (onlyAnimalScanner) {
                            obj.error = 1;
                        } else {
                            obj.error = 2;
                        }
                    } else {
                        obj.error = 1;
                    }
                }
                this.setState(obj);
            }
            if (this.props.tagReader.manyAnimalsInLocation) {
                const {farm, location: {state}} = this.props;
                this.props.history.push({
                    pathname: `/${farm}/mobile/animalChooser`,
                    state: {
                        ...state,
                        location: this.props.tagReader.location
                    }
                })
            }
        }
        if (!_.isEqual(this.props.tagReader.animal, prevProps.tagReader.animal)) {
            const {farm, tagReader: {animal}, location: {state}} = this.props;
            const {eventType, status, animalScannerNextPath} = state;
            this.props.history.push({
                pathname: animalScannerNextPath || `/${farm}/mobile/events/${eventType}/${status}`,
                state: {
                    ...state,
                    animals: [animal]
                }
            })
        }
    }

    onCloseClick = () => {
        this.setState({
            show: false
        });
        this.props.tagReader.clearTag();
    };

    getError() {
        const {error, tag} = this.state;
        const {tagReader: {location}, t} = this.props;
        switch (error) {
            case 0:
                return t("rfidApp.wrongAnimalType");
            case 1:
                return t("rfidApp.animalWithNumberNotFound", {errorTag: tag});
            case 2:
                if (location) {
                    let name = getLocationName(location);
                    return t("rfidApp.animalsNotFoundInLocation", {location: name});
                }
                return "";
            default:
                return "";
        }
    }

    render() {
        let {location: {state}, farm, t} = this.props;
        if (!state) return null;
        const {eventType, breadcrumbs, onlyAnimalScanner} = state;
        const {show, error} = this.state;
        return (
            <div className="rfid-scanner">
                <RfidHeader breadcrumbs={breadcrumbs} back backPathname={`/${farm}/mobile/events/${eventType}`}/>
                <Row className="h-100">
                    <Col xs={6} className="d-flex align-items-center">
                        <Link to={{
                            pathname: `/${farm}/mobile/animalChooser`,
                            state
                        }} className="rfid-animal-chooser">
                            <h4>{t("rfidApp.chooseAnimalFromList")}</h4>
                            <div className="rfid-animal-chooser-button">
                                <div>
                                    <i className="far fa-list-ul fa-fw"/>
                                    <i className="far fa-slash"/>
                                    <i className="far fa-search fa-fw"/>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center">
                        <div className={"rfid-scanner-sensor-info"}>
                            <i className="far fa-wifi"/>
                            {
                                onlyAnimalScanner &&
                                <h4>{t("rfidApp.scanAnimal")}</h4>
                            }
                            {
                                !onlyAnimalScanner &&
                                <h4>{t("rfidApp.scanAnimalOrStand")}</h4>
                            }
                            <div>{t("rfidApp.readerToTag")}</div>
                        </div>
                    </Col>
                </Row>
                <ErrorComponent show={show} error={this.getError()}
                                onCloseClick={this.onCloseClick} capitalizeFirstLetter={false}
                                type={error === 0 ? "error" : "warning"}/>
            </div>
        );
    }
}

RFIDAnimalScanner = withTagReader(props => {
    const {location: {state}} = props;
    if (!state) return {};
    let animalKind = state.animalType || null;
    let findAnimalInLocation = !state.onlyAnimalScanner
    return {
        findAnimalInLocation,
        animalKind
    }
})(RFIDAnimalScanner);

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(RFIDAnimalScanner);