import React, {Component} from 'react';
import {connect} from 'react-redux';
import devicesDB from "../../../../database/devicesDB";
import buildingsDB from "../../../../database/buildingsDB";
import {Trans, withTranslation} from "react-i18next";
import EventStatusView from "../EventStatusView";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {Col, Row} from "react-bootstrap";
import DataInput from "../../data-input/DataInput";
import {getFeedingCurves} from "../../../../selectors/feedingSelector";
import {
    getAvailableCurveTypes,
    getCurveDayShowingType,
    initializeFeeding,
    isValidCurveForDevType
} from "../../../../utils/FeedingUtils";
import {DevType} from "../../../../constans/devices";
import LastView from "../../last-view/LastView";
import {submit} from "./RFIDTransferStandSubmit"
import {CurveDayShowingType, CurveTypesWithInsemination} from "../../../../constans/feedingTypes";
import {get} from "lodash";
import moment from "moment";

const formName = "transfer"
const selector = formValueSelector(formName);

function mapStateToProps(state) {
    let curves = getFeedingCurves(state).slice(0).sort((a, b) => a.SetData.Index - b.SetData.Index)
    return {
        farm: state.location.farm,
        curves,
        curve: selector(state, "curve")
    };
}

class RfidTransferStand extends Component {

    state = {
        sector: null,
        foundDevice: false,
        animals: this.props.location.state ? this.props.location.state.animals : [],
        stand: this.props.location.state ? this.props.location.state.stand : null
    };

    constructor(props) {
        super(props);
        const {location: {state}, farm, curves} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            let tree = buildingsDB.getTreeByLocationID(this.props.location.state.stand.BID);
            let devices = devicesDB.getDevicesInPlcmntID(this.props.location.state.stand.BID);
            const {sector} = tree;
            let device = devices.filter(item => item.DevType === DevType.DISPENSER || item.DevType === DevType.DISPENSER_NRF)[0]
            this.state = {
                ...this.state,
                sector: sector,
                foundDevice: device,
                fittingCurves: curves.filter(c => getAvailableCurveTypes({sectorType: sector.SType}).includes(c.SetData.Type) && isValidCurveForDevType({
                    curve: c,
                    devType: device ? device.DevType : null
                }))
            }
            let initializeData = initializeFeeding({
                animal: this.props.location.state.animals[0],
                sectorType: sector.SType,
                curves: this.state.fittingCurves,
                initializeIndex: true
            });
            this.props.initialize({
                animals: this.props.location.state.animals,
                stand: this.props.location.state.stand,
                ...initializeData
            });
        }
    }

    onSubmit = () => {
        const {stand, animals} = this.state;
        return submit({animals: animals, stand}, this.props.dispatch, this.props);
    };

    onCloseClick = () => {
        this.setState({
            show: false
        })
        this.props.tagReader.clearTag();
    }

    getError() {
        const {t} = this.props;
        const {error, errorAnimal} = this.state;
        switch (error) {
            case 0:
                return t("transferFailure");
            case 1:
                return t("rfidApp.scannedCurrentlySelected", {AnmNo1: errorAnimal.AnmNo1});
            default:
                return "";
        }
    }

    curveValueFormatter = value => {
        const {fittingCurves} = this.state;
        return fittingCurves[value] ? fittingCurves[value].SetData.Name : "-"
    }

    getMaxDate(showingType, curve) {
        const currentDay = moment().startOf("day");
        const daysLen = get(curve, "SetData.Days.length", 0);
        const repetitionDay = get(curve, "SetData.InseminationJumpTo", 0);
        switch (showingType) {
            case CurveDayShowingType.BEFORE:
                return currentDay.clone().add(daysLen, "days").toDate().getTime();
            case CurveDayShowingType.AFTER:
                return currentDay.clone().toDate().getTime();
            case CurveDayShowingType.BOTH:
                return currentDay.clone().add(repetitionDay - 1, "days").toDate().getTime();
            case CurveDayShowingType.NORMAL:
                return daysLen;
            default:
                break;
        }
    }

    render() {
        const {location: {state}, farm, handleSubmit, submitting, submitFailed, error, submitSucceeded, curve, t} = this.props;
        if (!state) return null;
        const {animals, stand, show, foundDevice, fittingCurves} = this.state;
        const breadcrumbs = [{name: t("transfer"), link: `/${farm}/mobile/events/transfer`}, {name: t("standing")}];
        if (!foundDevice) {
            const {status, location} = state;
            return (
                <LastView
                    breadcrumbs={breadcrumbs}
                    text={<Trans i18nKey={"rfidApp.transferStandText"} values={{
                        AnmNo1: animals[0].AnmNo1,
                        location: location.CName + "-" + (stand ? stand.BoxesName : null)
                    }} components={{bold: <strong/>}}/>}
                    animals={animals} eventType={"transfer"} status={status}
                    additionalState={{
                        animalScannerNextPath: `/${farm}/mobile/locationScanner`,
                        onlyLocationScanner: true,
                        standsChooser: true,
                        onlyAnimalScanner: true,
                    }} onSubmit={this.onSubmit} counterName="transfer" error={error} showError={show}
                    closeError={this.onCloseClick}/>
            );
        }
        let showingType = getCurveDayShowingType(fittingCurves[curve]);
        const curveType = get(fittingCurves[curve], "SetData.Type");
        return (
            <EventStatusView
                breadcrumbs={breadcrumbs}
                handleSubmit={handleSubmit}
                submitting={submitting}
                submitFailed={submitFailed}
                error={error}
                submitSucceeded={submitSucceeded}
                formName={formName}
                status={state.status}
                eventType={state.eventType}
                counterName="transfer"
                additionalState={{
                    animalScannerNextPath: `/${farm}/mobile/locationScanner`,
                    onlyLocationScanner: true,
                    standsChooser: true,
                    onlyAnimalScanner: true,
                }}
            >
                <Row className="justify-content-center h-100">
                    <Col xs={10} className="d-flex flex-column justify-content-center">
                        <Field
                            name="curve"
                            component={DataInput}
                            label={t("feedingCurve")}
                            min={0}
                            max={fittingCurves.length - 1}
                            valueFormatter={this.curveValueFormatter}
                        />
                        <Field
                            name={showingType === CurveDayShowingType.NORMAL ? "curveDay" : "repetitionDate"}
                            component={DataInput}
                            label={showingType === CurveDayShowingType.NORMAL ? t("curveDay") : showingType === CurveDayShowingType.BEFORE ? (CurveTypesWithInsemination.includes(curveType) ? t("modals.feedingManageFeedingModal.plannedInseminationDate") : t("rfidApp.plannedParturition")) : CurveTypesWithInsemination.includes(curveType) ? t("modals.feedingManageFeedingModal.inseminationDate") : t("rfidApp.plannedParturition")}
                            date={showingType !== CurveDayShowingType.NORMAL}
                            max={this.getMaxDate(showingType, fittingCurves[curve])}
                            min={showingType === CurveDayShowingType.NORMAL ? 1 : null}
                        />
                    </Col>
                </Row>
            </EventStatusView>
        )
    }
}

RfidTransferStand = reduxForm({
    form: formName,
    onSubmit: submit
})(RfidTransferStand);

RfidTransferStand = withTranslation()(RfidTransferStand);

export default connect(
    mapStateToProps,
)(RfidTransferStand);