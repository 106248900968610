import {setConfiguration} from "../../../IOT/device-functions/ClimateDriverFunctions";

function setConfig(values, props) {
    const {expectedTemperature, alarmMin, alarmMax} = values;
    return new Promise((resolve, reject) => {
        setConfiguration(props.props.climate, {
            requestedAirTemperature: expectedTemperature,
            alarms: {
                minimumTemperatureAlarmDeviation: -alarmMin,
                maximumTemperatureAlarmDeviation: alarmMax
            }
        }, resolve, reject, true);
    })
}

export function submit(values, props) {
    console.log(props);
    return setConfig(values, props).then(res => {
        setTimeout(() => {
            props.setStatus("success");
            props.props.onCloseClick();
        }, 1500)
    })
}