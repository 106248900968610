import React, {Component} from 'react';
import PropTypes from 'prop-types';
import RfidHeader from "../rfid-header/RFIDHeader";
import Button from "../../../components/basics/button/Button";
import "./_event-status-view.scss"
import {clearSubmitErrors} from "redux-form";
import {connect} from "react-redux";
import ErrorComponent from "../error/ErrorComponent";
import {Link} from "react-router-dom";
import Loader from "../loader/Loader";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        counters: state.eventCounter
    };
}

class EventStatusView extends Component {

    onCloseClick = () => {
        this.props.dispatch(clearSubmitErrors(this.props.formName))
    };

    render() {
        const {
            handleSubmit, breadcrumbs, additionalData, children, submitting, error, submitFailed, className, farm,
            eventType, status, additionalState, timesPath, counterName, counters, disabledSubmit, submitSucceeded
        } = this.props;
        return (
            <div className={`event-status-view${className ? " " + className : ""}`}>
                <RfidHeader breadcrumbs={breadcrumbs}/>
                {
                    additionalData &&
                    <div className="additional-data">
                        {additionalData}
                    </div>
                }
                {
                    counterName &&
                    <div className="event-status-view-counter">
                        <i className="fa fa-wifi fa-fw"/> {counters[counterName]}
                    </div>
                }
                <form onSubmit={handleSubmit}>
                    <div className={"submit"}>
                        <Button buttonStyle={"text"} disabled={submitting || disabledSubmit || submitSucceeded}>
                            <Loader loading={submitting} success={submitSucceeded}/>
                        </Button>
                    </div>
                    {children}
                    <Link className="close" to={{
                        pathname: timesPath || `/${farm}/mobile/scanner`,
                        state: {
                            breadcrumbs,
                            eventType,
                            status,
                            ...additionalState
                        }
                    }} replace>
                        <i className="far fa-times-circle"/>
                    </Link>
                </form>
                <ErrorComponent error={error} show={submitFailed && !submitting} onCloseClick={this.onCloseClick}/>
            </div>
        );
    }
}

EventStatusView.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        link: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    })).isRequired,
    additionalData: PropTypes.node,
    submitting: PropTypes.bool,
    error: PropTypes.string,
    submitFailed: PropTypes.bool,
    formName: PropTypes.string,
    className: PropTypes.string,
    eventType: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    additionalState: PropTypes.object,
    timesPath: PropTypes.string,
    counterName: PropTypes.string,
    disabledSubmit: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
};

export default connect(
    mapStateToProps,
)(EventStatusView);