import React, {Component} from 'react';
import {connect} from 'react-redux';
import {AnimalTypes} from "../../../../../constans/animalTypes";
import {withTagReader} from "../../../tag-reader-hoc/withTagReader";
import RFIDHeader from "../../../rfid-header/RFIDHeader";
import {Col, Fade, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import Button from "../../../../../components/basics/button/Button";
import "../../../last-view/_last-view.scss";
import "./_insertion-last-view.scss"
import ChangeNumber from "./ChangeNumber";
import animalsDB from "../../../../../database/animalsDB";
import {createInsertion} from "../../../../../api/events/Insertion";
import ErrorComponent from "../../../error/ErrorComponent";
import Loader from "../../../loader/Loader";
import {compose} from "redux";
import {Trans, withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RFIDInsertionLastView extends Component {

    state = {
        nextAnimalNumber: null,
        tag: "",
        submitting: false,
        showChangeNumber: false,
        isTaken: false,
        error: null,
        show: false,
        submitted: false,
        blockButton: false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.isTaken && !this.state.showChangeNumber) {
            if (this.props.tagReader.tag !== this.state.tag && this.props.tagReader.tag) {
                const {tagReader: {animal, location}} = this.props;
                if (!animal && !location) {
                    if (this.state.tag) {
                        this.submit(this.state.tag, prevState.nextAnimalNumber, true);
                    }
                    let animalNumber = this.props.tagReader.tag.slice(-4, this.props.tagReader.tag.length);
                    if (animalsDB.checkIfAnimalExistOnFarm(animalNumber, this.props.farm)) {
                        this.setState({
                            tag: this.props.tagReader.tag,
                            nextAnimalNumber: animalNumber,
                            showChangeNumber: true,
                            isTaken: true,
                            show: false
                        })
                    } else {
                        this.setState({
                            tag: this.props.tagReader.tag,
                            nextAnimalNumber: animalNumber,
                            isTaken: false,
                            show: false
                        })
                    }
                } else {
                    if (!this.state.show) {
                        if (animal) {
                            this.setState({
                                error: 1,
                                show: true
                            })
                        }
                        if (location) {
                            this.setState({
                                error: 2,
                                show: true
                            })
                        }
                    }
                }
            }
        } else {
            if (this.props.tagReader.tag !== this.state.tag && this.props.tagReader.tag) {
                this.props.tagReader.clearTag();
            }
        }
    }

    getName() {
        const {match: {params: {animalKind}}, t} = this.props;
        switch (+animalKind) {
            case AnimalTypes.BOAR:
                return t("animalKind.4");
            case AnimalTypes.PIGLET:
                return t("animalKind.2");
            case AnimalTypes.PORKER:
                return t("animalKind.3");
            case AnimalTypes.SOW:
                return t("animalKind.0");
            case AnimalTypes.RENOVATION_SOW:
                return t("animalKind.5");
            default:
                return "";
        }
    }

    showChangeNumber = () => {
        this.setState({
            showChangeNumber: true
        })
    }

    closeChangeNumber = () => {
        this.setState({
            showChangeNumber: false
        })
    }

    onChangeNumber = number => {
        this.setState({
            nextAnimalNumber: (number + "").padStart(4, "0"),
            isTaken: false
        })
    }

    submit(rfid, AnmNo1, scanned = false) {
        this.setState({
            submitting: true,
            submitted: false
        });
        const {match: {params: {animalKind}}} = this.props;
        console.log(rfid, AnmNo1, scanned);
        let data = [{
            AnmNo1,
            AnmCnt: 1,
            PlcmntID: this.props.farm,
            AnimalKind: +animalKind,
            devices: [],
            additionalData: {
                RFID: rfid,
                DtaInTime: new Date().getTime()
            }
        }];
        createInsertion(data).then(() => {
            this.props.dispatch({type: "ADD_INSERTION", payload: data});
            this.setState({
                submitting: false,
                submitted: true,
                blockButton: !scanned
            }, () => {
                if (!scanned) {
                    setTimeout(() => {
                        this.props.history.push(`/${this.props.farm}/mobile/events/insertion`)
                    }, 1500)
                }
            });
        }).catch(() => {
            this.setState({
                error: 0,
                submitting: false
            });
        })
    }

    onCloseClick = () => {
        this.props.tagReader.clearTag();
        this.setState({
            show: false
        })
    }

    getError() {
        const {t} = this.props;
        const {error} = this.state;
        switch (error) {
            case 0:
                return t("rfidApp.insertionGeneralError");
            case 1:
                return t("rfidApp.foundAnimalWithNumber");
            case 2:
                return t("rfidApp.foundLocationWithNumber");
            default:
                return "";
        }
    }

    render() {
        const {farm,t} = this.props;
        const {nextAnimalNumber, tag, submitting, showChangeNumber, isTaken, show, submitted, error, blockButton} = this.state;
        return (
            <div className="last-view insertion-last-view">
                <RFIDHeader breadcrumbs={[
                    {
                        name: t("insertion"),
                        link: `/${farm}/mobile/events/insertion`
                    },
                    {
                        name: this.getName()
                    }
                ]}/>
                <Row className="justify-content-center h-100">
                    <Col md={10} className={`d-flex flex-column justify-content-center${tag ? " up" : ""}`}>
                        <h4 className="main-text">
                            {
                                !tag && t("rfidApp.insertionNoTagText")
                            }
                            {
                                tag &&
                                <Trans i18nKey={"rfidApp.insertionTagText"} values={{tag, AnmNo1: nextAnimalNumber}}
                                       components={{bold: <strong/>}}/>
                            }
                        </h4>
                        {
                            tag &&
                            <div className="additional-text">
                                <i className="far fa-wifi"/>
                                <small>{t("rfidApp.insertionAdditionalText")}</small>
                            </div>
                        }
                    </Col>
                </Row>
                <Link className="last-view-close" to={`/${farm}/mobile/events/insertion`} replace>
                    <i className="far fa-times-circle"/>
                </Link>
                <Button className="last-view-save" buttonStyle={"text"} disabled={submitting || !tag || blockButton}
                        onClick={() => this.submit(tag, nextAnimalNumber)}>
                    <Loader loading={submitting} success={submitted}/>
                </Button>
                {
                    tag &&
                    <Button className="insertion-change-number" buttonStyle="text" onClick={this.showChangeNumber}>
                        <div>{t("changeNumber")}</div>
                        <span className="fa-stack">
                            <i className="far fa-circle fa-stack-2x"/>
                            <i className="far fa-edit fa-stack-1x"/>
                        </span>
                    </Button>
                }
                <Fade in={showChangeNumber} unmountOnExit mountOnEnter>
                    <div className="change-number">
                        <ChangeNumber number={nextAnimalNumber} onClose={this.closeChangeNumber}
                                      onSubmitDone={this.onChangeNumber} isTaken={isTaken}/>
                    </div>
                </Fade>
                <ErrorComponent onCloseClick={this.onCloseClick} error={this.getError()} show={show}
                                capitalizeFirstLetter={false} type={error === 0 ? "error" : "warning"}/>
            </div>
        );
    }
}

RFIDInsertionLastView = withTagReader()(RFIDInsertionLastView);

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RFIDInsertionLastView);