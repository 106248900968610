import React, {Component} from 'react';
import {connect} from 'react-redux';
import LastView from "../../../last-view/LastView";
import {withTagReader} from "../../../tag-reader-hoc/withTagReader";
import {AnimalTypes} from "../../../../../constans/animalTypes";
import {getGrIDByAnimal} from "../../../../../utils/EventUtils";
import {startParturition} from "../../../../../api/events/Parturition";
import {compose} from "redux";
import {Trans, withTranslation} from "react-i18next";
import devicesDB from "../../../../../database/devicesDB";
import {DevType} from "../../../../../constans/devices";
import {getEventsForAnimal} from "../../../../../actions/eventsActions";
import LoadingComponent from "../../../../../components/loading/LoadingComponent";
import {EventTypes} from "../../../../../constans/eventTypes";
import moment from "moment";

function mapStateToProps(state, props) {
    if (props.location.state) {
        const {location: {state: {animals}}} = props;
        return {
            farm: state.location.farm,
            events: state.events.eventsForAnimals.get(animals[0].AnmID) || [],
            loading: state.events.eventsForAnimalsLoading.get(animals[0].AnmID)
        };
    }
    return {
        farm: state.location.farm,
        events: [],
        loading: false
    }
}

class RFIDParturitionStart extends Component {

    state = {
        animals: this.props.location.state ? this.props.location.state.animals : [],
        animalsToSave: [],
        show: false,
        error: null,
        errorTag: null,
        errorAnimal: null
    };

    constructor(props) {
        super(props);
        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            const {animals} = this.state;
            this.props.dispatch(getEventsForAnimal(animals[0]));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.tagReader.tag && this.props.tagReader.tag !== prevProps.tagReader.tag) {
            const {tagReader: {animal, tag}} = this.props;
            if (animal) {
                if (!this.state.animals.find(item => item.AnmID === animal.AnmID)) {
                    this.setState({
                        animals: [animal],
                        animalsToSave: this.state.animals,
                        show: false
                    })
                    this.props.dispatch(getEventsForAnimal(animal));
                } else {
                    this.setState({
                        show: true,
                        error: 2,
                        errorAnimal: animal
                    })
                }
            } else {
                this.setState({
                    show: true,
                    error: 1,
                    errorTag: tag
                })
            }
        }
    }

    onSubmit = (startTime, clickedButton) => {
        const {animalsToSave, animals} = this.state;
        let anms = clickedButton ? animals : animalsToSave;
        let data = anms.map(animal => {
            let devices = devicesDB.getDevicesInPlcmntID(Array.isArray(animal.PlcmntID) ? animal.PlcmntID[0].PlcmntID : animal.PlcmntID, {showDevicesInChildren: false});
            let nrf = devices.find(item => item.DevType === DevType.DISPENSER_NRF);
            return {
                AnmID: animal.AnmID,
                EvTime: startTime.getTime(),
                GrID: getGrIDByAnimal(animal, startTime.getTime()),
                device: nrf ? {DevID: nrf.DevID, GwID: nrf.GatewayID} : undefined
            }
        });
        return startParturition(data).then(res => {
        }).catch(e => {
            this.setState({
                show: true,
                error: 0
            })
            throw e;
        })
    };

    onCloseClick = () => {
        this.setState({
            show: false
        })
    }

    getError() {
        const {t} = this.props;
        const {error, errorTag, errorAnimal} = this.state;
        switch (error) {
            case 0:
                return t("rfidApp.startParturitionGeneralError");
            case 1:
                return t("rfidApp.animalWithNumberNotFound", {errorTag});
            case 2:
                return t("rfidApp.scannedCurrentlySelected", {AnmNo1: errorAnimal.AnmNo1});
            default:
                return "";
        }
    }

    checkStarts() {
        const {events} = this.props;
        let lastTime = +moment().subtract(24, "hours");
        let lastDay = events.filter(item => (item.EvCode === EventTypes.PARTURITION_START || item.EvCode === EventTypes.PARTURITION_END) && item.EvTime >= lastTime && item.EvTime <= +new Date());
        if (lastDay.length === 0) return false;
        return lastDay[lastDay.length - 1].EvCode === EventTypes.PARTURITION_START;
    }

    render() {
        const {location: {state}, farm, t, loading} = this.props;
        if (!state) return null;
        const {animals, show, error} = this.state;
        const {status} = state;
        let hasUnfinished = this.checkStarts();
        return (
            <>
                <LoadingComponent isLoading={loading}/>
                <LastView
                    breadcrumbs={[{
                        name: t("parturition"),
                        link: `/${farm}/mobile/events/parturition`
                    }, {name: t("rfidApp.start")}]}
                    text={
                        hasUnfinished ?
                            <Trans i18nKey="rfidApp.startParturitionFound" values={{AnmNo1: animals[0].AnmNo1}}
                                   components={{bold: <strong/>}}/> :
                            <Trans i18nKey="rfidApp.startParturitionText" values={{AnmNo1: animals[0].AnmNo1}}
                                   components={{bold: <strong/>}}/>
                    }
                    additionalText={t("rfidApp.startParturitionAdditionalText", {count: animals.length})}
                    animals={animals} eventType={"parturition"} status={status} showDate={!hasUnfinished}
                    additionalState={{
                        animalType: [AnimalTypes.SOW]
                    }} onSubmit={this.onSubmit} closeError={this.onCloseClick} error={this.getError()}
                    showError={show} errorType={error === 0 ? "error" : "warning"} disableSubmit={hasUnfinished}/>
            </>
        );
    }

}

RFIDParturitionStart = withTagReader({
    findAnimalInLocation: true
})(RFIDParturitionStart);

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RFIDParturitionStart);