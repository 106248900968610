import React, {Component} from 'react';
import "./_rfid-history-scanned.scss"

class RfidHistoryScannedComponent extends Component {
    render() {
        if (!this.props.value) return null;
        return (
            <div className="rfid-history-scanned"/>
        );
    }
}

export default RfidHistoryScannedComponent;