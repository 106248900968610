import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import LastView from "../../last-view/LastView";
import {getEventsForAnimal} from "../../../../actions/eventsActions";
import LoadingComponent from "../../../../components/loading/LoadingComponent";
import {isEqual} from "lodash"
import {EventTypes} from "../../../../constans/eventTypes";
import {AnimalTypes} from "../../../../constans/animalTypes";
import moment from "moment";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import {createGrafting} from "../../../../api/events/Grafting";

function mapStateToProps(state, props) {
    let obj = {
        farm: state.location.farm,
        events: [],
        medicines: state.dictionary.medicine
    }
    if (props.location.state) {
        let animal = props.location.state.animals[0];
        console.log(animal);
        obj.events = state.events.eventsForAnimals.get(animal.AnmID) || [];
        obj.loading = state.events.eventsForAnimalsLoading.get(animal.AnmID) || false;
        obj.graftingProgram = state.dictionary.graftingPrograms.find(item => item.WData.AnimalKind === animal.AnimalKind) || null;
    }
    return obj;
}

class RfidCheckGrafting extends Component {

    state = {
        grafting: null
    }

    constructor(props) {
        super(props);
        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            let animal = state.animals[0];
            this.props.dispatch(getEventsForAnimal(animal));
        }
    }

    componentDidMount() {
        if (this.props.events.length > 0) {
            this.setState({
                grafting: this.calculateGrafting()
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(this.props.events, prevProps.events)) {
            this.setState({
                grafting: this.calculateGrafting()
            })
        }
    }

    checkIfHadGraftingToday(medicine) {
        const {events} = this.props;
        let graftings = events.filter(item => item.EvCode === EventTypes.GRAFTING);
        return !!graftings.find(item => item.EvData.Medicine === medicine && moment(item.EvTime).isSame(new Date(), "day"));
    }

    calculateGrafting() {
        const {graftingProgram, events, location: {state: {animals}}} = this.props;
        let animal = animals[0];
        if (graftingProgram) {
            let date = null;
            if (animal.AnimalKind === AnimalTypes.SOW) {
                let insemination = events.slice(0).sort((a, b) => b.EvTime - a.EvTime).find(item => item.EvCode === EventTypes.INSEMINATION && item.EvTime < new Date().getTime());
                if (insemination) {
                    date = moment(insemination.EvTime).startOf("day");
                }
            } else {
                if (animal.DtaBrthTime || animal.DtaInTime) {
                    date = moment(animal.DtaBrthTime || animal.DtaInTime).startOf("day");
                }
            }
            if (date) {
                let diffToToday = moment().startOf("day").diff(date, "days");
                let hasGraftingsForAge = graftingProgram.WData.MedicineList.filter(item => item.Age === diffToToday);
                for (let hasGraftingForAge of hasGraftingsForAge) {
                    if (!this.checkIfHadGraftingToday(hasGraftingForAge.Medicine)) {
                        return {
                            medicine: hasGraftingForAge.Medicine,
                            reason: "2"
                        };
                    }
                }
            }
        }
        let treatments = events.filter(item => item.EvCode === EventTypes.TREATMENT);
        console.log(treatments);
        for (let event of treatments) {
            for (let treatment of event.EvData.Treatment) {
                for (let date of treatment.Dates) {
                    if (moment(date).isSame(new Date(), "day")) {
                        for (let medicine of treatment.Medicine) {
                            if (!this.checkIfHadGraftingToday(medicine)) {
                                return {
                                    medicine,
                                    reason: event.EvData.Reason || ""
                                };
                            }
                        }
                    }
                }
            }
        }
    }

    getText() {
        const {grafting} = this.state;
        const {location: {state: {animals}}, medicines, t} = this.props;
        if (!grafting) return t("rfidApp.noMedicinesToday", {AnmNo1: animals[0].AnmNo1});
        let medicine = medicines.find(item => item.WordID === grafting.medicine);
        if (medicine) {
            return t("rfidApp.giveMedicine", {Name: medicine.WData.Name});
        }
    }

    onSubmit = () => {
        const {location: {state: {animals}}, medicines, history, farm, t} = this.props;
        let animal = animals[0];
        const {grafting} = this.state;
        let medicine = medicines.find(item => item.WordID === grafting.medicine);
        let data = [{
            AnmID: animal.AnmID,
            Medicine: grafting.medicine,
            Reason: grafting.reason,
            Weight: 0,
            TreatmentType: medicine.WData.Type,
            AnmCnt: animal.AnmCnt,
            EvTime: new Date().getTime(),
            GrID: getGrIDByAnimal(animal, new Date().getTime())
        }];
        return createGrafting(data).then(() => {
            setTimeout(() => {
                history.replace({
                    pathname: `/${farm}/mobile/scanner`,
                    state: {
                        eventType: "checkGrafting",
                        status: "animal",
                        breadcrumbs: [{name: t("rfidApp.checkGrafting")}]
                    }
                });
            }, 1500)
            this.props.dispatch({type: "ADD_GRAFTING", payload: data});
        })
    }

    render() {
        const {location: {state}, loading, t} = this.props;
        const {grafting} = this.state;
        if (!state) return null;
        return (
            <>
                <LoadingComponent isLoading={loading}/>
                <LastView
                    breadcrumbs={[{name: t("rfidApp.checkGrafting")}]}
                    animals={[]}
                    text={this.getText()}
                    disableSubmit={!grafting}
                    eventType={"checkGrafting"}
                    status={"animal"}
                    onSubmit={this.onSubmit}
                />
            </>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(RfidCheckGrafting);