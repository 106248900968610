import React, {Component} from 'react';
import PropTypes from 'prop-types';
import RfidFeedingModal from "./RFIDFeedingModal";
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {
    getAvailableCurveTypes,
    getCurveDayShowingType,
    initializeFeeding,
    isValidCurveForDevType,
    prepareFeeding
} from "../../../../utils/FeedingUtils";
import Device from "../../../../beans/Device";
import "../../choosers/_animal-chooser.scss"
import {DevType} from "../../../../constans/devices";
import Animal from "../../../../beans/Animal";
import {setAnimal} from "../../../../IOT/device-functions/DispenserNRFFunctions";
import {Col, Row} from "react-bootstrap";
import DataInput from "../../data-input/DataInput";
import {CurveDayShowingType, CurveTypesWithInsemination} from "../../../../constans/feedingTypes";
import moment from "moment";
import {get} from "lodash";
import {isUsingFakeData} from "../../../../utils/SettingsUtils";
import {getFeedingCurves} from "../../../../selectors/feedingSelector";

function mapStateToProps(state) {
    return {
        curves: getFeedingCurves(state).slice(0).sort((a, b) => a.SetData.Index - b.SetData.Index)
    };
}

class RfidChangeCurveModal extends Component {

    state = {
        page: 0,
        curves: []
    }

    constructor(props) {
        super(props);

        const {curves, sectorType, devices, animal, fakeData} = this.props;
        let fittingCurves = curves.filter(c => getAvailableCurveTypes({sectorType}).includes(c.SetData.Type) && isValidCurveForDevType({
            curve: c,
            devType: devices[0] ? devices[0].DevType : null
        }));
        let initializeData = initializeFeeding({
            animal,
            sectorType: sectorType,
            curves: fittingCurves,
            initializeIndex: true
        });
        console.log(initializeData, fakeData);
        if (isUsingFakeData()) {
            initializeData = initializeFeeding({
                animal: {
                    feedParam: {
                        curveNr: fakeData.curve.number,
                        startTime: fakeData.curve.start
                    }
                },
                sectorType: sectorType,
                curves: fittingCurves,
                initializeIndex: true
            });
        }
        this.state = {
            ...this.state,
            curves: fittingCurves,
            ...initializeData
        }
    }

    sendData(gatewayId, data) {
        return new Promise((resolve, reject) => {
            setAnimal(gatewayId, [data], {onSuccess: resolve, onFailure: reject, showNotification: false})
        })
    }

    onSubmitClick = () => {
        const {curves, curve} = this.state;
        const {devices, workType, animal, locationID} = this.props;
        let device = devices[0];
        let c = curves[curve];
        console.log(c);
        let dataNRF = prepareFeeding({...this.state, curve: c}, DevType.DISPENSER_NRF);
        const data = {
            AnmNo1: animal.AnmNo1,
            RFID: animal.RFID,
            AnmID: animal.AnmID,
            PlcmntID: locationID,
            feeding: {
                curveNr: +dataNRF.curveNr,
                startTime: +dataNRF.startTime,
                number: device.DevType === DevType.DISPENSER ? (
                    get(device.PlcmntID.find(item => item.PlcmntID === locationID), "Adr", -1) + 1 || undefined
                ) : undefined
            }
        }
        console.log(data);
        if (isUsingFakeData()) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                    this.props.onSuccess({
                        curve: c.SetData.Index + 1,
                        curveDay: moment().startOf("day").diff(device.DevType === DevType.DISPENSER ? data[0].curveStart : workType === "R" ? data.animals[0].curveCfg.startTime : data.startTime, "days") + 1,
                        startTime: device.DevType === DevType.DISPENSER ? data[0].curveStart : workType === "R" ? data.animals[0].curveCfg.startTime : data.startTime
                    });
                }, 1500)
            })
        }
        return this.sendData(device.GatewayID, data);
    }

    onChange = value => {
        const {curves, curve} = this.state;
        let curveObject = curves[curve];
        let showingType = getCurveDayShowingType(curveObject);
        if (showingType === CurveDayShowingType.NORMAL) {
            this.setState({
                curveDay: value
            })
        } else {
            this.setState({
                repetitionDate: value
            })
        }
    }

    onChangeCurve = value => {
        this.setState({
            curve: value
        })
    }

    curveValueFormatter = value => {
        const {curves} = this.state;
        return curves[value] ? curves[value].SetData.Name : "-"
    }

    getMaxDate(showingType, curve) {
        const currentDay = moment().startOf("day");
        const daysLen = get(curve, "SetData.Days.length", 0);
        const repetitionDay = get(curve, "SetData.InseminationJumpTo", 0);
        switch (showingType) {
            case CurveDayShowingType.BEFORE:
                return currentDay.clone().add(daysLen, "days").toDate().getTime();
            case CurveDayShowingType.AFTER:
                return currentDay.clone().toDate().getTime();
            case CurveDayShowingType.BOTH:
                return currentDay.clone().add(repetitionDay - 1, "days").toDate().getTime();
            case CurveDayShowingType.NORMAL:
                return daysLen;
            default:
                break;
        }
    }

    render() {
        const {onCloseClick, onSuccess, t} = this.props;
        const {curves, curve, curveDay, repetitionDate} = this.state;
        let curveObject = curves[curve];
        let showingType = getCurveDayShowingType(curveObject);
        const curveType = get(curveObject, "SetData.Type");
        return (
            <RfidFeedingModal breadcrumbs={[{name: t("eventTypes.C")}]} onCloseClick={onCloseClick}
                              onSubmit={this.onSubmitClick} onSuccess={onSuccess} disableSubmit={!curveObject}>
                <Row className="justify-content-center h-100">
                    <Col xs={10} className="d-flex flex-column justify-content-center">
                        <DataInput label={t("feedingCurve")} min={0}
                                   max={curves.length - 1}
                                   valueFormatter={this.curveValueFormatter}
                                   input={{onChange: this.onChangeCurve, value: curve}}/>
                        <DataInput
                            label={showingType === CurveDayShowingType.NORMAL ? t("curveDay") : showingType === CurveDayShowingType.BEFORE ? (CurveTypesWithInsemination.includes(curveType) ? t("modals.feedingManageFeedingModal.plannedInseminationDate") : t("rfidApp.plannedParturition")) : CurveTypesWithInsemination.includes(curveType) ? t("modals.feedingManageFeedingModal.inseminationDate") : t("rfidApp.plannedParturition")}
                            date={showingType !== CurveDayShowingType.NORMAL}
                            max={this.getMaxDate(showingType, curveObject)}
                            min={showingType === CurveDayShowingType.NORMAL ? 1 : null}
                            input={{
                                onChange: this.onChange,
                                value: showingType === CurveDayShowingType.NORMAL ? curveDay : repetitionDate
                            }}/>
                    </Col>
                </Row>
            </RfidFeedingModal>
        );
    }
}

RfidChangeCurveModal.propTypes = {
    sectorType: PropTypes.number.isRequired,
    devices: PropTypes.arrayOf(PropTypes.instanceOf(Device)),
    curve: PropTypes.object,
    onCloseClick: PropTypes.func.isRequired,
    workType: PropTypes.string,
    animal: PropTypes.instanceOf(Animal),
    locationID: PropTypes.string,
    onSuccess: PropTypes.func,
    fakeData: PropTypes.object,
};

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(RfidChangeCurveModal);
