import React, {Component} from 'react';
import {connect} from 'react-redux';
import MobileAppLink from "../../../../components/basics/mobile-app-link/MobileAppLink";
import EventMainView from "../EventMainView";
import {AnimalTypes} from "../../../../constans/animalTypes";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RfidInsemination extends Component {
    render() {
        const {farm, t} = this.props;
        return (
            <EventMainView breadcrumbs={[{name: t("insemination")}]}>
                <MobileAppLink name={t("check")} href={`/${farm}/mobile/scanner`} state={{
                    breadcrumbs: [{
                        name: t("insemination"),
                        link: `/${farm}/mobile/events/insemination`
                    }, {name: t("check")}],
                    eventType: "insemination",
                    status: "status",
                    animalType: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW],
                    singleSelect: true
                }}/>
                <MobileAppLink name={t("inseminate")} href={`/${farm}/mobile/events/insemination/end`}/>
            </EventMainView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RfidInsemination);