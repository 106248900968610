import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import PropTypes from "prop-types"
import RFIDHistoryEvent from "../RFIDHistoryEvent";
import animalsDB from "../../../../../database/animalsDB";
import moment from "moment";
import {EventTypes} from "../../../../../constans/eventTypes";
import RFIDHistoryScannedComponent from "../RFIDHistoryScannedComponent";

function mapStateToProps(state) {
    return {
        history: state.eventCounter.history
    };
}

class RfidHistoryActiveNipples extends Component {

    state = {
        data: this.getData()
    }

    getData() {
        const {history} = this.props;
        let data = [];
        for (let event of history) {
            if (event.type !== EventTypes.ACTIVE_NIPPLES) break;
            data = [...data, ...event.data]
        }
        return data.sort((a, b) => b.EvTime - a.EvTime);
    }

    onDataChange = data => {
        this.setState({
            data
        })
    }

    animalValueFormatter = value => {
        let animal = animalsDB.getAnimalById(value);
        if (animal) return animal.AnmNo1;
        return null;
    }

    dateFormatter = value => {
        return moment(value).format("HH:mm");
    }

    render() {
        const {t} = this.props;
        const {data} = this.state;
        const headers = [
            {
                name: "",
                field: "scanned",
                headerClassName: "scanned",
                itemClassName: "scanned",
                component: <RFIDHistoryScannedComponent/>
            },
            {
                name: t("animal"),
                field: "AnmID",
                valueFormatter: this.animalValueFormatter
            },
            {
                name: t("activeNipples"),
                field: "Nipples"
            },
            {
                name: t("time"),
                field: "EvTime",
                valueFormatter: this.dateFormatter,
                headerClassName: "index",
                itemClassName: "index"
            }
        ]
        return (
            <RFIDHistoryEvent name={t("activeNipples")} data={data} headers={headers} onDataChange={this.onDataChange}/>
        );
    }
}

RfidHistoryActiveNipples.propTypes = {
    item: PropTypes.object,
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(RfidHistoryActiveNipples);