import {SubmissionError} from "redux-form";
import {getGrIDByAnimal, getPigBalanceForSowFromState} from "../../../../utils/EventUtils";
import {createFallPiglets} from "../../../../api/events/FallPiglets";
import {AnimalTypes} from "../../../../constans/animalTypes";

export function submit(values, dispatch, props) {
    let errors = validate(values, props);
    if (errors._error) {
        throw new SubmissionError(errors);
    }
    const {animals, amount} = values;
    const {match: {params: {reasonID}}, fallReasons, farm, history, t} = props;
    let data = animals.map(animal => ({
        AnmID: animal.AnmID,
        Piglets: amount,
        EvTime: new Date().getTime(),
        GrID: getGrIDByAnimal(animal, new Date().getTime()),
        Weight: 0, // TODO brakuje w evencie
        Reason: reasonID,
        PlcmntID: Array.isArray(animal.PlcmntID) ? animal.PlcmntID[0].PlcmntID : animal.PlcmntID
    }));
    return createFallPiglets(data).then(res => {
        let reason = fallReasons.find(item => item.ID === reasonID);
        setTimeout(() => {
            history.push({
                pathname: `/${farm}/mobile/scanner`,
                state: {
                    eventType: "fallPiglets",
                    status: reasonID,
                    breadcrumbs: [{
                        name: t("fallPiglets"),
                        link: `/${farm}/mobile/events/fallPiglets`
                    }, {name: reason.Value}],
                    animalType: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW]
                }
            });
        }, 1500)
        dispatch({type: "ADD_FALL_PIGLETS", payload: data});
    }).catch(e => {
        throw new SubmissionError({_errors: t("rfidApp.fallPigletsGeneralError")});
    })
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;

    if (values.amount === 0) {
        errors._error = t("rfidApp.amountError", {error: t("errors.lessVal", {count: 1})});
    } else {
        for (let animal of values.animals) {
            let balance = getPigBalanceForSowFromState(animal, new Date().getTime());
            if (values.amount > balance) {
                errors._error = t("rfidApp.amountNotEnoughPiglets", {AnmNo1: animal.AnmNo1});
            }
        }
    }

    return errors;
}