import React, {Component} from 'react';
import {connect} from 'react-redux';
import LastView from "../../last-view/LastView";
import {withTagReader} from "../../tag-reader-hoc/withTagReader";
import animalsDB from "../../../../database/animalsDB";
import CarouselItem from "../../carousel/CarouselItem";
import {Col, Fade, Row} from "react-bootstrap";
import Button from "../../../../components/basics/button/Button";
import "./_reclassify.scss"
import ChangeNumber from "../insertion/insertion-last-view/ChangeNumber";
import Carousel from "../../carousel/Carousel";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import {createReclassify} from "../../../../api/events/Reclassify";
import {AnimalTypes} from "../../../../constans/animalTypes";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RFIDReclassifyAdd extends Component {

    state = {
        animals: [],
        page: 0,
        editing: null,
        isTaken: false,
        editIndex: null,
        show: false,
        error: null
    }

    componentDidMount() {
        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {tagReader: {tag, animal, location}, location: {state}} = this.props;
        let animalAmount = state ? state.animals.reduce((a, b) => a + +b.AnmCnt, 0) : 0;
        const animals = this.state.animals.slice(0);
        if (tag !== prevProps.tagReader.tag && tag) {
            if (!animal && !location) {
                if (animals.length < animalAmount) {
                    let animalNumber = tag.slice(-4, tag.length);
                    if (!animals.find(item => item.RFID === tag)) {
                        let data = {
                            RFID: tag,
                            AnmNo1: animalNumber
                        }
                        animals.push(data)
                        if (animalsDB.checkIfAnimalExistOnFarm(animalNumber, this.props.farm)) {
                            this.setState({
                                animals,
                                editing: data,
                                isTaken: true,
                                editIndex: animals.length - 1
                            })
                        } else {
                            this.setState({
                                animals,
                                isTaken: false,
                                editing: null,
                                editIndex: null
                            })
                        }
                    } else {
                        this.setState({
                            error: 4,
                            show: true
                        })
                    }
                } else {
                    this.setState({
                        error: 3,
                        show: true
                    })
                }
            } else {
                if (!this.state.show) {
                    if (animal) {
                        this.setState({
                            error: 1,
                            show: true
                        })
                    }
                    if (location) {
                        this.setState({
                            error: 2,
                            show: true
                        })
                    }
                }
            }
        }
    }

    onPageChange = page => {
        this.setState({
            page
        })
    }

    onEditClick(row, index) {
        this.setState({
            editing: row,
            editIndex: index
        })
    }

    getCarouselPages() {
        const {animals} = this.state;
        let array = [];
        for (let i = 0; i < animals.length; i += 3) {
            let data = animals.slice(i, i + 3);
            array.push(
                <CarouselItem key={i}>
                    {
                        data.map((row, index) =>
                            <Row>
                                <Col xs={7}>{row.RFID}</Col>
                                <Col xs={3}>{row.AnmNo1}</Col>
                                <Col xs={2}><Button icon={<i className="far fa-edit"/>} buttonStyle={"round"}
                                                    onClick={() => this.onEditClick(row, i + index)}/></Col>
                            </Row>
                        )
                    }
                </CarouselItem>
            )
        }
        return array;
    }

    closeChangeNumber = () => {
        this.setState({
            editing: null
        })
    }

    onChangeNumber = (number, props) => {
        console.log(number, props);
        const {index} = props;
        const animals = this.state.animals.slice(0);
        let data = animals[index];
        data.AnmNo1 = (number + "").padStart(4, "0");
        this.setState({
            animals
        })
    }

    onSubmit = (startDate, clickedButton) => {
        const animals = this.state.animals.slice(0);
        const {location: {state}} = this.props;
        let data = [];
        for (let animal of state.animals) {
            let newAnimals = [];
            for (let i = 0; i < (animal.AnmCnt < animals.length ? animal.AnmCnt : animals.length); i++) {
                newAnimals.push(animals.shift())
            }
            if (newAnimals.length > 0) {
                data.push({
                    AnmID: animal.AnmID,
                    newAnimals,
                    EvTime: startDate.getTime(),
                    GrID: getGrIDByAnimal(animal, startDate.getTime())
                })
            } else {
                break;
            }
        }
        return createReclassify(data).then(res => {
            this.props.dispatch({
                type: "ADD_RECLASSIFY",
                payload: data
            })
        }).catch(e => {
            this.setState({
                show: true,
                error: 0
            })
            throw e;
        })
    }

    onCloseClick = () => {
        this.setState({
            show: false
        })
        this.props.tagReader.clearTag();
    }

    getError() {
        const {t} = this.props;
        const {error} = this.state;
        switch (error) {
            case 0:
                return t("rfidApp.reclassifyGeneralError");
            case 1:
                return t("rfidApp.foundAnimalWithNumber");
            case 2:
                return t("rfidApp.foundLocationWithNumber");
            case 3:
                return t("rfidApp.scannedMaxAnimals");
            case 4:
                return t("rfidApp.scannedNumberIsOnList");
            default:
                return "";
        }
    }

    getErrorType() {
        const {error} = this.state;
        switch (error) {
            case 0:
                return "error";
            case 1:
            case 2:
                return "warning";
            default:
                return "info";
        }

    }

    render() {
        const {location: {state}, t} = this.props;
        if (!state) return null;
        const {status} = state;
        const {page, editing, isTaken, editIndex, animals, show} = this.state;
        let animalAmount = state ? state.animals.reduce((a, b) => a + +b.AnmCnt, 0) : 0;
        console.log(this.getError());
        return (
            <>
                <LastView breadcrumbs={[{name: t("reclassify")}]} eventType={"reclassify"}
                          text={
                              <>
                                  {
                                      animals.length === 0 && t("rfidApp.reclassifyText1")
                                  }
                                  {
                                      animals.length > 0 &&
                                      <Carousel page={page} onPageChange={this.onPageChange}>
                                          {this.getCarouselPages()}
                                      </Carousel>
                                  }
                              </>
                          }
                          status={status}
                          additionalText={t("rfidApp.reclassifyAdditionalText", {remaining: animalAmount - animals.length})}
                          animals={[]}
                          onSubmit={this.onSubmit} submitOnlyOnButton
                          className={animals.length > 0 ? "reclassify" : null} counterName="reclassify"
                          additionalState={{animalType: AnimalTypes.PORKER}} showError={show} error={this.getError()}
                          errorType={this.getErrorType()}
                          closeError={this.onCloseClick} disableSubmit={animals.length === 0}/>
                <Fade in={!!editing} mountOnEnter unmountOnExit>
                    <div className="change-number">
                        <ChangeNumber number={editing && editing.AnmNo1} onClose={this.closeChangeNumber}
                                      onSubmitDone={this.onChangeNumber} isTaken={isTaken} index={editIndex}/>
                    </div>
                </Fade>
            </>
        );
    }
}

export default compose(
    withTagReader(),
    connect(mapStateToProps),
    withTranslation()
)(RFIDReclassifyAdd);
