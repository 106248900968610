import React, {Component} from 'react';
import {connect} from 'react-redux';
import RFIDHeader from "../../../rfid-header/RFIDHeader";
import MobileAppLink from "../../../../../components/basics/mobile-app-link/MobileAppLink";
import {AnimalTypes} from "../../../../../constans/animalTypes";
import "./_insertion-main-view.scss";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RfidInsertionMainView extends Component {
    render() {
        const {farm, t} = this.props;
        return (
            <div className="animal-insertion">
                <RFIDHeader breadcrumbs={[
                    {
                        name: t("insertion")
                    }
                ]} home/>
                <div className="type-chooser">
                    <MobileAppLink name={t("animalKind.5")}
                                   href={`/${farm}/mobile/events/insertion/${AnimalTypes.RENOVATION_SOW}`}/>
                    <MobileAppLink name={t("animalKind.0")} href={`/${farm}/mobile/events/insertion/${AnimalTypes.SOW}`}/>
                    <MobileAppLink name={t("animalKind.3")} href={`/${farm}/mobile/events/insertion/${AnimalTypes.PORKER}`}/>
                    <MobileAppLink name={t("animalKind.2")} href={`/${farm}/mobile/events/insertion/${AnimalTypes.PIGLET}`}/>
                    <MobileAppLink name={t("animalKind.4")} href={`/${farm}/mobile/events/insertion/${AnimalTypes.BOAR}`}/>
                </div>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RfidInsertionMainView);