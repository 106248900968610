import React, {Component} from 'react';
import {connect} from 'react-redux';
import RFIDScannerLeftBar from "../left-bar/RFIDScannerLeftBar";
import "./_mobile-rfid-scanner.scss";
import InseminationIcon from "../../../resources/images/events/insemination.svg";
import ParturitionIcon from "../../../resources/images/events/parturition.svg";
import USGIcon from "../../../resources/images/events/usg.svg";
import NoPregnancyIcon from "../../../resources/images/events/no-pregnancy.svg";
import SeparationToPiglethouseIcon from "../../../resources/images/events/separation-porkhouse.svg";
import GraftingIcon from "../../../resources/images/events/grafting.svg";
import FallPigletsIcon from "../../../resources/images/events/fall-piglets.svg";
import TransferIcon from "../../../resources/images/events/transfer.svg";
import FallIcon from "../../../resources/images/events/fall.svg";
import SelectionIcon from "../../../resources/images/events/selection.svg";
import MommyIcon from "../../../resources/images/events/mommy.svg";
import WeightingIcon from "../../../resources/images/events/weighting.svg";
import ActiveNipplesIcon from "../../../resources/images/events/active_nipples.svg";
import {withCookies} from "react-cookie";
import GroupEvents from "./group/GroupEvents";
import SellIcon from "../../../resources/images/events/sell.svg"
import {withTagReader} from "../tag-reader-hoc/withTagReader";
import InsertionIcon from "../../../resources/images/events/insertion.svg";
import ReclassifyIcon from "../../../resources/images/events/reclassify.svg";
import {AnimalTypes} from "../../../constans/animalTypes";
import TreatmentIcon from "../../../resources/images/events/treatment.svg";
import GraftingPigletsIcon from "../../../resources/images/events/piglets_treatment.svg"
import HeatIcon from "../../../resources/images/events/heat.svg"
import ErrorComponent from "../error/ErrorComponent";
import SearchIcon from "../../../resources/images/events/search.svg"
import LostRFIDIcon from "../../../resources/images/events/lost-rfid.svg"
import {getLocationID, getLocationName} from "../../../utils/BuildingUtils";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        hasAccess: state.view.showBreeding,
    };
}

class MobileRFIDScanner extends Component {

    state = {
        menuOpened: false,
        edit: false,
        show: false,
        error: null,
        errorTag: null,
        errorLocation: null
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.tagReader.tag !== prevProps.tagReader.tag && this.props.tagReader.tag) {
            if (!this.props.tagReader.animal && !this.props.tagReader.location) {
                this.setState({
                    show: true,
                    error: 0,
                    errorTag: this.props.tagReader.tag
                })
            } else {
                if (this.props.tagReader.animal) {
                    this.props.history.push(`/${this.props.farm}/mobile/information/${this.props.tagReader.animal.AnmID}`);
                } else if (this.props.tagReader.location) {
                    if (this.props.tagReader.manyAnimalsInLocation) {
                        this.props.history.push(`/${this.props.farm}/mobile/locationInformation/${getLocationID(this.props.tagReader.location)}`);
                    } else {
                        this.setState({
                            show: true,
                            error: 1,
                            errorLocation: this.props.tagReader.location
                        })
                    }
                }
            }
        }
    }

    onMenuOpenedChange = status => {
        this.setState({
            menuOpened: status
        })
    };

    onEditClick = () => {
        this.setState(state => ({
            edit: !state.edit
        }))
    };

    getError() {
        const {t} = this.props;
        const {error, errorTag, errorLocation} = this.state;
        switch (error) {
            case 0:
                return t("rfidApp.animalWithNumberNotFound", {errorTag});
            case 1:
                return t("rfidApp.noAnimalsFound", {location: getLocationName(errorLocation)});
            default:
                return "";
        }
    }

    onCloseClick = () => {
        this.setState({
            show: false
        });
        this.props.tagReader.clearTag();
    }

    render() {
        // dodajac kolejny event sprawdz ilosc kolumn na widoku
        const {farm, hasAccess, t} = this.props;
        const {menuOpened, edit, show} = this.state;
        const general = [
            {
                name: t("insertion"),
                icon: InsertionIcon,
                eventName: "insertion",
                show: true
            },
            {
                name: t("transfer"),
                icon: TransferIcon,
                eventName: "transfer",
                show: true
            },
            {
                name: t("weighting"),
                icon: WeightingIcon,
                eventName: "weighting",
                show: hasAccess
            },
            {
                name: t("reclassify"),
                icon: ReclassifyIcon,
                eventName: "reclassify",
                path: `/${farm}/mobile/scanner`,
                state: {
                    breadcrumbs: [
                        {
                            name: t("reclassify")
                        }
                    ],
                    animalType: AnimalTypes.PORKER,
                    eventType: "reclassify",
                    status: "add"
                },
                show: hasAccess
            },
            {
                name: t("searching"),
                icon: SearchIcon,
                eventName: "search",
                show: true
            },
            {
                name: t("rfidApp.lostEarringTitle"),
                icon: LostRFIDIcon,
                eventName: "change",
                path: `/${farm}/mobile/animalChooser`,
                state: {
                    breadcrumbs: [
                        {
                            name: t("rfidApp.lostEarringTitle")
                        }
                    ],
                    eventType: "change",
                    status: "rfid",
                    singleSelect: true
                },
                show: true
            },
        ];
        let grafting = [
            {
                name: t("treatment"),
                icon: GraftingIcon,
                eventName: "grafting"
            },
            {
                name: t("scheduleOfTreatment"),
                icon: TreatmentIcon,
                eventName: "treatment",
                path: `/${farm}/mobile/scanner`,
                state: {
                    breadcrumbs: [
                        {
                            name: t("scheduleOfTreatment")
                        }
                    ],
                    eventType: "treatment",
                    status: "medicine"
                }
            },
            {
                name: t("pigletsTreatment"),
                icon: GraftingPigletsIcon,
                eventName: "graftingPiglets"
            },
            {
                name: t("rfidApp.checkGrafting"),
                icon: GraftingPigletsIcon,
                eventName: "checkGrafting",
                path: `/${farm}/mobile/scanner`,
                state: {
                    breadcrumbs: [
                        {
                            name: t("rfidApp.checkGrafting")
                        }
                    ],
                    eventType: "checkGrafting",
                    status: "animal"
                }
            },
        ];
        const reproduction = [
            {
                name: t("insemination"),
                icon: InseminationIcon,
                eventName: "insemination"
            },
            {
                name: t("usg"),
                icon: USGIcon,
                eventName: "usg"
            },
            {
                name: t("noPregnancy"),
                icon: NoPregnancyIcon,
                eventName: "noPregnancy"
            },
            {
                name: t("heat"),
                icon: HeatIcon,
                eventName: "heat"
            },
        ];
        const birth = [
            {
                name: t("parturition"),
                icon: ParturitionIcon,
                eventName: "parturition"
            },
            {
                name: t("rfidApp.separation"),
                icon: SeparationToPiglethouseIcon,
                eventName: "separationToPigletHouse",
                // state: {
                //     breadcrumbs: [{
                //         name: t("rfidApp.separationFromPlace"),
                //         link: {
                //             pathname: `/${farm}/mobile/locationScanner`
                //         }
                //     }],
                //     goToStands: false,
                //     eventType: "separationToPiglethouse",
                //     status: "amount",
                //     locationNextPath: `/${farm}/mobile/groupScanner`,
                //     groupsNextPath: `/${farm}/mobile/events/separationToPiglethouse/location`
                // }
            },
            {
                name: t("mommy"),
                icon: MommyIcon,
                eventName: "mommy"
            },
            {
                name: t("activeNipples"),
                icon: ActiveNipplesIcon,
                eventName: "activeNipples",
                path: `/${farm}/mobile/scanner`,
                state: {
                    breadcrumbs: [{name: t("activeNipples")}],
                    eventType: "activeNipples",
                    status: "add",
                    animalType: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW],
                }
            },
        ]
        const falls = [
            {
                name: t("fallPiglets"),
                icon: FallPigletsIcon,
                eventName: "fallPiglets"
            },
            {
                name: t("fall"),
                icon: FallIcon,
                eventName: "fall"
            },
        ]
        const sell = [
            {
                name: t("sale"),
                icon: SellIcon,
                eventName: "sell"
            },
            {
                name: t("selection"),
                icon: SelectionIcon,
                eventName: "selection"
            }
        ]
        return (
            <div className="mobile-rfid-scanner">
                <RFIDScannerLeftBar onOpenedChange={this.onMenuOpenedChange} onEditClick={this.onEditClick}/>
                <div className="mobile-rfid-scanner-container">
                    <div className="mobile-rfid-scanner-grid">
                        <GroupEvents name={t("general")} items={general} menuOpened={menuOpened} edit={edit}/>
                        <GroupEvents name={t("treatment")} items={grafting} menuOpened={menuOpened} edit={edit}/>
                        <GroupEvents name={t("menu.procreation")} items={reproduction} menuOpened={menuOpened} edit={edit}/>
                        <GroupEvents name={t("menu.parturition")} items={birth} menuOpened={menuOpened} edit={edit}/>
                        <GroupEvents name={t("falls")} items={falls} menuOpened={menuOpened} edit={edit}/>
                        <GroupEvents name={t("sale")} items={sell} menuOpened={menuOpened} edit={edit}/>
                    </div>
                </div>
                <ErrorComponent onCloseClick={this.onCloseClick} error={this.getError()} show={show}/>
            </div>
        );
    }
}

MobileRFIDScanner = withCookies(MobileRFIDScanner);

MobileRFIDScanner = withTagReader({
    findAnimalInLocation: true
})(MobileRFIDScanner);

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(MobileRFIDScanner);
