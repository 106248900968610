import React, {Component} from 'react';
import {connect} from 'react-redux';
import EventMainView from "../EventMainView";
import MobileAppLink from "../../../../components/basics/mobile-app-link/MobileAppLink";
import {AnimalTypes} from "../../../../constans/animalTypes";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RfidMommyMainView extends Component {
    render() {
        const {farm, t} = this.props;
        return (
            <EventMainView breadcrumbs={[{name: t("mommy")}]}>
                <MobileAppLink name={t("rfidApp.getFromMommy")} href={`/${farm}/mobile/scanner`} state={{
                    eventType: "mommy",
                    status: "get",
                    breadcrumbs: [{name: t("mommy"), link: `/${farm}/mobile/events/mommy`}, {name: t("rfidApp.getFromMommy")}],
                    animalType: [AnimalTypes.SOW]
                }}/>
                <MobileAppLink name={t("rfidApp.addToMommy")} href={`/${farm}/mobile/scanner`} state={{
                    eventType: "mommy",
                    status: "add",
                    breadcrumbs: [{name: t("mommy"), link: `/${farm}/mobile/events/mommy`}, {name: t("rfidApp.addToMommy")}],
                    animalType: [AnimalTypes.SOW]
                }}/>
            </EventMainView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RfidMommyMainView);