import React, {Component} from 'react';
import {connect} from 'react-redux';
import animalsDB from "../../../../database/animalsDB";
import buildingsDB from "../../../../database/buildingsDB";
import {SectorType} from "../../../../constans/sectorTypes";
import {Level} from "../../../../constans/levelTypes";
import LocationChooser from "../../choosers/LocationChooser";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RfidSeparationToPiglethouseLocationChooser extends Component {

    state = {
        locations: []
    };

    constructor(props) {
        super(props);

        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            const {group} = state;
            let plcmnts = new Map();
            if (group) {
                let animals = group.AnmIDs.map(id => animalsDB.getAnimalById(id)).filter(item => !!item && !item.DtaDthTime);
                for (let animal of animals) {
                    if (Array.isArray(animal.PlcmntID)) {
                        for (let plcmnt of animal.PlcmntID) {
                            let tree = buildingsDB.getTreeByLocationID(plcmnt.PlcmntID);
                            if (tree && tree.chamber) {
                                plcmnts.set(tree.chamber.CID, {
                                    value: tree.chamber,
                                    name: tree.chamber.CName,
                                    type: tree.sector.SType
                                });
                            }
                        }
                    } else {
                        let tree = buildingsDB.getTreeByLocationID(animal.PlcmntID);
                        if (tree && tree.chamber) {
                            plcmnts.set(tree.chamber.CID, {
                                value: tree.chamber,
                                name: tree.chamber.CName,
                                type: tree.sector.SType
                            });
                        }
                    }
                }
            } else {
                let emptyChambers = buildingsDB.getAllChambersForSTypeWithLocation(SectorType.PIGLET_HOUSE, farm)
                    .filter(item => animalsDB.getAllAnimalsForLocation(item, Level.CHAMBER).length === 0);
                for (let chamber of emptyChambers) {
                    plcmnts.set(chamber.CID, {
                        value: chamber,
                        name: chamber.CName,
                        type: SectorType.PIGLET_HOUSE
                    });
                }
            }
            this.state = {
                ...this.state,
                locations: [...plcmnts.values()]
            }
        }
    }

    getClassName(sType) {
        switch (sType) {
            case SectorType.DELIVERY:
                return "birth";
            case SectorType.MATING:
                return "mating";
            case SectorType.SOWS:
                return "sows";
            case SectorType.PIGLET_HOUSE:
                return "piglets";
            default:
                return "other"
        }
    }

    render() {
        const {location: {state}, farm, t} = this.props;
        if (!state) return null;
        const {locations} = this.state;
        const {group} = state;
        return (
            <LocationChooser location={{
                state: {
                    ...state,
                    locationNextPath: !group ? `/${farm}/mobile/animalChooser/newAnimal` : null,
                    breadcrumbs: [
                        {
                            name: t("rfidApp.separation"),
                            link: `/${farm}/mobile/events/separationToPigletHouse`
                        },
                        {
                            name: "Komora",
                            link: {pathname: `/${farm}/mobile/locationScanner`, state}
                        },
                        {
                            name: t("group"),
                            link: {pathname: `/${farm}/mobile/groupScanner`, state}
                        },
                        {name: t("rfidApp.toPlace")}
                    ],
                    locations,
                    saveName: "locationTo"
                }
            }}/>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RfidSeparationToPiglethouseLocationChooser);