import React, {Component} from 'react';
import "./_rfid-location-climate-changes-modal.scss"
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {withFormik} from "formik";
import DataInput from "../data-input/DataInput";
import {Col, Row} from "react-bootstrap";
import RfidHeader from "../rfid-header/RFIDHeader";
import Button from "../../../components/basics/button/Button";
import Loader from "../loader/Loader";
import PropTypes from "prop-types"
import Climate from "../../../beans/devices/Climate";
import {connect} from "react-redux";
import {submit} from "./RFIDLocationClimateChangesModalSubmit"

function mapStateToProps(state, props) {
    return {
        shadow: state.shadows.shadows.get(props.climate.DevID)
    }
}

class RfidLocationClimateChangesModal extends Component {

    onChangeExpectedTemperature = value => {
        const {setFieldValue} = this.props;
        setFieldValue("expectedTemperature", value);
    }

    onChangeMinAlarm = value => {
        const {setFieldValue} = this.props;
        setFieldValue("alarmMin", value);
    }

    onChangeMaxAlarm = value => {
        const {setFieldValue} = this.props;
        setFieldValue("alarmMax", value);
    }

    expectedTemperatureValueFormatter = value => {
        return <>{value.toFixed(1)}&#176;C</>
    }

    maxAlarmValueFormatter = value => {
        const {values} = this.props;
        return <>{(value + values.expectedTemperature).toFixed(1)}&#176;C</>
    }

    render() {
        const {values, onCloseClick, handleSubmit, isSubmitting, status, t} = this.props;
        return (
            <div className="rfid-location-climate-changes-modal">
                <RfidHeader breadcrumbs={[{name: t("rfidApp.climateSettings")}]}/>
                <Row className="justify-content-center h-100">
                    <Col xs={8} className="d-flex flex-column justify-content-center">
                        <DataInput label={t("chamber.chart.temperatureDesired")} input={{
                            value: values.expectedTemperature,
                            onChange: this.onChangeExpectedTemperature
                        }} valueFormatter={this.expectedTemperatureValueFormatter} step={.2} min={10} max={50}/>
                        <DataInput label={t("chamber.chart.minTemp")} input={{
                            value: values.alarmMin,
                            onChange: this.onChangeMinAlarm
                        }} valueFormatter={this.maxAlarmValueFormatter} step={.2}
                                   min={-4}
                                   max={-.2}/>
                        <DataInput label={t("chamber.chart.maxTemp")} input={{
                            value: values.alarmMax,
                            onChange: this.onChangeMaxAlarm
                        }} min={2} max={20} step={.2} valueFormatter={this.maxAlarmValueFormatter}/>
                    </Col>
                </Row>
                <Button buttonStyle={"text"} type={"button"} className="close" onClick={onCloseClick}>
                    <i className="far fa-times-circle"/>
                </Button>
                <Button buttonStyle={"text"} type={"button"} className="submit" onClick={handleSubmit}>
                    <Loader loading={isSubmitting} success={status === "success"}/>
                </Button>
            </div>
        );
    }
}

RfidLocationClimateChangesModal.propTypes = {
    onCloseClick: PropTypes.func.isRequired,
    climate: PropTypes.instanceOf(Climate),
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withFormik({
        displayName: "rfidClimateChange",
        mapPropsToValues: (props) => {
            try {
                return {
                    expectedTemperature: props.shadow.configuration.requestedAirTemperature,
                    alarmMin: -props.shadow.configuration.alarms.minimumTemperatureAlarmDeviation,
                    alarmMax: props.shadow.configuration.alarms.maximumTemperatureAlarmDeviation
                }
            } catch (e) {
                return {
                    expectedTemperature: 0,
                    alarmMin: 0,
                    alarmMax: 0
                }
            }
        },
        handleSubmit: submit
    })
)(RfidLocationClimateChangesModal);