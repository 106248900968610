import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import PropTypes from "prop-types"
import RFIDHistoryEvent from "../RFIDHistoryEvent";
import {EventTypes} from "../../../../../constans/eventTypes";
import animalsDB from "../../../../../database/animalsDB";
import moment from "moment";
import RFIDHistoryScannedComponent from "../RFIDHistoryScannedComponent";

function mapStateToProps(state) {
    return {
        history: state.eventCounter.history,
        selectionReason: state.dictionary.selectionReason.WData[state.language.lang.lang] || []
    };
}

class RFIDHistorySelection extends Component {

    state = {
        data: this.getData()
    }

    getData() {
        const {history} = this.props;
        let data = [];
        for (let event of history) {
            if (event.type !== EventTypes.SELECTION) break;
            data = [...data, ...event.data]
        }
        return data.sort((a, b) => b.EvTime - a.EvTime);
    }

    onDataChange = data => {
        this.setState({
            data
        })
    }

    animalValueFormatter = value => {
        let animal = animalsDB.getAnimalById(value);
        if (animal) return animal.AnmNo1;
        return null;
    }

    dateFormatter = value => {
        return moment(value).format("HH:mm");
    }

    getReason = id => {
        const {selectionReason} = this.props;
        console.log("XDDDD:",selectionReason);
        let reason = selectionReason.find(item => item.ID === id);
        if (reason) return reason.Value;
        return null;
    }

    render() {
        const {t} = this.props;
        const {data} = this.state;
        const headers = [
            {
                name: "",
                field: "scanned",
                headerClassName: "scanned",
                itemClassName: "scanned",
                component: <RFIDHistoryScannedComponent/>
            },
            {
                name: t("animal"),
                field: "AnmID",
                valueFormatter: this.animalValueFormatter
            },
            {
                name: t("reason"),
                field: "Reason",
                valueFormatter: this.getReason
            },
            {
                name: t("time"),
                field: "EvTime",
                valueFormatter: this.dateFormatter,
                headerClassName: "index",
                itemClassName: "index"
            }
        ]
        return (
            <RFIDHistoryEvent name={t("selection")} data={data} onDataChange={this.onDataChange} headers={headers}/>
        );
    }
}

RFIDHistorySelection.propTypes = {
    item: PropTypes.object,
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(RFIDHistorySelection);