import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import RFIDHistoryEvent from "../RFIDHistoryEvent";
import {EventTypes} from "../../../../../constans/eventTypes";
import animalsDB from "../../../../../database/animalsDB";
import moment from "moment";
import {convertWeightUnitTo} from "../../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../../constans/unitTypes";
import RFIDHistoryScannedComponent from "../RFIDHistoryScannedComponent";

function mapStateToProps(state) {
    return {
        history: state.eventCounter.history
    };
}

class RFIDHistoryCastration extends Component {

    state = {
        data: this.getData()
    }

    getData() {
        const {history} = this.props;
        let data = [];
        for (let event of history) {
            if (event.type !== EventTypes.CASTRATION) break;
            data = [...data, ...event.data]
        }
        return data.sort((a, b) => b.EvTime - a.EvTime);
    }

    onDataChange = data => {
        this.setState({
            data
        })
    }

    animalValueFormatter = value => {
        let animal = animalsDB.getAnimalById(value);
        if (animal) return animal.AnmNo1;
        return null;
    }

    dateFormatter = value => {
        return moment(value).format("HH:mm");
    }

    scannedValueFormatter = value => {
        if (!value) return <i className="far fa-times"/>;
        return <i className="far fa-check"/>
    }

    weightFormatter = value => {
        if (!value) return <i className="far fa-times"/>
        return convertWeightUnitTo(value, {
            unit: UnitTypes.MEDIUM,
            showUnit: true,
            fixed: 0
        })
    }

    getReason = id => {
        const {weighting} = this.props;
        let reason = weighting.find(item => item.ID === id);
        if (reason) return reason.Value;
        return null;
    }

    render() {
        const {t} = this.props;
        const {data} = this.state;
        const headers = [
            {
                name: "",
                field: "scanned",
                headerClassName: "scanned",
                itemClassName: "scanned",
                component: <RFIDHistoryScannedComponent/>
            },
            {
                name: t("animal"),
                field: "AnmID",
                valueFormatter: this.animalValueFormatter
            },
            {
                name: t("castration"),
                field: "Castrate",
                valueFormatter: this.scannedValueFormatter,
                headerClassName: "index",
                itemClassName: "index"
            },
            {
                name: t("tails"),
                field: "Tails",
                valueFormatter: this.scannedValueFormatter,
                headerClassName: "index",
                itemClassName: "index"
            },
            {
                name: t("teeth"),
                field: "Teeth",
                valueFormatter: this.scannedValueFormatter,
                headerClassName: "index",
                itemClassName: "index"
            },
            {
                name: t("weight"),
                field: "Weight",
                valueFormatter: this.weightFormatter,
            },
            {
                name: t("time"),
                field: "EvTime",
                valueFormatter: this.dateFormatter,
                headerClassName: "index",
                itemClassName: "index"
            }
        ]
        return (
            <RFIDHistoryEvent name={t("castration")} data={data} onDataChange={this.onDataChange} headers={headers}/>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(RFIDHistoryCastration);