import React, {Component} from 'react';
import {connect} from 'react-redux';
import EventStatusView from "../EventStatusView";
import {formValueSelector, reduxForm} from "redux-form";
import DateChooser from "../../../../components/basics/date-chooser/DateChooser";
import {Col, Row} from "react-bootstrap";
import {submit} from "./RFIDTreatmentAddSubmit"
import {compose} from "redux";
import {withTranslation} from "react-i18next";

const formName = "treatment";
const selector = formValueSelector(formName);

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        dates: selector(state, "dates"),
    };
}

class RfidTreatmentAdd extends Component {

    constructor(props) {
        super(props);
        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            this.props.initialize({
                dates: [],
                animals: state.animals
            })
        }
    }

    onDateChange = dates => {
        this.props.change("dates", dates);
    }

    render() {
        const {location: {state}, handleSubmit, submitting, submitFailed, error, dates, farm, submitSucceeded, t} = this.props;
        if (!state) return null;
        return (
            <EventStatusView
                breadcrumbs={[
                    {
                        name: t("scheduleOfTreatment"),
                        link: {
                            pathname: `/${farm}/mobile/scanner`,
                            state: {
                                breadcrumbs: [
                                    {
                                        name: t("scheduleOfTreatment")
                                    }
                                ],
                                eventType: "treatment",
                                status: "medicine"
                            }
                        }
                    },
                    {
                        name: "Lek",
                        link: {
                            pathname: `/${farm}/mobile/events/treatment/medicine`,
                            state: {
                                animals: state.animals
                            }
                        }
                    },
                    {
                        name: t("reason"),
                        link: {
                            pathname: `/${farm}/mobile/events/treatment/reason`,
                            state
                        }
                    },
                    {
                        name: t("date")
                    }
                ]}
                handleSubmit={handleSubmit} submitting={submitting}
                submitFailed={submitFailed} error={error} formName={formName}
                additionalState={{
                    breadcrumbs: [{name: t("scheduleOfTreatment")}],
                    eventType: "treatment",
                    status: "medicine"
                }} submitSucceeded={submitSucceeded}>
                <Row className="justify-content-center h-100 align-items-center">
                    <Col xs={10}>
                        <h5>{t("rfidApp.amountOfSelectedDays")}: {(dates || []).length}</h5>
                        <DateChooser selectedDates={dates || []} onChange={this.onDateChange}/>
                    </Col>
                </Row>
            </EventStatusView>
        );
    }
}

RfidTreatmentAdd = reduxForm({
    form: formName,
    onSubmit: submit
})(RfidTreatmentAdd);

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RfidTreatmentAdd);