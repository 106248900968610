import {SubmissionError} from "redux-form";
import animalsDB from "../../../../../database/animalsDB";

export function submit(values, dispatch, props) {
    const errors = validate(values, props);
    if (errors._error) {
        throw new SubmissionError(errors);
    }
    props.onSubmitDone(values.number, props);
    props.onClose();
}

function validate(values, props) {
    const errors = {};
    const {t} = props;

    if (!values.number) {
        errors._error = t("rfidApp.animalNumberError", {error: t("required")});
    } else {
        if (animalsDB.checkIfAnimalExistOnFarm(values.number + "", props.farm)
            || animalsDB.checkIfAnimalExistOnFarm((values.number + "").padStart(4, "0"), props.farm)) {
            errors._error = t("rfidApp.animalNumberError", {error: (values.number + "").padStart(4, "0") + " " + t("errors.exist")});
        }
    }

    return errors;
}