import React, {Component} from 'react';
import {connect} from 'react-redux';
import EventStatusView from "../../EventStatusView";
import {Field, reduxForm} from "redux-form";
import {submit} from "./RFIDGraftingPigletsStartSubmit"
import {medicineTypes} from "../../../../../constans/medicine";
import {Col, Row} from "react-bootstrap";
import DataInput from "../../../data-input/DataInput";
import animalsDB from "../../../../../database/animalsDB";
import {Level} from "../../../../../constans/levelTypes";
import {getEventsForAnimals} from "../../../../../actions/eventsActions";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {getUnit} from "../../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../../constans/unitTypes";

const formName = "graftingPigletsAdd";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
    };
}

class RFIDGraftingPigletsStart extends Component {

    constructor(props) {
        super(props);

        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            let {animals, location} = state;
            if (location) {
                animals = animalsDB.getAllAnimalsForLocation(location, Level.CHAMBER, {joinEvents: false});
                console.log(animals);
            }
            this.props.dispatch(getEventsForAnimals(animals.map(animal => animal.AnmID)));
            this.props.initialize({
                animals,
                amount: 0,
                forage: 0
            })
        }
    }

    getName() {
        const {match: {params: {medicineType}}, t} = this.props;
        switch (+medicineType) {
            case medicineTypes.VACCINE:
                return t("grafting");
            case medicineTypes.FORAGE:
                return t("medicalFeed");
            default:
                return "";
        }
    }

    getLink() {
        const {match: {params: {medicineType}}} = this.props;
        switch (+medicineType) {
            case medicineTypes.VACCINE:
                return "vaccine";
            case medicineTypes.FORAGE:
                return "forage";
            default:
                return "";
        }
    }

    getBreadcrumbs() {
        const {location: {state}, farm, t} = this.props;
        const {medicine} = state;
        return [{
            name: t("pigletsTreatment"),
            link: `/${farm}/mobile/events/graftingPiglets`
        }, {
            name: this.getName(),
            link: `/${farm}/mobile/events/graftingPiglets/${this.getLink()}`
        }, {name: medicine.WData.Name}];
    }

    render() {
        const {handleSubmit, location: {state}, submitting, submitFailed, error, match: {params: {medicineType}}, submitSucceeded, t} = this.props;
        if (!state) return null;
        const {medicine} = state;
        return (
            <EventStatusView handleSubmit={handleSubmit}
                             breadcrumbs={this.getBreadcrumbs()} submitting={submitting}
                             status={state.status} eventType={state.eventType} counterName="graftingPiglets"
                             submitFailed={submitFailed} error={error} formName={formName}
                             submitSucceeded={submitSucceeded} additionalState={{medicine}}>
                <Row className="justify-content-center h-100">
                    <Col xs={8} className="d-flex flex-column justify-content-center align-items-center w-100">
                        {
                            +medicineType === medicineTypes.VACCINE &&
                            <Field
                                name="amount"
                                component={DataInput}
                                label={t("pigletsAmount")}
                            />
                        }
                        {
                            +medicineType === medicineTypes.FORAGE &&
                            <Field
                                name="forage"
                                component={DataInput}
                                label={t("forageAmount")}
                                step={.5}
                                valueFormatter={value => value.toFixed(1) + getUnit("weight", UnitTypes.MEDIUM)}
                            />
                        }
                    </Col>
                </Row>
            </EventStatusView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation(),
    reduxForm({
        form: formName,
        onSubmit: submit
    })
)(RFIDGraftingPigletsStart);