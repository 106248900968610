import {SubmissionError} from "redux-form";
import {getGrIDByAnimal} from "../../../../../utils/EventUtils";
import {createMommy} from "../../../../../api/events/Mommy";
import {AnimalTypes} from "../../../../../constans/animalTypes";

export function submit(values, dispatch, props) {
    console.log(values);
    let errors = validate(values, props);
    if (errors._error) {
        throw new SubmissionError(errors);
    }
    const {animals, amount} = values;
    const {history, farm, t} = props;
    let data = {
        maxPiglets: 0,
        EvTime: new Date().getTime(),
        animals: animals.map(animal => ({
            AnmID: animal.AnmID,
            Piglets: amount,
            GrID: getGrIDByAnimal(animal, new Date().getTime())
        }))
    };
    return createMommy(data).then(res => {
        setTimeout(() => {
            history.push({
                pathname: `/${farm}/mobile/scanner`,
                state: {
                    eventType: "mommy",
                    status: "add",
                    breadcrumbs: [{
                        name: t("mommy"),
                        link: `/${farm}/mobile/events/mommy`
                    }, {name: t("rfidApp.addToMommy")}],
                    animalType: [AnimalTypes.SOW]
                }
            });
        }, 1500)
        dispatch({type: "ADD_MOMMY_ADD", payload: data});
    }).catch(e => {
        throw new SubmissionError({_error: t("rfidApp.mommyGeneralError")});
    })
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;

    if (values.amount === 0) {
        errors._error = t("rfidApp.amountError", {error: t("errors.lessVal", {count: 1})});
    }

    return errors;
}