import {medicineTypes} from "../../../../../constans/medicine";
import {getGrIDByAnimal, getPigBalanceForSowFromState} from "../../../../../utils/EventUtils";
import {SubmissionError} from "redux-form";
import {createPigletsTreatment} from "../../../../../api/events/PigletsTreatment";

export function submit(values, dispatch, props) {
    console.log(values);
    let errors = validate(values, props);
    if (errors._error) {
        throw new SubmissionError(errors);
    }
    const {animals, amount, forage} = values;
    const {location: {state: {medicine}}, match: {params: {medicineType}}, farm, history, t} = props;
    const time = new Date().getTime();
    let data = animals.map(animal => ({
        AnmID: animal.AnmID,
        Medicine: medicine.WordID,
        Weight: 0, // TODO brak
        TreatmentType: +medicineType,
        AnmCnt: animal.AnmCnt,
        EvTime: time,
        GrID: getGrIDByAnimal(animal, time),
        PiCnt: +medicineType === medicineTypes.FORAGE ? getPigBalanceForSowFromState(animal, time) : amount,
        ForageAmount: +medicineType === medicineTypes.FORAGE ? forage : undefined
    }));
    console.log(data);
    return createPigletsTreatment(data).then(res => {
        setTimeout(() => {
            history.push({
                pathname: `/${farm}/mobile/scanner`,
                state: {
                    medicine,
                    breadcrumbs: [{
                        name: t("pigletsTreatment"),
                        link: `/${farm}/mobile/events/graftingPiglets`
                    }, {name: +medicineType === medicineTypes.VACCINE ? t("grafting") : t("medicalFeed")}],
                    eventType: "graftingPiglets",
                    status: `${medicineType}/add`,
                    advancedAnimalScanner: true,
                    medicineType: medicineType,
                }
            });
        }, 1500)
        dispatch({type: "ADD_GRAFTING_PIGLETS", payload: data});
    }).catch(e => {
        throw new SubmissionError({_errors: t("rfidApp.graftingGeneralError")});
    })
}

export function validate(values, props) {
    const errors = {};
    const {match: {params: {medicineType}}, t} = props;

    if (+medicineType === medicineTypes.FORAGE) {
        if (values.forage === 0) {
            errors._error = t("rfidApp.forageAmountError", {error: t("errors.lessVal", {count: 0.5})});
        } else {
            for (let animal of values.animals) {
                let balance = getPigBalanceForSowFromState(animal, new Date().getTime());
                if (balance === 0) {
                    errors._error = t("rfidApp.noPigletsInAnimal", {AnmNo1: animal.AnmNo1});
                }
            }
        }
    } else {
        if (values.amount === 0) {
            errors._error = t("rfidApp.pigletsError", {error: t("errors.lessVal", {count: 1})});
        } else {
            for (let animal of values.animals) {
                let balance = getPigBalanceForSowFromState(animal, new Date().getTime());
                if (values.amount > balance) {
                    errors._error = t("rfidApp.amountNotEnoughPiglets", {AnmNo1: animal.AnmNo1});
                }
            }
        }
    }

    return errors;
}