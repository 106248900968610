import {SubmissionError} from "redux-form";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import {createWeighting} from "../../../../api/events/Weighting";
import {AnimalTypes} from "../../../../constans/animalTypes";
import {convertWeightToBaseUnit} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";

export function submit(values, dispatch, props) {
    let errors = validate(values, props);
    if (errors._error) {
        throw new SubmissionError(errors);
    }
    const {match: {params: {reasonID}}, weightingReasons, history, farm, t} = props;
    const {animals, weight} = values;

    let data = animals.map(animal => ({
        AnmID: animal.AnmID,
        AnmCnt: animal.AnmCnt,
        EvTime: new Date().getTime(),
        GrID: getGrIDByAnimal(animal, new Date().getTime()),
        Weight: convertWeightToBaseUnit(weight, {fromUnit: UnitTypes.MEDIUM}),
        Reason: reasonID,
        Plcmnt: Array.isArray(animal.PlcmntID) ? animal.PlcmntID[0].PlcmntID : animal.PlcmntID
    }));
    return createWeighting(data).then(res => {
        let reason = weightingReasons.find(item => item.ID === reasonID);
        setTimeout(() => {
            history.push({
                pathname: `/${farm}/mobile/scanner`,
                state: {
                    eventType: "weighting",
                    status: reason.ID,
                    breadcrumbs: [{
                        name: t("weighting"),
                        link: `/${farm}/mobile/events/weighting`
                    }, {name: reason.Value}],
                    animalType: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW]
                }
            });
        }, 1500)
        dispatch({type: "ADD_WEIGHTING", payload: data});
    }).catch(e => {
        throw new SubmissionError({_error: t("rfidApp.weightingGeneralError")});
    })
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;

    if (values.weight === 0) {
        errors._error = t("rfidApp.weightError", {error: t("errors.lessVal", {count: 1})});
    }

    return errors;
}