import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import "./_loader.scss"
import PropTypes from "prop-types"

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class Loader extends Component {

    state = {
        endAnimation: false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.loading !== prevProps.loading && this.props.loading) {
            this.setState({
                endAnimation: false
            })
        }
        if (this.props.success !== prevProps.success && this.props.success) {
            setTimeout(() => {
                this.setState({
                    endAnimation: true
                })
            }, 1000)
        }
    }

    getClassName() {
        let className = "loader";
        const {loading, success} = this.props;
        const {endAnimation} = this.state;
        if (endAnimation) return className + " success end";
        if (loading) return className + " spin";
        if (success) return className + " success";
        return className;
    }

    render() {
        return (
            <div className={this.getClassName()}>
                <svg viewBox="0 0 200 200"  xmlns="http://www.w3.org/2000/svg">
                    <circle cx="100" cy="100" r="88" stroke="currentColor" strokeWidth="20" fill="none" className="path" shapeRendering="geometricPrecision"/>
                    <circle className="fill" fill="none" stroke="currentColor" strokeWidth="20" strokeMiterlimit="10"
                            cx="100" cy="100" r="88" shapeRendering="geometricPrecision"/>
                    <polyline className="check" fill="none" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"
                              points="150,70 86,132 50,100  " shapeRendering="geometricPrecision"/>
                </svg>
            </div>
        );
    }
}

Loader.propTypes = {
    loading: PropTypes.bool,
    success: PropTypes.bool,
}

Loader.defaultProps = {
    loading: false
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(Loader);