import devicesDB from "../../../../../database/devicesDB";
import {getGrIDByAnimal} from "../../../../../utils/EventUtils";
import {createSell} from "../../../../../api/events/Sell";
import {SubmissionError} from "redux-form";
import {SectorType} from "../../../../../constans/sectorTypes";

export function submit(values, dispatch, props) {
    const {animals, amount} = values;
    let errors = validate(values, props);
    if (errors._error) {
        throw new SubmissionError(errors);
    }
    const {location, history, farm, t} = props;
    let anm = amount;
    let data = [];
    for (let animal of animals) {
        let oldDevices = devicesDB.getDevicesInPlcmnt(location, {showDevicesInChildren: false}).map(dev => ({
            DevID: dev.DevID,
            GwID: dev.GatewayID
        }));
        let numberOfAnimals = 0;
        if (Array.isArray(animal.PlcmntID)) {
            let loc = animal.PlcmntID.find(item => item.PlcmntID === location.CID);
            numberOfAnimals = loc.AnmCnt;
        } else {
            numberOfAnimals = animal.AnmCnt;
        }
        let number = anm > numberOfAnimals ? numberOfAnimals : anm;
        anm -= numberOfAnimals;
        data.push({
            AnmID: animal.AnmID,
            amount: number,
            EvTime: new Date().getTime(),
            devices: oldDevices,
            clearFeeding: true,
            GrID: getGrIDByAnimal(animal, new Date().getTime()),
            Weight: 0,
            Price: 0,
            HCW: 0,
            AnimalKind: +animal.AnimalKind
        })
        if (anm <= 0) break;
    }
    console.log(data);
    return createSell(data).then(() => {
        setTimeout(() => {
            history.push({
                pathname: `/${farm}/mobile/locationScanner`,
                state: {
                    eventType: "sell",
                    status: "location",
                    breadcrumbs: [{name: t("sale"), link: `/${farm}/mobile/events/sell`}, {name: t("rfidApp.room")}],
                    sectorTypes: [SectorType.PIGLET_HOUSE, SectorType.PORK_HOUSE]
                }
            });
        }, 1500)
        dispatch({type: "ADD_SELL", payload: data});
    }).catch(e => {
        console.error("error", e)
        throw new SubmissionError({_error: t("rfidApp.sellGeneralError")})
    })
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;

    if (values.amount === 0) {
        errors._error = t("rfidApp.animalCountError", {error: t("errors.lessVal", {count: 1})});
    } else {
        let numberOfAnimals = values.animals.reduce((a, animal) => {
            if (Array.isArray(animal.PlcmntID)) {
                let loc = animal.PlcmntID.find(item => item.PlcmntID === location.CID);
                return loc.AnmCnt + a;
            } else {
                return a + animal.AnmCnt;
            }
        }, 0);
        if (numberOfAnimals < values.amount) {
            errors._error = t("rfidApp.animalCountError", {error: t("errors.maxAnimalsValue", {amount: numberOfAnimals})});
        }
    }

    return errors;
}