import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import RFIDHeader from "../../rfid-header/RFIDHeader";
import animalsDB from "../../../../database/animalsDB";
import {Level} from "../../../../constans/levelTypes";
import {getUsageForPig} from "../../../../IOT/device-functions/GatewayFunctions";
import {DevType} from "../../../../constans/devices";
import LoadingComponent from "../../../../components/loading/LoadingComponent";
import {findIndex, isEqual} from "lodash";
import TableGrid from "../../../../components/basics/table-grid/TableGrid";
import "./_search.scss"
import {withTagReader} from "../../tag-reader-hoc/withTagReader";
import {ProgressBar} from "react-bootstrap";
import {getColorForPercentage} from "../../../../utils/ColorUtils";
import {getFeedingLevels, isUsingFakeData} from "../../../../utils/SettingsUtils";
import fakeData from "../../../../demo/feeding/getUsageForPig.json"

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        gateways: state.farmDevices.devices.filter(item => item.DevType === DevType.GATEWAY)
    };
}

class RfidSearchEnd extends Component {

    state = {
        animals: [],
        messages: {},
        selectedRows: []
    }

    constructor(props) {
        super(props);
        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            const {location} = state;
            let animals = animalsDB.getAllAnimalsForLocation(location, this.getLevel(location), {joinEvents: false});
            let messages = {};
            let anms = [];
            let data = animals.map(animal => {
                anms.push({
                    animal,
                    feeding: null
                })
                return {
                    AnmID: animal.AnmID,
                    PlcmntID: Array.isArray(animal.PlcmntID) ? animal.PlcmntID[0].PlcmntID : animal.PlcmntID,
                    RFID: animal.RFID
                }
            });
            console.log(data);
            if (isUsingFakeData()) {
                messages.fake = true;
                setTimeout(() => {
                    let array = [];
                    for (let i = 0; i < anms.length; i++) {
                        array[i] = {
                            ...anms[i],
                            feeding: fakeData[i % fakeData.length].feeding
                        }
                    }
                    this.setState({
                        animals: this.sortArray(array),
                        messages: {
                            fake: false
                        }
                    })
                }, 1000)
            } else {
                for (let gw of this.props.gateways) {
                    let id = getUsageForPig(gw, data, this.onGetUsageForPigSuccess, this.onGetUsageForPigFailure);
                    messages[id] = true;
                }
            }

            this.state = {
                ...this.state,
                animals: anms,
                messages
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(this.props.tagReader.animal, prevProps.tagReader.animal) && this.props.tagReader.animal) {
            const {tagReader: {animal}, match: {params: {type}}} = this.props;
            let animals = this.state.animals.slice(0);
            let feedingLevels = getFeedingLevels();
            let index = findIndex(animals, o => {
                if (!o.feeding) return false;
                if (o.animal.AnmID !== animal.AnmID) return false;
                if (type === "eating") return o.feeding[0].got / o.feeding[0].amount > feedingLevels.FeedingMaximumLevel;
                return o.feeding[0].got / o.feeding[0].amount < feedingLevels.FeedingMinimumLevel;
            });
            animals = animals.map((row, i) => ({
                ...row,
                highlight: i === index
            }));
            this.setState({
                animals
            }, () => {
                this.props.tagReader.clearTag();
                if (index >= 0) {
                    this.scrollToRow(index);
                    navigator.vibrate([500, 200, 500, 200, 500]);
                }
            })
        }
    }

    scrollToRow(index) {
        let container = document.getElementsByClassName("body-container")[0];
        let row = document.getElementsByClassName("table-row-box")[0];
        let height = row.clientHeight;
        container.scroll({
            top: height * index,
            behavior: "smooth"
        })
    }

    sortFunction(a, b, eating = false) {
        if (eating) {
            if (!a.feeding) return -1;
            if (!b.feeding) return 1;
        } else {
            if (!a.feeding) return 1;
            if (!b.feeding) return -1;
        }
        let percentA = a.feeding[0].got / a.feeding[0].amount;
        let percentB = b.feeding[0].got / b.feeding[0].amount;
        return percentA - percentB;
    }

    sortArray(data) {
        const {match: {params: {type}}} = this.props;
        if (type === "eating") {
            return data.sort((a, b) => this.sortFunction(b, a, true));
        }
        return data.sort((a, b) => this.sortFunction(a, b));
    }

    onGetUsageForPigSuccess = msg => {
        const {CAnsw} = msg;
        const {messages} = this.state;
        const {match: {params: {type}}} = this.props;
        const animals = this.state.animals.slice(0);
        let feedingLevels = getFeedingLevels();
        for (let row of CAnsw) {
            let index = findIndex(animals, o => o.animal.AnmID === row.AnmID);
            if (row.feeding) {
                if ((type === "eating" && row.feeding[0].got / row.feeding[0].amount >= feedingLevels.FeedingMaximumLevel) || (type !== "eating" && row.feeding[0].got / row.feeding[0].amount <= feedingLevels.FeedingMinimumLevel)) {
                    animals[index] = {
                        ...animals[index],
                        feeding: row.feeding
                    }
                } else {
                    animals.splice(index, 1);
                }
            }
        }
        messages[msg.MsgId] = false;
        this.setState({
            messages,
            animals: this.sortArray(animals)
        })
    }

    onGetUsageForPigFailure = (error, msg) => {
        const {messages} = this.state;
        messages[msg.MsgId] = false;
        this.setState({
            messages
        })
    }

    getLevel(location) {
        if (location.FarmID) return Level.FARM;
        if (location.BgID) return Level.BUILDING;
        if (location.SID) return Level.SECTOR;
        if (location.CID) return Level.CHAMBER;
        return Level.BOX;
    }

    getName() {
        const {match: {params: {type}}, t} = this.props;
        if (type === "eating") return t("eating");
        return t("notEating");
    }

    isLoading() {
        const {messages} = this.state;
        for (let key in messages) {
            if (messages[key]) return true;
        }
        return false;
    }

    feedingFormatter = value => {
        if (!value || !value.amount) return "-";
        let percent = ((value.got || 0) / value.amount) * 100;
        return <ProgressBar now={percent}>
            <div role="progressbar" className={`progress-bar ${getColorForPercentage(percent)}`} aria-valuenow={percent}
                 aria-valuemin={0} aria-valuemax={100} style={{width: `${percent}%`}}/>
            <span>{percent.toFixed(1)}%</span>
        </ProgressBar>
    }

    rowClassName = props => {
        return props.highlight ? "highlight" : null;
    }

    onSelectedRowsChanged = rows => {
        this.setState({
            selectedRows: rows
        })
    }

    render() {
        const {farm, location: {state}, t} = this.props;
        if (!state) return null;
        const {animals} = this.state;
        const headers = [
            {
                name: t("animalNumber"),
                field: "animal.AnmNo1"
            },
            {
                name: t("today"),
                field: "feeding[0]",
                valueFormatter: this.feedingFormatter,
                itemClassName: "d-flex"
            },
            {
                name: "-1",
                field: "feeding[1]",
                valueFormatter: this.feedingFormatter,
                itemClassName: "feeding",
                headerClassName: "feeding"
            },
            {
                name: "-2",
                field: "feeding[2]",
                valueFormatter: this.feedingFormatter,
                itemClassName: "feeding",
                headerClassName: "feeding"
            },
            {
                name: "-3",
                field: "feeding[3]",
                valueFormatter: this.feedingFormatter,
                itemClassName: "feeding",
                headerClassName: "feeding"
            },
            {
                name: "-4",
                field: "feeding[4]",
                valueFormatter: this.feedingFormatter,
                itemClassName: "feeding",
                headerClassName: "feeding"
            },
            {
                name: "-5",
                field: "feeding[5]",
                valueFormatter: this.feedingFormatter,
                itemClassName: "feeding",
                headerClassName: "feeding"
            },
            {
                name: "-6",
                field: "feeding[6]",
                valueFormatter: this.feedingFormatter,
                itemClassName: "feeding",
                headerClassName: "feeding"
            }
        ]
        return (
            <div className="search">
                <LoadingComponent isLoading={this.isLoading()}/>
                <RFIDHeader breadcrumbs={[{
                    name: t("searching"),
                    link: `/${farm}/mobile/events/search`
                }, {name: this.getName()}]} back/>
                <TableGrid data={animals} headers={headers} shouldIndex rowClassName={this.rowClassName}/>
                {/*<ButtonGroup>*/}
                {/*    <Button>Temp1</Button>*/}
                {/*    <Button>Temp2</Button>*/}
                {/*    <Button>Temp3</Button>*/}
                {/*</ButtonGroup>*/}
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    withTagReader(),
    connect(mapStateToProps)
)(RfidSearchEnd);