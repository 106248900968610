import React, {Component} from 'react';
import "./_rfid-feedin-modal.scss";
import RfidHeader from "../../rfid-header/RFIDHeader";
import PropTypes from "prop-types";
import Button from "../../../../components/basics/button/Button";
import Loader from "../../loader/Loader";
import {isUsingFakeData} from "../../../../utils/SettingsUtils";

class RfidFeedingModal extends Component {

    state = {
        submitting: false,
        submitted: false
    }

    onSubmit = () => {
        const {onSubmit, onCloseClick, onSuccess} = this.props;
        this.setState({
            submitting: true,
            submitted: false
        });
        let promise = onSubmit();
        Promise.resolve(promise).then(() => {
            this.setState({
                submitted: true,
                submitting: false
            }, () => {
                setTimeout(() => {
                    onCloseClick();
                    if (!isUsingFakeData()) {
                        onSuccess();
                    }
                }, 1500)
            })
        }).catch(e => {
            this.setState({
                submitting: false
            });
        })
    }

    render() {
        const {breadcrumbs, children, onCloseClick, className, disableSubmit} = this.props;
        const {submitted, submitting} = this.state;
        return (
            <div className={`rfid-feeding-modal${className ? " " + className : ""}`}>
                <RfidHeader breadcrumbs={breadcrumbs}/>
                {children}
                <Button className="close" buttonStyle={"text"} onClick={onCloseClick}>
                    <i className="far fa-times-circle"/>
                </Button>
                <Button className="submit" buttonStyle={"text"} onClick={this.onSubmit} disabled={disableSubmit}>
                    <Loader loading={submitting} success={submitted}/>
                </Button>
            </div>
        );
    }
}

RfidFeedingModal.propTypes = {
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        pathname: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    })).isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    className: PropTypes.string,
    disableSubmit: PropTypes.bool,
}

export default RfidFeedingModal;