import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import PropTypes from "prop-types"
import animalsDB from "../../../../../database/animalsDB";
import moment from "moment";
import RFIDHistoryEvent from "../RFIDHistoryEvent";
import {EventTypes} from "../../../../../constans/eventTypes";
import RFIDHistoryScannedComponent from "../RFIDHistoryScannedComponent";
import groupsDB from "../../../../../database/groupsDB";

function mapStateToProps(state) {
    return {
        history: state.eventCounter.history,
    };
}

class RFIDHistorySeparationToPiglethouse extends Component {

    state = {
        data: this.getData()
    }

    getData() {
        const {history} = this.props;
        let data = [];
        for (let event of history) {
            if (event.type !== EventTypes.SEPARATION) break;
            if (event.data.animals) {
                for (let animal of event.data.animals) {
                    data.push({
                        ...animal,
                        ...event
                    })
                }
            } else {
                for (let row of event.data) {
                    data.push(row)
                }
            }
        }
        return data.sort((a, b) => b.EvTime - a.EvTime);
    }

    onDataChange = data => {
        this.setState({
            data
        })
    }

    animalValueFormatter = value => {
        let animal = animalsDB.getAnimalById(value);
        if (animal) return animal.AnmNo1;
        return null;
    }

    dateFormatter = value => {
        return moment(value).format("HH:mm");
    }

    animalNumberFormatter = value => {
        if (value.addToAnimal) {
            return this.animalValueFormatter(value.addToAnimal);
        }
        return value.newAnimalNumber;
    }

    groupNumberFormatter = value => {
        if (value.addToGroup) {
            let group = groupsDB.findGroupByID(value.addToGroup);
            if (group) return group.GrNo1;
        }
        return value.groupNumber || value.newGroupNumber;
    }

    render() {
        const {t} = this.props;
        const {data} = this.state;
        const headers = [
            {
                name: "",
                field: "scanned",
                headerClassName: "scanned",
                itemClassName: "scanned",
                component: <RFIDHistoryScannedComponent/>
            },
            {
                name: t("animal"),
                field: "AnmID",
                valueFormatter: this.animalValueFormatter
            },
            {
                name: t("animalNumber"),
                valueFormatter: this.animalNumberFormatter
            },
            {
                name: t("group"),
                valueFormatter: this.groupNumberFormatter
            },
            {
                name: t("time"),
                field: "EvTime",
                valueFormatter: this.dateFormatter,
                headerClassName: "index",
                itemClassName: "index"
            }
        ]
        return (
            <RFIDHistoryEvent name={t("rfidApp.separation")} data={data} headers={headers} onDataChange={this.onDataChange}/>
        );
    }
}

RFIDHistorySeparationToPiglethouse.propTypes = {
    item: PropTypes.object,
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(RFIDHistorySeparationToPiglethouse);