import React, {Component} from 'react';
import PropTypes from 'prop-types';
import RfidFeedingModal from "./RFIDFeedingModal";
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import Device from "../../../../beans/Device";
import "../../choosers/_animal-chooser.scss"
import {DevType} from "../../../../constans/devices";
import Animal from "../../../../beans/Animal";
import {setAnimal} from "../../../../IOT/device-functions/DispenserNRFFunctions";
import {Col, Row} from "react-bootstrap";
import DataInput from "../../data-input/DataInput";
import {isUsingFakeData} from "../../../../utils/SettingsUtils";
import {get} from "lodash";

function mapStateToProps(state) {
    return {};
}

class RFIDChangeCorrection extends Component {

    state = {
        value: this.props.offset || 0
    }

    onChangeCorrection = value => {
        this.setState({
            value
        })
    }

    sendData(gatewayId, data) {
        return new Promise((resolve, reject) => {
            setAnimal(gatewayId, [data], {onSuccess: resolve, onFailure: reject, showNotification: false})
        })
    }

    onSubmitClick = () => {
        const {value} = this.state;
        const {devices, animal, locationID} = this.props;
        const device = devices[0];
        const data = {
            AnmNo1: animal.AnmNo1,
            RFID: animal.RFID,
            AnmID: animal.AnmID,
            PlcmntID: locationID,
            feeding: {
                percentCorrection: +value,
                number: device.DevType === DevType.DISPENSER ? (
                    get(device.PlcmntID.find(item => item.PlcmntID === locationID), "Adr", -1) + 1 || undefined
                ) : undefined
            }
        }
        if (isUsingFakeData()) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                    this.props.onSuccess({
                        correction: value
                    });
                }, 1500)
            })
        }
        return this.sendData(device.GatewayID, data);
    }

    render() {
        const {onCloseClick, onSuccess, t} = this.props;
        const {value} = this.state;
        return (
            <RfidFeedingModal breadcrumbs={[{name: t("rfidApp.changeDoseCorrection")}]} onCloseClick={onCloseClick}
                              onSubmit={this.onSubmitClick} onSuccess={onSuccess}>
                <Row className="justify-content-center h-100">
                    <Col xs={8} className="d-flex flex-column justify-content-center">
                        <DataInput
                            label={t("doseCorrection")}
                            input={{
                                onChange: this.onChangeCorrection,
                                value
                            }}
                            max={25}
                            min={-25}
                            step={5}
                            valueFormatter={val => val + "%"}
                        />
                    </Col>
                </Row>
            </RfidFeedingModal>
        );
    }
}

RFIDChangeCorrection.propTypes = {
    devices: PropTypes.arrayOf(PropTypes.instanceOf(Device)),
    onCloseClick: PropTypes.func.isRequired,
    workType: PropTypes.string,
    animal: PropTypes.instanceOf(Animal),
    locationID: PropTypes.string,
    onSuccess: PropTypes.func,
    curve: PropTypes.object,
    offset: PropTypes.number,
};

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(RFIDChangeCorrection);