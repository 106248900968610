import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTagReader} from "../../../tag-reader-hoc/withTagReader";
import LastView from "../../../last-view/LastView";
import {AnimalTypes} from "../../../../../constans/animalTypes";
import {getGrIDByAnimal} from "../../../../../utils/EventUtils";
import {createUSG} from "../../../../../api/events/USG";
import {compose} from "redux";
import {Trans, withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        user: state.user.user
    };
}

class RfidUsgResultInfo extends Component {

    state = {
        animals: this.props.location.state ? this.props.location.state.animals : [],
        animalsToSave: [],
        show: false,
        error: null,
        errorTag: null,
        errorAnimal: null
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.tagReader.tag && this.props.tagReader.tag !== prevProps.tagReader.tag) {
            const {tagReader: {animal, tag}} = this.props;
            if (animal) {
                if (!this.state.animals.find(item => item.AnmID === animal.AnmID)) {
                    this.setState({
                        animals: [animal],
                        animalsToSave: this.state.animals,
                        show: false
                    })
                } else {
                    this.setState({
                        show: true,
                        error: 2,
                        errorAnimal: animal
                    })
                }
            } else {
                this.setState({
                    show: true,
                    error: 1,
                    errorTag: tag
                })
            }
        }
    }

    componentDidMount() {
        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        }
    }

    onSubmit = (startTime, clickedButton) => {
        const {match: {params: {status}}} = this.props;
        const {animalsToSave, animals} = this.state;
        let anms = clickedButton ? animals : animalsToSave;
        let data = anms.map(animal => ({
            AnmID: animal.AnmID,
            EvTime: startTime.getTime(),
            GrID: getGrIDByAnimal(animal, startTime.getTime()),
            Pregnant: status === "positive"
        }));
        return createUSG(data).then(res => {
            this.props.dispatch({type: "ADD_USG", payload: data});
        }).catch(e => {
            this.setState({
                show: true,
                error: 0
            })
            throw e;
        })
    };

    onCloseClick = () => {
        this.setState({
            show: false
        })
    }

    getError() {
        const {t} = this.props;
        const {error, errorTag, errorAnimal} = this.state;
        switch (error) {
            case 0:
                return "Nie udało się zgłosić badania USG";
            case 1:
                return t("rfidApp.animalWithNumberNotFound", {errorTag});
            case 2:
                return t("rfidApp.scannedCurrentlySelected", {AnmNo1: errorAnimal.AnmNo1});
            default:
                return "";
        }
    }

    render() {
        const {location: {state}, match: {params}, farm, t} = this.props;
        if (!state) return null;
        const {status} = state;
        const {animals, show, error} = this.state;
        let isPositive = params.status === "positive";
        return (
            <LastView breadcrumbs={[{name: t("usg"), link: `/${farm}/mobile/events/usg`}, {name: t("testResult")}]}
                      eventType={"usg"}
                      text={<Trans i18nKey={"rfidApp.usgText"} count={animals.length} values={{
                          result: isPositive ? t("events.usgEvent.positive") : t("events.usgEvent.negative"),
                          AnmNo1: animals[0].AnmNo1
                      }} components={{b: <strong/>}}/>}
                      status={status}
                      additionalText={t("rfidApp.usgAdditionalText", {count: animals.length})}
                      animals={animals} onSubmit={this.onSubmit}
                      additionalState={{
                          animalType: [AnimalTypes.SOW]
                      }} counterName="usg" error={this.getError()} showError={show} closeError={this.onCloseClick}
                      errorType={error === 0 ? "error" : "warning"}/>
        );
    }
}

RfidUsgResultInfo = withTagReader({
    findAnimalInLocation: true
})(RfidUsgResultInfo);

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RfidUsgResultInfo);