import {convertWeightToBaseUnit} from "../../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../../constans/unitTypes";
import {SubmissionError} from "redux-form";
import {getGrIDByAnimal, getPigBalanceForSowFromState} from "../../../../../utils/EventUtils";
import {createSeparationToMommy} from "../../../../../api/events/SeparationToMommy";
import {AnimalTypes} from "../../../../../constans/animalTypes";


export function submit(values, dispatch, props) {
    console.log(values);
    let errors = validate(values, props);
    if (errors._error) {
        throw new SubmissionError(errors);
    }
    const {animals, amount, weight} = values;
    const {history, farm, t} = props;
    console.log(animals);
    let data = animals.map(animal => ({
        AnmID: animal.AnmID,
        EvTime: new Date().getTime(),
        GrID: getGrIDByAnimal(animal, new Date().getTime()),
        Piglets: amount,
        Weight: convertWeightToBaseUnit(weight, {fromUnit: UnitTypes.MEDIUM})
    }))
    return createSeparationToMommy(data).then(res => {
        setTimeout(() => {
            history.push({
                pathname: `/${farm}/mobile/scanner`,
                state: {
                    eventType: "mommy",
                    status: "add",
                    breadcrumbs: [{
                        name: t("mommy"),
                        link: `/${farm}/mobile/events/mommy`
                    }, {name: t("rfidApp.getFromMommy")}],
                    animalType: [AnimalTypes.SOW]
                }
            });
        }, 1500)
        dispatch({type: "ADD_MOMMY_GET", payload: data});
    }).catch(e => {
        throw new SubmissionError({_error: t("rfidApp.mommyGeneralError")});
    })
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;

    if (values.amount === 0) {
        errors._error = t("rfidApp.amountError", {error: t("errors.lessVal", {count: 1})});
    } else {
        for (let animal of values.animals) {
            let balance = getPigBalanceForSowFromState(animal, new Date().getTime());
            if (values.amount > balance) {
                errors._error = t("rfidApp.amountNotEnoughPiglets", {AnmNo1: animal.AnmNo1});
            }
        }
    }
    if (values.weight === 0) {
        errors._error = t("rfidApp.weightError", {error: t("errors.lessVal", {count: 1})});
    }

    return errors;
}