import {getGrIDByAnimal} from "../../../../../utils/EventUtils";
import {createGrafting} from "../../../../../api/events/Grafting";
import {medicineTypes} from "../../../../../constans/medicine";
import {SubmissionError} from "redux-form";

export function submit(values, dispatch, props) {
    console.log(values);
    let errors = validate(values, props);
    if (errors._error) {
        throw new SubmissionError(errors);
    }
    const {animals, reason} = values;
    const {location: {state: {medicine, breadcrumbs}}, match: {params: {medicineType}}, history, farm, t} = props;
    const time = new Date().getTime();
    let data = animals.map(animal => ({
        AnmID: animal.AnmID,
        Medicine: medicine.WordID,
        Reason: reason,
        Weight: 0, // TODO brak
        TreatmentType: +medicineType,
        StartTime: +medicineType !== medicineTypes.VACCINE ? time : undefined,
        AnmCnt: animal.AnmCnt,
        EvTime: time,
        GrID: getGrIDByAnimal(animal, time)
    }));
    console.log(data);
    return createGrafting(data).then(res => {
        setTimeout(() => {
            history.replace({
                pathname: +medicineType === medicineTypes.VACCINE ? `/${farm}/mobile/scanner` : `/${farm}/mobile/locationScanner`,
                state: {
                    eventType: "grafting",
                    status: `${medicineType}/add`,
                    breadcrumbs,
                    locationNextPath: `/${farm}/mobile/medicineChooser`,
                    medicine,
                    medicineType: +medicineType,
                }
            });
        }, 1500)
        dispatch({type: "ADD_GRAFTING", payload: data});
    }).catch(e => {
        throw new SubmissionError({_errors: t("rfidApp.graftingGeneralError")});
    })
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;

    if (!values.reason) {
        errors._error = t("rfidApp.reasonError", {error: t("required")});
    }

    return errors;
}