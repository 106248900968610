import React, {Component} from 'react';
import {connect} from 'react-redux';
import EventMainView from "../EventMainView";
import MobileAppLink from "../../../../components/basics/mobile-app-link/MobileAppLink";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RFIDTransferMainView extends Component {
    render() {
        const {farm, t} = this.props;
        return (
            <EventMainView breadcrumbs={[{name: t("transfer")}]}>
                <MobileAppLink name={t("pigPen")} href={`/${farm}/mobile/scanner`} state={{
                    eventType: "transfer",
                    status: "chamber",
                    breadcrumbs: [{name: t("transfer"), link: `/${farm}/mobile/events/transfer`}, {name: t("pigPen")}],
                    onlyLocationScanner: true,
                    advancedAnimalScanner: true,
                    animalScannerNextPath: `/${farm}/mobile/locationScanner`,
                    chamberChooser: true,
                    onlyAnimalScanner: true,
                }}/>
                <MobileAppLink name={t("standing")} href={`/${farm}/mobile/scanner`} state={{
                    eventType: "transfer",
                    status: "stand",
                    breadcrumbs: [{
                        name: t("transfer"),
                        link: `/${farm}/mobile/events/transfer`
                    }, {name: t("standing")}],
                    animalScannerNextPath: `/${farm}/mobile/locationScanner`,
                    onlyAnimalScanner: true,
                    onlyLocationScanner: true,
                    standsChooser: true,
                    blockLocationFromScannedAnimal: true,
                    singleSelect: true
                }}/>
            </EventMainView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RFIDTransferMainView);