import React, {Component} from 'react';
import {connect} from 'react-redux';
import MobileAppLink from "../../../../components/basics/mobile-app-link/MobileAppLink";
import {AnimalTypes} from "../../../../constans/animalTypes";
import EventMainView from "../EventMainView";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RfidUsg extends Component {
    render() {
        const {farm, t} = this.props;
        return (
            <EventMainView breadcrumbs={[{name: t("usg")}]}>
                <MobileAppLink name={t("check")} href={`/${farm}/mobile/scanner`} state={{
                    eventType: "usg",
                    status: "check",
                    breadcrumbs: [{name: t("usg"), link: `/${farm}/mobile/events/usg`}, {name: t("check")}],
                    animalType: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW],
                    singleSelect: true
                }}/>
                <MobileAppLink name={t("testResult")} href={`/${farm}/mobile/events/usg/result`}/>
                <MobileAppLink name={t("events.usgEvent.toRepeat")} href={`/${farm}/mobile/scanner`} state={{
                    eventType: "usg",
                    status: "repeat",
                    breadcrumbs: [{
                        name: t("usg"),
                        link: `/${farm}/mobile/events/usg`
                    }, {name: t("events.usgEvent.toRepeat")}],
                    animalType: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW]
                }}/>
            </EventMainView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RfidUsg);