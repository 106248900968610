import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Input from "../../../components/basics/input/Input";
import "./_keyboard.scss";

class Keyboard extends Component {

    state = {
        searchInput: "",
        timeout: null
    };

    /**
     * Handler dla onChange.
     * Wysyła po 300ms chyba, że użytkownik kliknie kolejny przycisk
     */
    onChange() {
        const {onChange} = this.props;
        if (onChange) {
            clearTimeout(this.state.timeout);
            let timeout = setTimeout(() => {
                onChange(this.state.searchInput);
            }, 300);
            this.setState({
                timeout
            })
        }
    }

    renderKeyboard() {
        let array = [];
        for (let i = 1; i <= 9; i++) {
            array.push(
                <div className="keyboard-item" onClick={e => this.onKeyboardClick(e, i)} key={i}>{i}</div>
            )
        }
        array.push(
            <div className="keyboard-item" onClick={e => this.onKeyboardClick(e, 0)} key={0}>0</div>
        );
        return array;
    }

    onKeyboardClick(e, number) {
        this.setState(state => ({
            searchInput: state.searchInput + number
        }), () => {
            this.onChange();
        })
    }

    onBackspaceClick = e => {
        let {searchInput} = this.state;
        if (searchInput.length > 0) {
            searchInput = searchInput.slice(0, -1);
            this.setState({
                searchInput
            }, () => {
                this.onChange();
            })
        }
    };

    render() {
        const {searchInput} = this.state;
        const {placeholder} = this.props;
        return (
            <div className="keyboard">
                <Input type={"number"} readOnly placeholder={placeholder}
                       icon={<i className="fas fa-search"/>} value={searchInput}/>
                <div className="keyboard-buttons">
                    {this.renderKeyboard()}
                    <div className="backspace" onClick={this.onBackspaceClick}>
                        <span><i className="fas fa-times"/></span>
                    </div>
                </div>
            </div>
        );
    }

}

Keyboard.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
};

Keyboard.defaultProps = {
    placeholder: "Numer zwierzęcia"
};

export default Keyboard;