import React, {Component} from 'react';
import {connect} from 'react-redux';
import EventStatusView from "../EventStatusView";
import {Col, Row} from "react-bootstrap";
import {Field, reduxForm} from "redux-form";
import BigDataInput from "../../data-input/BigDataInput";
import {submit} from "./RFIDWeightingEndSubmit"
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {getUnit} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        weightingReasons: state.dictionary.weighting.WData[state.language.lang.lang],
        user: state.user.user,
    };
}

const formName = "weighting";

class RfidWeightingEnd extends Component {

    constructor(props) {
        super(props);

        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            const {animals} = state;
            this.props.initialize({
                weight: 0,
                animals
            })
        }
    }

    render() {
        const {location: {state}, match: {params: {reasonID}}, weightingReasons, handleSubmit, submitting, submitFailed, error, farm, submitSucceeded, t} = this.props;
        let reason = weightingReasons.find(item => item.ID === reasonID);
        if (!state || !reason) return null;
        const {animals} = state;
        return (
            <EventStatusView
                breadcrumbs={[{name: t("weighting"), link: `/${farm}/mobile/events/weighting`}, {name: reason.Value}]}
                handleSubmit={handleSubmit} submitting={submitting}
                submitFailed={submitFailed} error={error} formName={formName} status={state.status}
                eventType={state.eventType} counterName="weighting" submitSucceeded={submitSucceeded}>
                {
                    animals.length === 1 &&
                    <div className="animal-info">
                        {t("rfidApp.animalNumber")}: <span>{animals[0].AnmNo1}</span>
                    </div>
                }
                <Row className="justify-content-center h-100">
                    <Col xs={6} className="d-flex flex-column justify-content-center align-items-center w-100">
                        <Field
                            name="weight"
                            component={BigDataInput}
                            columns={3}
                            label={t("rfidApp.weightInUnit", {unit: getUnit("weight", UnitTypes.MEDIUM)}) + ":"}
                        />
                    </Col>
                </Row>
            </EventStatusView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation(),
    reduxForm({
        form: formName,
        onSubmit: submit
    })
)(RfidWeightingEnd);