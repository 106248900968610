import React, {Component} from 'react';
import LastView from "../../../last-view/LastView";
import {connect} from "react-redux";
import {withTagReader} from "../../../tag-reader-hoc/withTagReader";
import {getGrIDByAnimal} from "../../../../../utils/EventUtils";
import {createSell} from "../../../../../api/events/Sell";
import buildingsDB from "../../../../../database/buildingsDB";
import devicesDB from "../../../../../database/devicesDB";
import {compose} from "redux";
import {Trans, withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        user: state.user.user
    };
}

class RFIDSellAnimal extends Component {

    state = {
        animals: this.props.location.state ? this.props.location.state.animals : [],
        animalsToSave: [],
        show: false,
        error: null,
        errorTag: null,
        errorAnimal: null
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.tagReader.tag && this.props.tagReader.tag !== prevProps.tagReader.tag) {
            const {tagReader: {animal, tag}} = this.props;
            if (animal) {
                if (!this.state.animals.find(item => item.AnmID === animal.AnmID)) {
                    this.setState({
                        animals: [animal],
                        animalsToSave: this.state.animals,
                        show: false
                    })
                } else {
                    this.setState({
                        show: true,
                        error: 2,
                        errorAnimal: animal
                    })
                }
            } else {
                this.setState({
                    show: true,
                    error: 1,
                    errorTag: tag
                })
            }
        }
    }

    componentDidMount() {
        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        }
    }

    onSubmit = (startTime, clickedButton) => {
        const {animalsToSave, animals} = this.state;
        let anms = clickedButton ? animals : animalsToSave;

        let data = anms.map((animal) => {
            let currentLocation = Array.isArray(animal.PlcmntID) ? animal.PlcmntID[0].PlcmntID : animal.PlcmntID;
            let location = buildingsDB.getLocationByID(currentLocation);
            let oldDevices = devicesDB.getDevicesInPlcmnt(location, {showDevicesInChildren: false}).map(dev => ({
                DevID: dev.DevID,
                GwID: dev.GatewayID
            }));
            return {
                AnmID: animal.AnmID,
                amount: animal.AnmCnt,
                EvTime: startTime.getTime(),
                devices: oldDevices,
                clearFeeding: true,
                GrID: getGrIDByAnimal(animal, startTime.getTime()),
                Weight: 0,
                Price: 0,
                HCW: 0,
                AnimalKind: +animal.AnimalKind
            }
        });
        return createSell(data).then(() => {
            this.props.dispatch({type: "ADD_SELL", payload: data});
        }).catch(e => {
            this.setState({
                show: true,
                error: 0
            })
            throw e;
        });
    };

    onCloseClick = () => {
        this.setState({
            show: false
        })
    }

    getError() {
        const {t} = this.props;
        const {error, errorTag, errorAnimal} = this.state;
        switch (error) {
            case 0:
                return t("rfidApp.sellGeneralError");
            case 1:
                return t("rfidApp.animalWithNumberNotFound", {errorTag});
            case 2:
                return t("rfidApp.scannedCurrentlySelected", {AnmNo1: errorAnimal.AnmNo1});
            default:
                return "";
        }
    }

    render() {
        const {location: {state}, farm, t} = this.props;
        console.log(state);
        if (!state) return null;
        const {animals, show, error} = this.state;
        const {status} = state;
        return (
            <LastView breadcrumbs={[{name: t("sale"), link: `/${farm}/mobile/events/sell`}, {
                name: t("animal")
            }]}
                      text={<Trans i18nKey={"rfidApp.sellText"} count={animals.length}
                                   values={{AnmNo1: animals[0].AnmNo1}} components={{bold: <strong/>}}/>}
                      additionalText={t("rfidApp.sellAdditionalText", {count: animals.length})}
                      animals={animals} eventType={"sell"} status={status} onSubmit={this.onSubmit} counterName="sell"
                      showError={show} error={this.getError()} closeError={this.onCloseClick}
                      errorType={error === 0 ? "error" : "warning"}/>
        );
    }
}

RFIDSellAnimal = withTagReader({
    findAnimalInLocation: true
})(RFIDSellAnimal);

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RFIDSellAnimal);