import React, {Component} from 'react';
import {connect} from 'react-redux';
import EventMainView from "../EventMainView";
import MobileAppLink from "../../../../components/basics/mobile-app-link/MobileAppLink";
import "../grafting/_grafting.scss";
import Button from "../../../../components/basics/button/Button";
import {castrationTypes} from "../../../../constans/medicine";
import {Link} from "react-router-dom";
import {AnimalTypes} from "../../../../constans/animalTypes";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RFIDGraftingPigletsMainView extends Component {

    state = {
        selected: []
    };

    onTypeClick(type) {
        let selected = this.state.selected.slice(0);
        if (selected.includes(type)) {
            selected = selected.filter(item => item !== type);
        } else {
            selected.push(type);
        }
        this.setState({
            selected
        })
    }

    getName(number) {
        const {t} = this.props;
        switch (number) {
            case castrationTypes.CASTRATE:
                return t("castrate");
            case castrationTypes.TAILS:
                return t("tails");
            case castrationTypes.TEETH:
                return t("teeth");
            case castrationTypes.WEIGHTING:
                return t("weighting");
            default:
                return "";
        }
    }

    render() {
        const {farm, t} = this.props;
        const {selected} = this.state;
        return (
            <EventMainView breadcrumbs={[{
                name: t("pigletsTreatment"),
                link: `/${farm}/mobile/events/graftingPiglets`
            }, {name: t("castration")}]} additionalData={
                <>
                    <div className="medicine-list">
                        <div
                            className={`fetura-mobile-link${selected.includes(castrationTypes.CASTRATE) ? " selected active" : ""}`}
                            onClick={() => this.onTypeClick(castrationTypes.CASTRATE)}>{this.getName(castrationTypes.CASTRATE)}
                        </div>
                        <div
                            className={`fetura-mobile-link${selected.includes(castrationTypes.TAILS) ? " selected active" : ""}`}
                            onClick={() => this.onTypeClick(castrationTypes.TAILS)}>{this.getName(castrationTypes.TAILS)}
                        </div>
                        <div
                            className={`fetura-mobile-link${selected.includes(castrationTypes.TEETH) ? " selected active" : ""}`}
                            onClick={() => this.onTypeClick(castrationTypes.TEETH)}>{this.getName(castrationTypes.TEETH)}
                        </div>
                        <div
                            className={`fetura-mobile-link${selected.includes(castrationTypes.WEIGHTING) ? " selected active" : ""}`}
                            onClick={() => this.onTypeClick(castrationTypes.WEIGHTING)}>{this.getName(castrationTypes.WEIGHTING)}
                        </div>
                    </div>
                    <Link to={{
                        pathname: `/${farm}/mobile/scanner`,
                        state: {
                            breadcrumbs: [{
                                name: t("pigletsTreatment"),
                                link: `/${farm}/mobile/events/graftingPiglets`
                            }, {
                                name: t("castration"),
                                link: `/${farm}/mobile/events/graftingPiglets/castration`
                            }, {name: selected.length === 1 ? this.getName(selected[0]) : t("many")}],
                            eventType: "graftingPiglets",
                            status: `castration/add`,
                            advancedAnimalScanner: true,
                            selectedTypes: selected,
                            animalType: [AnimalTypes.SOW]
                        }
                    }} disabled={selected.length === 0} className="submit-grafting">
                        <Button buttonStyle={"text"} disabled={selected.length === 0}>
                            <span className="fa-stack">
                                <i className="far fa-chevron-circle-right fa-stack-2x"/>
                            </span>
                        </Button>
                    </Link>
                </>
            }>
                <MobileAppLink name={t("castration")} href={`/${farm}/mobile/events/graftingPiglets/castration`} selected/>
                <MobileAppLink name={t("grafting")} href={`/${farm}/mobile/events/graftingPiglets/vaccine`}/>
                <MobileAppLink name={t("medicalFeed")} href={`/${farm}/mobile/events/graftingPiglets/forage`}/>
            </EventMainView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RFIDGraftingPigletsMainView);