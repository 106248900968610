import React, {Component} from 'react';
import {connect} from 'react-redux';
import EventStatusView from "../../EventStatusView";
import {Field, reduxForm} from "redux-form";
import BigDataInput from "../../../data-input/BigDataInput";
import {Col, Row} from "react-bootstrap";
import Button from "../../../../../components/basics/button/Button";
import {submit} from "./ChangeNumberSubmit"
import "./_change-number.scss"
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
    };
}

const formName = "changeNumber";

class ChangeNumber extends Component {

    constructor(props) {
        super(props);

        this.props.initialize({
            number: this.props.number
        })
    }

    render() {
        const {submitting, error, submitFailed, handleSubmit, onClose, isTaken, number, t} = this.props;
        return (
            <EventStatusView breadcrumbs={[]} formName={formName} submitting={submitting} error={error}
                             submitFailed={submitFailed} handleSubmit={handleSubmit}>
                <Row className="justify-content-center h-100">
                    <Col xs={8}>
                        <Field
                            name="number"
                            component={BigDataInput}
                            label={isTaken ? t("rfidApp.takenNumber", {number}) : t("animalNumber")}
                        />
                    </Col>
                </Row>
                {
                    !isTaken &&
                    <Button buttonStyle={"text"} type={"button"} className="custom-close" onClick={onClose}>
                        <i className="far fa-times-circle"/>
                    </Button>
                }
            </EventStatusView>
        );
    }
}

ChangeNumber = reduxForm({
    form: formName,
    onSubmit: submit
})(ChangeNumber)

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(ChangeNumber);