import React, {Component} from 'react';
import {connect} from 'react-redux';
import LastView from "../../last-view/LastView";
import {withTagReader} from "../../tag-reader-hoc/withTagReader";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import devicesDB from "../../../../database/devicesDB";
import buildingsDB from "../../../../database/buildingsDB";
import {createFall} from "../../../../api/events/Fall";
import {compose} from "redux";
import {Trans, withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        fallReasons: state.dictionary.fallReasons.WData[state.language.lang.lang],
        user: state.user.user
    };
}

class RFIDFallEnd extends Component {

    state = {
        animals: this.props.location.state ? this.props.location.state.animals : [],
        animalsToSave: [],
        show: false,
        error: null,
        errorTag: null,
        errorAnimal: null
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.tagReader.tag && this.props.tagReader.tag !== prevProps.tagReader.tag) {
            const {tagReader: {animal, tag}} = this.props;
            if (animal) {
                if (!this.state.animals.find(item => item.AnmID === animal.AnmID)) {
                    this.setState({
                        animals: [animal],
                        animalsToSave: this.state.animals,
                        show: false
                    })
                } else {
                    this.setState({
                        show: true,
                        error: 2,
                        errorAnimal: animal
                    })
                }
            } else {
                this.setState({
                    show: true,
                    error: 1,
                    errorTag: tag
                })
            }
        }
    }

    componentDidMount() {
        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        }
    }

    onSubmit = (startTime, clickedButton) => {
        const {match: {params: {reasonID}}} = this.props;
        const {animalsToSave, animals} = this.state;
        let anms = clickedButton ? animals : animalsToSave;
        let data = anms.map(animal => {
            let plcmnt = Array.isArray(animal.PlcmntID) ? animal.PlcmntID[0].PlcmntID : animal.PlcmntID;
            let loc = buildingsDB.getLocationByID(plcmnt);
            let devices = devicesDB.getDevicesInPlcmnt(loc, {showDevicesInChildren: false});
            return {
                AnmID: animal.AnmID,
                amount: 1, // z racji ze jest to RFID przyjmowane jest, ze zawsze jest upadek pojedynczego
                from: plcmnt,
                EvTime: startTime.getTime(),
                GrID: getGrIDByAnimal(animal, startTime.getTime()),
                devices: devices.map(dev => ({
                    DevID: dev.DevID,
                    GwID: dev.GatewayID
                })),
                clearFeeding: true,
                Weight: 0, // TODO brakuje w evencie
                Reason: reasonID,
                AnimalKind: +animal.AnimalKind
            }
        });
        return createFall(data)
            .then(() => {
                this.props.dispatch({type: "ADD_FALL", payload: data});
            }).catch(e => {
                this.setState({
                    show: true,
                    error: 0
                })
                throw e;
            })
    };

    onCloseClick = () => {
        this.setState({
            show: false
        });
        this.props.tagReader.clearTag();
    }

    getError() {
        const {t} = this.props;
        const {error, errorTag, errorAnimal} = this.state;
        switch (error) {
            case 0:
                return t("rfidApp.fallGeneralError");
            case 1:
                return t("rfidApp.animalWithNumberNotFound", {errorTag});
            case 2:
                return t("rfidApp.scannedCurrentlySelected", {AnmNo1: errorAnimal.AnmNo1});
            default:
                return "";
        }
    }

    render() {
        const {location: {state}, match: {params: {reasonID}}, fallReasons, farm, t} = this.props;
        let reason = fallReasons.find(item => item.ID === reasonID);
        if (!state || !reason) return null;
        const {animals, show, error} = this.state;
        const {status} = state;
        return (
            <LastView
                breadcrumbs={[
                    {name: t("fall"), link: `/${farm}/mobile/events/fall`},
                    {name: reason.Value}
                ]}
                text={
                    <Trans i18nKey={"rfidApp.fallText"} count={animals.length}
                           values={{AnmNo1: animals[0].AnmNo1, reason: reason.Value}} components={{bold: <strong/>}}/>
                }
                additionalText={t("rfidApp.fallAdditionalText", {count: animals.length})}
                animals={animals} eventType={"fall"} status={status} onSubmit={this.onSubmit} counterName="fall"
                error={this.getError()} showError={show} closeError={this.onCloseClick}
                errorType={error === 0 ? "error" : "warning"}
            />
        );
    }
}

RFIDFallEnd = withTagReader({
    findAnimalInLocation: true
})(RFIDFallEnd);

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RFIDFallEnd);