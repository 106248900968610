import React, {Component} from 'react';
import {connect} from 'react-redux';
import RFIDHeader from "../../../rfid-header/RFIDHeader";
import moment from "moment";
import "../../_event-status-view.scss"
import CheckerContainer from "../../../checker-container/CheckerContainer";
import {getEventsForAnimals} from "../../../../../actions/eventsActions";
import LoadingComponent from "../../../../../components/loading/LoadingComponent";
import {EventTypes} from "../../../../../constans/eventTypes";
import _ from "lodash"
import {compose} from "redux";
import {withTagReader} from "../../../tag-reader-hoc/withTagReader";
import {AnimalTypes} from "../../../../../constans/animalTypes";
import animalsDB from "../../../../../database/animalsDB";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        events: state.events.eventsForAnimals,
        loading: state.events.eventsForAnimalsLoading
    };
}

class RfidCheckInsemination extends Component {

    state = {
        loading: true,
        insemination: null,
        heat: null,
        loadingState: this.props.loading,
        toInsemination: false,
        animal: this.props.location.state ? this.props.location.state.animals[0] : null
    };

    constructor(props) {
        super(props);

        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            this.props.dispatch(getEventsForAnimals([this.state.animal.AnmID], {FarmID: farm}));
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {location: {state}, loading, events} = nextProps;
        if (state && !_.isEqual(prevState.loadingState, nextProps.loadingState)) {
            let insemination = null, heat = null, toInsemination = false;
            // na tym widoku moze byc tylko jedno zwierze
            let animal = prevState.animal;
            let isLoading = loading.get(animal.AnmID);
            console.log(isLoading);
            if (isLoading !== undefined && !isLoading) {
                let eventz = events.get(animal.AnmID).slice(0).sort((a, b) => b.EvTime - a.EvTime);
                console.log(eventz);
                insemination = eventz.find(item => item.EvCode === EventTypes.INSEMINATION && !item.DtaDelTime);
                heat = eventz.find(item => item.EvCode === EventTypes.HEAT && !item.DtaDelTime);
                try {
                    let inseminationsAndToInseminations = eventz.filter(item =>
                        (item.EvCode === EventTypes.INSEMINATION || item.EvCode === EventTypes.TO_INSEMINATION)
                        && item.EvTime < new Date().getTime() && !item.DtaDelTime);
                    inseminationsAndToInseminations.sort((a, b) => b.EvTime - a.EvTime);
                    toInsemination = inseminationsAndToInseminations[0].EvCode === EventTypes.TO_INSEMINATION;
                } catch (e) {
                    toInsemination = false;
                }
            }
            return {
                loading: isLoading,
                insemination,
                heat,
                loadingState: loading,
                toInsemination
            }
        }
        return {
            loadingState: loading
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(this.props.tagReader.animal, prevProps.tagReader.animal) && this.props.tagReader.animal) {
            const {farm} = this.props;
            this.setState({
                animal: this.props.tagReader.animal
            })
            this.props.dispatch(getEventsForAnimals([this.props.tagReader.animal.AnmID], {FarmID: farm}));
        }
    }

    render() {
        const {location: {state}, farm, t} = this.props;
        if (!state) return null;
        const {loading, insemination, heat, toInsemination, animal} = this.state;
        let location = animalsDB.getAnimalLocationsByPlcmntID(animal.PlcmntID, {nameDeep: 2});
        return (
            <div className="check">
                <LoadingComponent isLoading={loading}/>
                <RFIDHeader breadcrumbs={[{
                    name: t("insemination"),
                    link: `/${farm}/mobile/events/insemination`
                }, {name: t("check")}]} back/>
                <CheckerContainer data={[
                    {
                        label: t("animalNumber"),
                        value: animal.AnmNo1
                    },
                    {
                        label: t("location"),
                        value: location[0] ? location[0].name : "-"
                    },
                    {
                        label: t("toInsemination"),
                        value: toInsemination ? t("yes") : t("no"),
                        valueClassName: toInsemination ? "success" : "error"
                    },
                    {
                        label: t("dateOfLastHeat"),
                        value: heat ? moment(heat.EvTime).format("DD.MM.YYYY") : "-"
                    },
                    {
                        label: t("lastInseminationDate"),
                        value: insemination ? moment(insemination.EvTime).format("DD.MM.YYYY") : "-"
                    }
                ]}/>
            </div>
        );
    }
}

export default compose(
    withTagReader({
        animalKind: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW],
        findAnimalInLocation: true
    }),
    connect(mapStateToProps),
    withTranslation(),
)(RfidCheckInsemination);