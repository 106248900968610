import React, {Component} from 'react';
import {connect} from 'react-redux';
import EventStatusView from "../../EventStatusView";
import {Col, Row} from "react-bootstrap";
import {Field, reduxForm} from "redux-form";
import BigDataInput from "../../../data-input/BigDataInput";
import {submit} from "./RFIDSellLocationSubmit";
import animalsDB from "../../../../../database/animalsDB";
import {Level} from "../../../../../constans/levelTypes";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {getLocationID} from "../../../../../utils/BuildingUtils";
import {SectorType} from "../../../../../constans/sectorTypes";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        user: state.user.user,
    };
}

const formName = "sell";

class RFIDSellLocation extends Component {

    constructor(props) {
        super(props);

        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            const {location} = state;
            let animals = animalsDB.getAllAnimalsForLocation(location, this.getLevel(location), {joinEvents: false});
            let amount = animals.reduce((current, animal) => {
                if (Array.isArray(animal.PlcmntID)) {
                    let loc = animal.PlcmntID.find(item => item.PlcmntID === getLocationID(location));
                    return current + loc.AnmCnt;
                } else {
                    return current + animal.AnmCnt;
                }
            }, 0);
            this.props.initialize({
                amount: amount,
                animals
            })
        }
    }

    getLevel(loc) {
        if (loc.FarmID) return Level.FARM;
        if (loc.BgID) return Level.BUILDING;
        if (loc.SID) return Level.SECTOR;
        if (loc.CID) return Level.CHAMBER;
        if (loc.BID) return Level.BOX;
    }

    render() {
        const {location: {state}, handleSubmit, submitting, submitFailed, error, farm, submitSucceeded, t} = this.props;
        if (!state) return null;
        return (
            <EventStatusView
                breadcrumbs={[{name: t("sale"), link: `/${farm}/mobile/events/sell`}, {name: t("rfidApp.room")}]}
                handleSubmit={handleSubmit} submitting={submitting}
                submitFailed={submitFailed} error={error} formName={formName} status={state.status}
                eventType={state.eventType} timesPath={`/${farm}/mobile/locationScanner`} counterName="sell"
                submitSucceeded={submitSucceeded}
                additionalState={{sectorTypes: [SectorType.PIGLET_HOUSE, SectorType.PORK_HOUSE]}}>
                <Row className="justify-content-center h-100">
                    <Col xs={6} className="d-flex flex-column justify-content-center align-items-center w-100">
                        <Field
                            name="amount"
                            component={BigDataInput}
                            columns={4}
                            label={t("animalCount") + ":"}
                        />
                    </Col>
                </Row>
            </EventStatusView>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    reduxForm({
        form: formName,
        onSubmit: submit
    })
)(RFIDSellLocation);
