import React, {Component} from 'react';
import {connect} from 'react-redux';
import EventMainView from "../EventMainView";
import MobileAppLink from "../../../../components/basics/mobile-app-link/MobileAppLink";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RFIDGraftingPigletsMainView extends Component {
    render() {
        const {farm, t} = this.props;
        return (
            <EventMainView breadcrumbs={[{name: t("pigletsTreatment")}]}>
                <MobileAppLink name={t("castration")} href={`/${farm}/mobile/events/graftingPiglets/castration`}/>
                <MobileAppLink name={t("grafting")} href={`/${farm}/mobile/events/graftingPiglets/vaccine`}/>
                <MobileAppLink name={t("medicalFeed")} href={`/${farm}/mobile/events/graftingPiglets/forage`}/>
            </EventMainView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RFIDGraftingPigletsMainView);