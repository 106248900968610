import React, {Component} from 'react';
import {connect} from 'react-redux';
import RfidHeader from "../rfid-header/RFIDHeader";
import animalsDB from "../../../database/animalsDB";
import NotFound from "../../NotFound";
import {getEventsForAnimal} from "../../../actions/eventsActions";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import moment from "moment";
import AgeCounter from "../../../components/basics/age-counter/AgeCounter";
import {Col, Fade, Row} from "react-bootstrap";
import Button from "../../../components/basics/button/Button";
import "./_animal-information.scss"
import Carousel from "../carousel/Carousel";
import CarouselItem from "../carousel/CarouselItem";
import {isEqual} from "lodash";
import {getAverageBreakForAnimal, preEvents} from "../../../utils/AnimalDocumentsUtils";
import {getTimeFromInseminationToPartuition, isUsingFakeData} from "../../../utils/SettingsUtils";
import {EventTypes} from "../../../constans/eventTypes";
import {AnimalTypes} from "../../../constans/animalTypes";
import Tooltip from "../../../components/basics/tooltip/Tooltip";
import {getFeedingDataForPig} from "../../../IOT/device-functions/GatewayFunctions";
import {DevType} from "../../../constans/devices";
import {withTagReader} from "../tag-reader-hoc/withTagReader";
import FeedingHistoryItem from "./FeedingHistoryItem";
import {getColorForPercentage} from "../../../utils/ColorUtils";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import {formatCurveDay} from "../../../utils/FeedingUtils";
import RfidChangeCurveModal from "./modals/RFIDChangeCurveModal";
import devicesDB from "../../../database/devicesDB";
import {getLocationID, getLocationName} from "../../../utils/BuildingUtils";
import RFIDChangeCurveDay from "./modals/RFIDChangeCurveDay";
import RFIDChangeCorrection from "./modals/RFIDChangeCorrection";
import RFIDChangeSkipDoses from "./modals/RFIDChangeSkipDoses";
import RfidAdditionalFeeding from "./modals/RFIDAdditionalFeeding";
import fakeData from "../../../demo/feeding/getFeedingDataForPig.json"
import {getFeedingCurves} from "../../../selectors/feedingSelector";
import ErrorComponent from "../error/ErrorComponent";
import buildingsDB from "../../../database/buildingsDB";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        loadingEvents: state.events.eventsForAnimalsLoading,
        events: state.events.eventsForAnimals,
        graftingPrograms: state.dictionary.graftingPrograms,
        medicines: state.dictionary.medicine,
        gateways: state.farmDevices.devices.filter(item => item.DevType === DevType.GATEWAY),
        curves: getFeedingCurves(state).slice(0).sort((a, b) => a.SetData.Index - b.SetData.Index),
        schedules: state.settings.feedingSchedules
    };
}

class AnimalInformation extends Component {

    state = {
        animal: null,
        loadingEvents: true,
        lastInsemination: null,
        plannedParturitionTime: null,
        page: 0,
        events: [],
        cycle: null,
        parturition: null,
        averageBreak: 0,
        todo: [],
        done: [],
        loadingUsage: true,
        feeding: null,
        show: null,
        location: null,
        devices: [],
        showError: false,
        error: null,
        errorTag: null,
        errorLocation: null
    }

    sentMessages = {};

    constructor(props) {
        super(props);
        let animal = animalsDB.getAnimalById(this.props.match.params.AnmID);
        this.fetchData(animal, this.props.gateways);
        if (animal) {
            let events = this.props.events.get(animal.AnmID);
            let data = this.getData(events, animal);
            let toDoData = this.createToDoAndDone(this.props.graftingPrograms, animal, events);
            let location = animalsDB.getAnimalLocationsByPlcmntID(animal.PlcmntID, {nameDeep: 2})[0];
            let devices = [];
            if (location) {
                devices = devicesDB.getDevicesInPlcmnt(location.location, {showDevicesInChildren: false}).filter(item => item.DevType === DevType.DISPENSER_NRF || item.DevType === DevType.DISPENSER);
            }
            this.state = {
                ...this.state,
                animal,
                events,
                location,
                devices,
                ...data,
                ...toDoData
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.animal) {
            const {AnmID} = prevState.animal;
            let loading = nextProps.loadingEvents.get(AnmID);
            let events = nextProps.events.get(AnmID);
            if (loading !== prevState.loadingEvents && events) {
                return {
                    loadingEvents: loading,
                    events
                };
            }
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(this.state.events, prevState.events)) {
            let data = this.getData(this.state.events, this.state.animal);
            let data2 = this.createToDoAndDone(this.props.graftingPrograms, this.state.animal, this.state.events);
            this.setState({...data, ...data2});
        }
        if (!isEqual(this.props.graftingPrograms, prevProps.graftingPrograms) || !isEqual(this.state.lastInsemination, prevState.lastInsemination)) {
            let data = this.createToDoAndDone(this.props.graftingPrograms, this.state.animal, this.state.events);
            this.setState(data);
        }
        if (this.props.tagReader.tag !== prevProps.tagReader.tag) {
            // jezeli odnaleziono pojedyncze zwierze
            if (this.props.tagReader.animal !== prevProps.tagReader.animal && this.props.tagReader.animal && this.props.tagReader.animal.AnmID !== this.state.animal.AnmID) {
                this.props.history.push(`/${this.props.farm}/mobile/information/${this.props.tagReader.animal.AnmID}`)
            } else if (this.props.tagReader.manyAnimalsInLocation) {
                // jezeli odnaleziono wiele zwierzat w lokalizacji
                this.props.history.push(`/${this.props.farm}/mobile/locationInformation/${getLocationID(this.props.tagReader.location)}`)
            } else if (!this.props.tagReader.animal) {
                if (this.props.tagReader.location) {
                    this.setState({
                        showError: true,
                        error: 2,
                        errorLocation: this.props.tagReader.location
                    })
                } else {
                    this.setState({
                        showError: true,
                        error: 1,
                        errorTag: this.props.tagReader.tag
                    })
                }
            }
        }
    }

    fetchData(animal, gateways) {
        this.props.dispatch(getEventsForAnimal(animal));
        this.getUsage(animal, gateways);
    }

    onGetUsageSuccess = msg => {
        console.log(msg);
        let data = msg.CAnsw[0];
        this.sentMessages[msg.MsgId] = false;
        this.setState({
            feeding: Object.keys(data).length > 1 ? data : null,
            loadingUsage: false
        })
    }

    onGetUsageFailure = (error, msg) => {
        this.sentMessages[msg.MsgId] = false;
        let hasAnyWaiting = false;
        for (let key in this.sentMessages) {
            if (this.sentMessages[key]) hasAnyWaiting = true;
        }
        if (!hasAnyWaiting && !this.state.feeding) {
            this.setState(state => ({
                loadingUsage: false,
                page: state.page === 0 ? 1 : state.page
            }))
        }
    }

    getUsage(animal, gateways, fake = {}) {
        this.sentMessages = {};
        this.setState({
            loadingUsages: true
        })
        for (let gw of gateways) {
            if (isUsingFakeData()) {
                let tree = buildingsDB.getTreeByLocationID(animal.PlcmntID);
                setTimeout(() => {
                    console.log(fake);
                    if (tree.chamber) {
                        let fakeD = fakeData;
                        fakeD.AnmID = this.state.animal.AnmID;
                        if (!tree.chamber.IndividualFeeding && Object.keys(fake).length === 0) {
                            fakeD.workType = "R";
                            fakeD.curve.number = 0;
                            fakeD.curve.correction = 0;
                            fakeD.lastWeekHistory[0].plannedUsage = 3000;
                        }
                        if (fake.curve) {
                            fakeD.curve.number = fake.curve;
                        }
                        if (fake.curveDay) {
                            fakeD.curve.day = fake.curveDay;
                        }
                        if (fake.correction !== undefined) {
                            fakeD.curve.correction = fake.correction;
                        }
                        if (fake.skipDoses) {
                            fakeD.skipDoses = fake.skipDoses;
                        }
                        if (fake.startTime) {
                            fakeD.curve.start = fake.startTime;
                        }
                        console.log(fakeD);
                        this.onGetUsageSuccess({
                            CAnsw: [fakeD]
                        })
                    } else {
                        this.setState({
                            feeding: null,
                            loadingUsage: false
                        })
                    }
                }, 1000)
            } else {
                let id = getFeedingDataForPig(gw, [{
                    AnmID: animal.AnmID,
                    PlcmntID: Array.isArray(animal.PlcmntID) ? animal.PlcmntID[0].PlcmntID : animal.PlcmntID,
                    RFID: animal.RFID
                }], this.onGetUsageSuccess, this.onGetUsageFailure);
                this.sentMessages[id] = true;
            }
        }
    }

    getData(events, animal) {
        let data = preEvents(events, animal);
        let currentCycle = data.cycleTable[data.cycleTable.length - 1];
        let obj = {
            averageBreak: getAverageBreakForAnimal(data.cycleTable)
        }
        if (currentCycle) {
            obj = {
                ...obj,
                cycle: currentCycle.cycle,
                lastInsemination: currentCycle[EventTypes.INSEMINATION][0],
                parturition: currentCycle[EventTypes.PARTURITION][0]
            }
            if (obj.lastInsemination) {
                let time = getTimeFromInseminationToPartuition();
                obj.plannedParturitionTime = moment(obj.lastInsemination.EvTime).add(time, "days");

            }
        }
        return obj;
    }

    createToDoAndDone(graftingPrograms, animal, events) {
        let obj = {
            todo: [],
            done: []
        };
        let e = preEvents(events, animal);
        let currentCycle = e.cycleTable[e.cycleTable.length - 1];
        let startDate = animal.AnimalKind === AnimalTypes.SOW || animal.AnimalKind === AnimalTypes.RENOVATION_SOW ? currentCycle ? currentCycle.StartTime : null : animal.DtaBrthTime;
        if (startDate) {
            let graftingProgram = graftingPrograms.find(item => item.WData.AnimalKind === animal.AnimalKind);
            if (graftingProgram) {
                for (let row of graftingProgram.WData.MedicineList) {
                    let medicine = this.props.medicines.find(item => item.WordID === row.Medicine);
                    obj.todo.push({
                        date: moment(startDate).add(row.Age, "days"),
                        text: medicine ? medicine.WData.Name : "-",
                        medicine
                    })
                }
            }
            let treatments = events.filter(item => item.EvCode === EventTypes.TREATMENT);
            for (let event of treatments) {
                for (let treatment of event.EvData.Treatment) {
                    let dates = treatment.Dates.filter(item => item > startDate);
                    for (let date of dates) {
                        for (let medicineID of treatment.Medicine) {
                            let medicine = this.props.medicines.find(item => item.WordID === medicineID);
                            obj.todo.push({
                                date: moment(date),
                                text: medicine ? medicine.WData.Name : "-",
                                medicine
                            })
                        }
                    }
                }
            }
            let graftings = events.filter(item => item.EvCode === EventTypes.GRAFTING && item.EvTime > startDate);
            for (let grafting of graftings) {
                let medicine = this.props.medicines.find(item => item.WordID === grafting.EvData.Medicine);
                obj.done.push({
                    date: moment(grafting.EvTime),
                    text: medicine ? medicine.WData.Name : "-",
                    medicine
                })
                obj.todo = obj.todo.filter(item => item.medicine && medicine && (item.medicine.WordID !== medicine.WordID || !item.date.isSame(grafting.EvTime, "day")));
            }
        }
        obj.todo.sort((a, b) => a.date.toDate().getTime() - b.date.toDate().getTime());
        obj.todo = obj.todo.slice(0, 8);
        obj.done.sort((a, b) => b.date.toDate().getTime() - a.date.toDate().getTime());
        obj.done = obj.done.slice(0, 8);
        return obj;
    }

    onPageChange = page => {
        this.setState({
            page
        })
    }

    getCurveObject() {
        const {curves} = this.props;
        const {feeding} = this.state;
        if (!feeding || !feeding.curve) return null;
        if (isUsingFakeData()) {
            return curves.find(item => item.SetData.Index === feeding.curve.number - 1);
        }
        return curves.find(item => item.SetID === feeding.curve.id) || null;
    }

    getCurve() {
        const {t} = this.props;
        const {feeding} = this.state;
        if (feeding && feeding.curve && feeding.curve.number === 0 && feeding.workType === "R") {
            return t("deviceRows.feeding.dispenserRow.defaultCurve");
        }
        let curve = this.getCurveObject();
        if (curve) {
            return curve.SetData.Name;
        }
        return "-";
    }

    getCorrection() {
        const {feeding} = this.state;
        if (!feeding) return "-";
        return feeding.curve.correction;
    }

    getHistory() {
        let array = [];
        try {
            const {feeding: {lastWeekHistory}} = this.state;
            if (lastWeekHistory) {
                for (let i = 1; i < 7; i++) {
                    if (lastWeekHistory[i] && lastWeekHistory[i].plannedUsage) {
                        array.push(
                            <FeedingHistoryItem usage={lastWeekHistory[i].usage} max={lastWeekHistory[i].plannedUsage}
                                                text={"-" + i} key={i}/>
                        )
                    } else {
                        array.push(<FeedingHistoryItem usage={0} max={100} key={i} text={"-" + (i)}/>)
                    }
                }
            }
            return array;
        } catch (e) {
            for (let i = 0; i < 6; i++) {
                array.push(
                    <FeedingHistoryItem usage={0} max={100} key={i} text={"-" + (i + 1)}/>
                )
            }
        }
        return array;
    }

    getUsageForToday() {
        const {t} = this.props;
        const {feeding} = this.state;
        if (feeding) {
            const {lastWeekHistory} = feeding;
            if (lastWeekHistory && lastWeekHistory[0]) {
                let {usage, plannedUsage} = lastWeekHistory[0];
                let percent = (usage / plannedUsage) * 100;
                return (
                    <div className="big-history">
                        <div className={`big-history-indicator ${getColorForPercentage(percent)}`}>
                            {convertWeightUnitTo(usage, {unit: UnitTypes.MEDIUM, showUnit: true, fixed: 1})}
                            &nbsp;/&nbsp;
                            {convertWeightUnitTo(plannedUsage, {unit: UnitTypes.MEDIUM, showUnit: true, fixed: 1})}
                        </div>
                        <div className="big-history-text">{t("today")}</div>
                    </div>
                )
            }
        } else {
            return (
                <div className="big-history">
                    <div className="big-history-indicator error">- / -</div>
                    <div className="big-history-text">{t("today")}</div>
                </div>
            )
        }
    }

    getCurveDay() {
        const {feeding} = this.state;
        if (!feeding) return "-";
        let curve = this.getCurveObject();
        if (curve) {
            const {day} = feeding.curve;
            return formatCurveDay(day, curve);
        }
        return "-";
    }

    getSchedule() {
        const {feeding} = this.state;
        const {schedules} = this.props;
        try {
            if (isUsingFakeData()) {
                const {schedule: {number}} = feeding;
                return schedules[number - 1];
            }
            const {schedule: {id}} = feeding;
            return schedules.find(item => item.SetID === id);
        } catch (e) {
            return null;
        }
    }

    getNextFeeding() {
        const {feeding} = this.state;
        if (!feeding) return "-";
        const {skipDoses} = feeding;
        let schedule = this.getSchedule();
        if (schedule && skipDoses) {
            let nextDoses = schedule.SetData.Doses.filter((item, index) => {
                let feedingTime = moment(item.Start);
                let tmp = moment().hour(feedingTime.hour()).minute(feedingTime.minute());
                return tmp.toDate().getTime() > new Date().getTime() && !skipDoses[index]
            });
            let nextDose = nextDoses[0];
            if (nextDose) {
                return moment(nextDose.Start).format("HH:mm");
            }
        }
        return "-";
    }

    onChangeCurveClick = () => {
        this.setState({
            show: "curve"
        })
    }

    onChangeDayClick = () => {
        this.setState({
            show: "day"
        })
    }

    onChangeCorrectionClick = () => {
        this.setState({
            show: "correction"
        })
    }

    onSkipDosesClick = () => {
        this.setState({
            show: "skip"
        })
    }

    onAdditionalFeedingClick = () => {
        this.setState({
            show: "additionalFeeding"
        })
    }

    onCloseClick = () => {
        this.setState({
            show: null
        })
    }

    onChangeSuccess = (fakeData) => {
        const {animal} = this.state;
        const {gateways} = this.props;
        this.getUsage(animal, gateways, fakeData);
    }

    getStage() {
        const {feeding} = this.state;
        try {
            return feeding.curve.eventStage;
        } catch (e) {
            return 0;
        }
    }

    getCurveStart() {
        const {feeding} = this.state;
        try {
            return feeding.curve.start;
        } catch (e) {
            return 0;
        }
    }

    getSkipDoses() {
        const {feeding} = this.state;
        try {
            return feeding.skipDoses;
        } catch (e) {
            return [0, 0, 0, 0, 0, 0]
        }
    }

    getError() {
        const {t} = this.props;
        const {error, errorTag, errorLocation} = this.state;
        switch (error) {
            case 1:
                return t("rfidApp.animalWithNumberNotFound", {errorTag});
            case 2:
                return t("rfidApp.animalsNotFoundInLocation", {location: getLocationName(errorLocation)});
            default:
                return "";
        }
    }

    onCloseErrorClick = () => {
        this.setState({
            showError: false
        })
    }

    showCurveDayAndCorrection() {
        const {feeding} = this.state;
        return !(feeding && feeding.curve && feeding.curve.number === 0 && feeding.workType === "R");
    }

    render() {
        const {
            animal, loadingEvents, lastInsemination, plannedParturitionTime, page, cycle, parturition, averageBreak,
            todo, done, loadingUsage, show, location, devices, feeding, showError
        } = this.state;
        const {location: {state}, t} = this.props;
        if (!animal) return <NotFound/>
        let day = this.getCurveDay();
        let offset = this.getCorrection();
        let schedule = this.getSchedule();
        let isBlurred = !feeding || (feeding && !feeding.feeding);
        return (
            <div className="animal-information">
                <RfidHeader breadcrumbs={[{name: `${animal.AnmNo1} ${animal.RFID ? `(${animal.RFID})` : ""}`}]}
                            home={!state || !state.back} back={state && state.back}/>
                <div className="h-100">
                    <Carousel page={page} onPageChange={this.onPageChange}>
                        <CarouselItem>
                            <LoadingComponent isLoading={loadingUsage}/>
                            <Row className={isBlurred ? "blur" : null}>
                                <Col xs={5}>{t("feedingCurve")}:</Col>
                                <Col xs={5}>
                                    <div>{this.getCurve()}</div>
                                </Col>
                                <Col xs={2}>
                                    <Button icon={<i className="far fa-cog fa-fw"/>} onClick={this.onChangeCurveClick}
                                            disabled={!feeding}/>
                                </Col>
                            </Row>
                            <Row className={isBlurred ? "blur" : null}>
                                <Col xs={5}>{t("curveDay")}:</Col>
                                <Col xs={5}>
                                    <div>{day}</div>
                                </Col>
                                <Col xs={2}>
                                    {
                                        this.showCurveDayAndCorrection() &&
                                        <Button icon={<i className="far fa-cog fa-fw"/>} onClick={this.onChangeDayClick}
                                                disabled={!feeding}/>
                                    }
                                </Col>
                            </Row>
                            <Row className={isBlurred ? "blur" : null}>
                                <Col xs={5}>{t("modals.feedingManageFeedingModal.doseCorrection")}:</Col>
                                <Col xs={5}>
                                    <div>{offset}%</div>
                                </Col>
                                <Col xs={2}>
                                    {
                                        this.showCurveDayAndCorrection() &&
                                        <Button icon={<i className="far fa-cog fa-fw"/>}
                                                onClick={this.onChangeCorrectionClick}
                                                disabled={!feeding}/>
                                    }
                                </Col>
                            </Row>
                            {
                                feeding && feeding.workType && feeding.workType !== "R" &&
                                <Row className={isBlurred ? "blur" : null}>
                                    <Col xs={5}>{t("nextFeeding")}:</Col>
                                    <Col xs={5}>
                                        <div>{this.getNextFeeding()}</div>
                                    </Col>
                                    <Col xs={2}>
                                        <Button icon={<i className="far fa-cog fa-fw"/>} onClick={this.onSkipDosesClick}
                                                disabled={!feeding || !schedule}/>
                                    </Col>
                                </Row>
                            }
                            <Row className={isBlurred ? "blur" : null}>
                                <Col xs={10}>
                                    <div className="feeding">
                                        <div className="feeding-today">
                                            {this.getUsageForToday()}
                                        </div>
                                        {this.getHistory()}
                                    </div>
                                </Col>
                                <Col xs={2}>
                                    {
                                        feeding && feeding.workType && feeding.workType !== "R" &&
                                        <Button icon={<i className="far fa-utensils fa-fw"/>}
                                                onClick={this.onAdditionalFeedingClick} disabled={false}/>
                                    }
                                </Col>
                            </Row>
                            {
                                !feeding &&
                                <div className="no-feeding">
                                    <div>{t("noFeeding")}</div>
                                </div>
                            }
                            {
                                feeding && !feeding.feeding &&
                                <div className="no-feeding">
                                    <div>
                                        {
                                            feeding.workType && feeding.workType === "R" &&
                                            <div>{t("feedingNotConfigured")}</div>
                                        }
                                        {
                                            feeding.workType && feeding.workType !== "R" &&
                                            <div>{t("deviceRows.feeding.dispenserRow.stop")}</div>
                                        }
                                        <Button icon={<i className="far fa-cog fa-fw"/>}
                                                onClick={this.onChangeCurveClick}
                                                disabled={false}/>
                                    </div>
                                </div>
                            }
                        </CarouselItem>
                        <CarouselItem>
                            <LoadingComponent isLoading={loadingEvents}/>
                            <Row>
                                <Col xs={6}>{t("location")}:</Col>
                                <Col xs={6}>{location ? location.name : "-"}</Col>
                            </Row>
                            <Row>
                                <Col xs={6}>{t("age")}:</Col>
                                <Col xs={6}>
                                    <AgeCounter start={animal.DtaBrthTime}
                                                stop={animal.DtaDthTime || new Date().getTime()}/>
                                </Col>
                            </Row>
                            {
                                animal.AnimalKind === AnimalTypes.SOW &&
                                <>
                                    <Row>
                                        <Col xs={6}>{t("cycle")}:</Col>
                                        <Col xs={6}>
                                            {cycle !== null ? cycle : "-"}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6}>{t("lastInseminationDate")}:</Col>
                                        <Col xs={6}>
                                            {lastInsemination ? moment(lastInsemination.EvTime).format("DD.MM.YYYY") : "-"}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6}>{t("modals.feedingSowInfoModal.plannedParturitionDate")}:</Col>
                                        <Col xs={6}>
                                            {plannedParturitionTime ? plannedParturitionTime.format("DD.MM.YYYY") : "-"}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6}>{t("rfidApp.parturitionHistoryShort")}:</Col>
                                        <Col xs={6}>
                                            {parturition ? `${parturition.EvData.HealthyCnt} / ${parturition.EvData.DeadCnt} / ${parturition.EvData.MummyCnt}` : "- / - / -"}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6}>{t("rfidApp.sowIdleDays")}:</Col>
                                        <Col xs={6}>
                                            {averageBreak}
                                        </Col>
                                    </Row>
                                </>
                            }
                        </CarouselItem>
                        <CarouselItem>
                            <LoadingComponent isLoading={loadingEvents}/>
                            <Row className="h-100">
                                <Col xs={6}>
                                    <h5 className="justify-content-center">{t("toDo")}</h5>
                                    <ul>
                                        {
                                            todo.map((item, index) =>
                                                <li key={index}>
                                                    <Row>
                                                        <Col xs={3}>{item.date.format("DD.MM")}</Col>
                                                        <Tooltip tooltipContent={item.text}>
                                                            <Col xs={9} className="todo-text">{item.text}</Col>
                                                        </Tooltip>
                                                    </Row>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </Col>
                                <Col xs={6}>
                                    <h5 className="justify-content-center">{t("done")}</h5>
                                    <ul>
                                        {
                                            done.map((item, index) =>
                                                <li key={index}>
                                                    <Row>
                                                        <Col xs={3}>{item.date.format("DD.MM")}</Col>
                                                        <Tooltip tooltipContent={item.text}>
                                                            <Col xs={9} className="todo-text">{item.text}</Col>
                                                        </Tooltip>
                                                    </Row>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </Col>
                            </Row>
                        </CarouselItem>
                    </Carousel>
                    <Fade in={show === "curve"} unmountOnExit mountOnEnter>
                        <div>
                            <RfidChangeCurveModal sectorType={location ? location.sectorType : null} devices={devices}
                                                  curve={this.getCurveObject()} onCloseClick={this.onCloseClick}
                                                  workType={feeding ? feeding.workType : null} animal={animal}
                                                  locationID={getLocationID(location.location)}
                                                  onSuccess={this.onChangeSuccess}
                                                  fakeData={feeding}/>
                        </div>
                    </Fade>
                    <Fade in={show === "day"} unmountOnExit mountOnEnter>
                        <div>
                            <RFIDChangeCurveDay devices={devices} curve={this.getCurveObject()}
                                                onCloseClick={this.onCloseClick}
                                                workType={feeding ? feeding.workType : null} animal={animal}
                                                locationID={getLocationID(location.location)}
                                                onSuccess={this.onChangeSuccess} stage={this.getStage()}
                                                curveStart={this.getCurveStart()}/>
                        </div>
                    </Fade>
                    <Fade in={show === "correction"} unmountOnExit mountOnEnter>
                        <div>
                            <RFIDChangeCorrection devices={devices}
                                                  onCloseClick={this.onCloseClick}
                                                  workType={feeding ? feeding.workType : null} animal={animal}
                                                  locationID={getLocationID(location.location)}
                                                  onSuccess={this.onChangeSuccess}
                                                  offset={typeof offset === "string" ? 0 : offset}/>
                        </div>
                    </Fade>
                    <Fade in={show === "skip"} unmountOnExit mountOnEnter>
                        <div>
                            <RFIDChangeSkipDoses devices={devices}
                                                 onCloseClick={this.onCloseClick}
                                                 onSuccess={this.onChangeSuccess}
                                                 locationID={getLocationID(location.location)}
                                                 skipDoses={this.getSkipDoses()} schedule={schedule}/>
                        </div>
                    </Fade>
                    <Fade in={show === "additionalFeeding"} unmountOnExit mountOnEnter>
                        <div>
                            <RfidAdditionalFeeding devices={devices}
                                                   onCloseClick={this.onCloseClick}
                                                   onSuccess={this.onChangeSuccess}
                                                   locationID={getLocationID(location.location)}/>
                        </div>
                    </Fade>
                </div>
                <ErrorComponent onCloseClick={this.onCloseErrorClick} error={this.getError()} show={showError}
                                type={"warning"}/>
            </div>
        );
    }
}

AnimalInformation = withTagReader({
    findAnimalInLocation: true
})(AnimalInformation);

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(AnimalInformation);