import React, {Component} from 'react';
import {connect} from 'react-redux';
import RFIDHeader from "../../rfid-header/RFIDHeader";
import InfoBox from "../../../../components/basics/info-box/InfoBox";
import Carousel from "../../carousel/Carousel";
import CarouselItem from "../../carousel/CarouselItem";
import MobileAppLink from "../../../../components/basics/mobile-app-link/MobileAppLink";
import '../no-pregnancy/_rfid-no-pregnancy.scss'
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        fallReasons: state.dictionary.fallReasons.WData[state.language.lang.lang]
    };
}

class RFIDFall extends Component {

    state = {
        page: 0
    };

    onPageChange = page => {
        this.setState({
            page
        })
    };

    splitReasons(reasons) {
        let slicedReasons = [];
        for (let i = 0; i < reasons.length; i += 3) {
            slicedReasons.push(reasons.slice(i, i + 3));
        }
        return slicedReasons;
    }

    render() {
        const {fallReasons, farm, t} = this.props;
        const {page} = this.state;
        return (
            <div className="event">
                <RFIDHeader breadcrumbs={[
                    {
                        name: t("fall")
                    }
                ]} home/>
                {
                    fallReasons.length === 0 &&
                    <InfoBox boxColor={"warning"}>{t("rfidApp.fallReasonsNotFound")}</InfoBox>
                }
                {
                    fallReasons.length > 0 &&
                    <Carousel page={page} onPageChange={this.onPageChange} title={t("rfidApp.chooseReason") + ":"}>
                        {
                            this.splitReasons(fallReasons).map((reasons, index) =>
                                <CarouselItem key={index}>
                                    {
                                        reasons.map((reason, index2) =>
                                            <MobileAppLink name={reason.Value}
                                                           href={`/${farm}/mobile/scanner`}
                                                           key={index2}
                                                           state={{
                                                               eventType: "fall",
                                                               status: reason.ID,
                                                               breadcrumbs: [{
                                                                   name: t("fall"),
                                                                   link: `/${farm}/mobile/events/fall`
                                                               }, {name: reason.Value}]
                                                           }}/>
                                        )
                                    }
                                </CarouselItem>
                            )
                        }
                    </Carousel>
                }
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RFIDFall);