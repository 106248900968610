import {SubmissionError} from "redux-form";
import {getPigBalance} from "../../../../utils/EventUtils";
import animalsDB from "../../../../database/animalsDB";
import {createSeparationByAnimal} from "../../../../api/events/Separation";

export function submit(values, dispatch, props) {
    let errors = validate(values, props);
    if (errors._error) {
        throw new SubmissionError(errors);
    }
    console.log(values, props);
    const {animal, amount} = values;
    const {location: {state: {group, locationTo, groupNumber, animalNumber}}, history, farm, t} = props;
    console.log(group)
    let data = [{
        AnmID: animal.AnmID,
        piglets: amount,
        EvTime: new Date().getTime(),
        weight: 0, // TODO brak w evencie
        separateTo: locationTo.CID
    }];
    if (group) {
        let animalsFromGroupInPlcmnt = group.AnmIDs.map(id => animalsDB.getAnimalById(id)).filter(animal => {
            if (!animal) return false;
            if (animal.DtaDthTime) return false;
            if (Array.isArray(animal.PlcmntID)) {
                return !!animal.PlcmntID.find(item => item.PlcmntID === locationTo.CID);
            }
            return animal.PlcmntID === locationTo.CID;
        });
        let addToAnimal = animalsFromGroupInPlcmnt[0];
        data[0] = {
            ...data[0],
            addToAnimal: addToAnimal.AnmID,
            addToGroup: group.AnmGrp
        }
    } else {
        data[0] = {
            ...data[0],
            newGroupNumber: groupNumber + "",
            newAnimalNumber: animalNumber + ""
        }
    }
    return createSeparationByAnimal(data).then(res => {
        setTimeout(() => {
            history.push(`/${farm}/mobile/events/separationToPigletHouse`);
        }, 1500)
        dispatch({type: "ADD_SEPARATION_TO_PIGLETHOUSE", payload: data});
    }).catch(e => {
        throw new SubmissionError({_error: t("rfidApp.separationGeneralError")});
    })
}

export function validate(values, props) {
    const errors = {};
    const {t, events} = props;

    if (values.amount === 0) {
        errors._error = t("rfidApp.separatedPigletsError", {error: t("errors.lessVal", {count: 1})});
    } else {
        let piglets = getPigBalance(events);
        if (piglets < values.amount) {
            errors._error = t("rfidApp.separatedPigletsError", {error: t("errors.maxAnimalsValue", {amount: piglets})});
        }
    }

    return errors;
}