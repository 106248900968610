import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import {createTreatment} from "../../../../api/events/Treatment";
import {SubmissionError} from "redux-form";

export function submit(values, dispatch, props) {
    console.log("Valjus:",values);
    let errors = validate(values, props);
    if (errors._error) {
        throw new SubmissionError(errors);
    }
    const {animals, dates} = values;
    const {history, farm, t} = props;
    let anms = animals.map(animal => ({
        AnmID: animal.AnmID,
        GrID: getGrIDByAnimal(animal, new Date().getTime()),
        AnmCnt: animal.AnmCnt
    }));
    let treatmentData = [
        {
            dates: dates.map(date => date.toDate().getTime()),
            medicines: props.location.state.medicines.map(medicine => medicine.WordID)
        }
    ];
    let data = [{
        treatment: treatmentData,
        animals: anms,
        EvTime: new Date().getTime(),
        Reason: props.location.state.reason
    }];
    console.log("Dejta:",data);
    return createTreatment(data).then(() => {
        setTimeout(() => {
            history.push({
                pathname: `/${farm}/mobile/scanner`,
                state: {
                    eventType: "treatment",
                    status: "medicine",
                    breadcrumbs: [{name: t("scheduleOfTreatment")}]
                }
            });
        }, 1500)
        dispatch({type: "ADD_TREATMENT", payload: data});
    }).catch(() => {
        throw new SubmissionError({_error: t("rfidApp.treatmentGeneralError")});
    })
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;

    if (!values.dates || values.dates.length === 0) {
        errors._error = t("rfidApp.dateError", {error: props.errorText.required});
    }

    return errors;
}
