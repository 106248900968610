import React, {Component} from 'react';
import {connect} from 'react-redux';
import EventMainView from "../EventMainView";
import MobileAppLink from "../../../../components/basics/mobile-app-link/MobileAppLink";
import {AnimalTypes} from "../../../../constans/animalTypes";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RfidUsgResult extends Component {
    render() {
        const {farm, t} = this.props;
        return (
            <EventMainView
                breadcrumbs={[{name: t("usg"), link: `/${farm}/mobile/events/usg`}, {name: t("testResult")}]}
                additionalData={
                    <>
                        <MobileAppLink name={t("events.usgEvent.positive")} href={`/${farm}/mobile/scanner`} state={{
                            eventType: "usg",
                            status: "result/positive",
                            breadcrumbs: [{
                                name: t("usg"),
                                link: `/${farm}/mobile/events/usg`
                            }, {name: t("testResult")}],
                            animalType: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW]
                        }}/>
                        <MobileAppLink name={t("events.usgEvent.negative")} href={`/${farm}/mobile/scanner`} state={{
                            eventType: "usg",
                            status: "result/negative",
                            breadcrumbs: [{
                                name: t("usg"),
                                link: `/${farm}/mobile/events/usg`
                            }, {name: t("testResult")}],
                            animalType: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW]
                        }}/>
                    </>
                }>
                <MobileAppLink name={t("check")} href={`/${farm}/mobile/scanner`} state={{
                    eventType: "usg",
                    status: "check",
                    breadcrumbs: [{name: t("usg"), link: `/${farm}/mobile/events/usg`}, {name: t("check")}],
                    animalType: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW],
                    singleSelect: true
                }}/>
                <MobileAppLink name={t("testResult")} href={`/${farm}/mobile/events/usg/result`} selected/>
                <MobileAppLink name={t("events.usgEvent.toRepeat")} href={`/${farm}/mobile/scanner`} state={{
                    eventType: "usg",
                    status: "repeat",
                    breadcrumbs: [{name: t("usg"), link: `/${farm}/mobile/events/usg`}, {name: t("events.usgEvent.toRepeat")}],
                    animalType: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW]
                }}/>
            </EventMainView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RfidUsgResult);