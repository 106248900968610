import React, {Component} from 'react';
import {connect} from 'react-redux';
import EventMainView from "../EventMainView";
import MobileAppLink from "../../../../components/basics/mobile-app-link/MobileAppLink";
import {medicineTypes} from "../../../../constans/medicine";
import "./_grafting.scss";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        medicines: state.dictionary.medicine.filter(item => item.WData.Type === medicineTypes.VACCINE)
    };
}

class RFIDGraftingMainViewVaccine extends Component {
    render() {
        const {farm, medicines, t} = this.props;
        return (
            <EventMainView
                breadcrumbs={[{name: t("treatment"), link: `/${farm}/mobile/events/grafting`}, {name: t("grafting")}]}
                additionalData={
                    <div className="medicine-list">
                        {
                            medicines.slice(0, 4).map((med, key) =>
                                <MobileAppLink key={key} name={med.WData.Name}
                                               href={`/${farm}/mobile/scanner`}
                                               state={{
                                                   medicine: med,
                                                   breadcrumbs: [{
                                                       name: t("treatment"),
                                                       link: `/${farm}/mobile/events/grafting`
                                                   }, {name: t("grafting")}],
                                                   eventType: "grafting",
                                                   status: `${medicineTypes.VACCINE}/add`,
                                                   advancedAnimalScanner: true
                                               }}>
                                    <div className="color" style={{background: med.WData.Color || "#FFFFFF"}}/>
                                </MobileAppLink>
                            )
                        }
                        {
                            medicines.length > 4 &&
                            <MobileAppLink name={t("other")} href={`/${farm}/mobile/medicineChooser`} state={{
                                breadcrumbs: [{
                                    name: t("treatment"),
                                    link: `/${farm}/mobile/events/grafting`
                                }, {name: t("grafting")}],
                                eventType: "grafting",
                                status: `${medicineTypes.VACCINE}/add`,
                                medicineType: medicineTypes.VACCINE
                            }}/>
                        }
                    </div>
                }>
                <MobileAppLink name={t("dosatron")} href={`/${farm}/mobile/events/grafting/dosatron`}/>
                <MobileAppLink name={t("grafting")} href={`/${farm}/mobile/events/grafting/vaccine`} selected/>
                <MobileAppLink name={t("medicalFeed")} href={`/${farm}/mobile/events/grafting/forage`}/>
            </EventMainView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RFIDGraftingMainViewVaccine);