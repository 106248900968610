import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./error-component.scss";

class ErrorComponent extends Component {

    render() {
        const {error, show, onCloseClick, capitalizeFirstLetter, type} = this.props;
        return (
            <div
                className={`submit-error${capitalizeFirstLetter ? " capitalize" : ""}${error && show ? " in" : ""} ${type}`}>
                <div className="text-container">{error}</div>
                <i className="far fa-times" onClick={onCloseClick}/>
            </div>
        );
    }
}

ErrorComponent.propTypes = {
    show: PropTypes.bool,
    error: PropTypes.string.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    capitalizeFirstLetter: PropTypes.bool,
    type: PropTypes.oneOf(["success", "warning", "error", "info"])
};

ErrorComponent.defaultProps = {
    capitalizeFirstLetter: true,
    type: "error"
};

export default ErrorComponent;