import React, {Component} from 'react';
import MobileAppLink from "../../../../components/basics/mobile-app-link/MobileAppLink";
import EventMainView from "../EventMainView";
import animalsDB from "../../../../database/animalsDB";
import {AnimalTypes} from "../../../../constans/animalTypes";
import InfoBox from "../../../../components/basics/info-box/InfoBox";
import "./_insemination-end.scss"
import {connect} from "react-redux";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RfidInseminationEnd extends Component {

    state = {
        boars: animalsDB.getAllAnimals(this.props.farm, AnimalTypes.BOAR)
    };

    render() {
        const {farm, t} = this.props;
        const {boars} = this.state;
        let boarsToShow = boars.slice(0, 4);
        console.log(boars);
        return (
            <EventMainView breadcrumbs={[{
                name: t("insemination"),
                link: `/${farm}/mobile/events/insemination`
            }, {name: t("inseminate")}]} additionalData={
                <div>
                    <div className="text-center">{t("rfidApp.chooseBoar")}:</div>
                    <div className="boar-list">
                        {
                            boars.length === 0 &&
                            <InfoBox boxColor={"warning"}>{t("rfidApp.noBoars")}</InfoBox>
                        }
                        {
                            boarsToShow.map((boar, index) =>
                                <MobileAppLink key={index} name={boar.AnmNo1} href={`/${farm}/mobile/scanner`} state={{
                                    breadcrumbs: [
                                        {
                                            name: t("insemination"),
                                            link: `/${farm}/mobile/events/insemination`
                                        },
                                        {
                                            name: t("inseminate")
                                        }
                                    ],
                                    eventType: "insemination",
                                    status: "end/info",
                                    animalType: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW],
                                    boar
                                }}/>
                            )
                        }
                        {
                            boars.length > 4 &&
                            <MobileAppLink name={t("other")} href={`/${farm}/mobile/animalChooser`} state={{
                                breadcrumbs: [
                                    {
                                        name: t("insemination"),
                                        link: `/${farm}/mobile/events/insemination`
                                    },
                                    {
                                        name: t("inseminate")
                                    }
                                ],
                                eventType: "insemination",
                                status: "end/info",
                                animalType: AnimalTypes.BOAR,
                                singleSelect: true,
                                insertAs: "boar",
                                insertAsObject: true,
                                animalScannerNextPath: `/${farm}/mobile/scanner`,
                                additionalState: {
                                    animalScannerNextPath: null,
                                    singleSelect: false,
                                    insertAs: null,
                                    insertAsObject: false,
                                    animalType: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW]
                                }
                            }}/>
                        }
                    </div>
                </div>
            }>
                <MobileAppLink name={t("check")} href={`/${farm}/mobile/scanner`} state={{
                    breadcrumbs: [{
                        name: t("insemination"),
                        link: `/${farm}/mobile/events/insemination`
                    }, {name: t("check")}],
                    eventType: "insemination",
                    status: "status",
                    animalType: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW]
                }}/>
                <MobileAppLink name={t("inseminate")} href={`/${farm}/mobile/events/insemination/end`} selected/>
            </EventMainView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RfidInseminationEnd);