import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import PropTypes from "prop-types";
import TableGrid from "../../../../components/basics/table-grid/TableGrid";
import {findIndex, isEqual} from "lodash";
import {withTagReader} from "../../tag-reader-hoc/withTagReader";
import ErrorComponent from "../../error/ErrorComponent";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RfidHistoryEvent extends Component {

    state = {
        error: null,
        show: false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {tagReader: {tag, animal}} = this.props;
        if (tag !== prevProps.tagReader.tag && tag) {
            if (!isEqual(animal, prevProps.tagReader.animal) && animal) {
                let indexes = this.getAllIndexes(this.props.data, animal, tag);
                if (indexes.length > 0) {
                    const data = this.props.data.slice(0);
                    for (let index of indexes) {
                        for (let i = 0; i < data.length; i++) {
                            if (i === index) {
                                data[i] = {
                                    ...data[i],
                                    scanned: true,
                                    highlighted: true
                                }
                            } else {
                                data[i] = {
                                    ...data[i],
                                    highlighted: indexes.includes(i)
                                }
                            }
                        }
                    }
                    this.props.onDataChange(data);
                    this.scrollToRow(indexes[0]);
                    this.props.tagReader.clearTag();
                    this.setState({
                        show: false
                    })
                } else {
                    navigator.vibrate([500, 200, 500, 200, 500]);
                    this.setState({
                        show: true,
                        error: 1,
                        errorTag: tag
                    })
                    this.props.tagReader.clearTag();
                }
            } else {
                this.setState({
                    show: true,
                    error: 2,
                    errorTag: tag
                })
                this.props.tagReader.clearTag();
            }
        }
    }

    getIndex(data, animal, tag, start = 0) {
        const {predicate} = this.props;
        return findIndex(data, o => predicate(o, animal, tag), start);
    }

    getAllIndexes(data, animal, tag, start = 0) {
        let index = this.getIndex(data, animal, tag, start);
        if (index >= 0) {
            let indexes = [index];
            let nextIndex = this.getAllIndexes(data, animal, tag, index + 1);
            indexes.push(...nextIndex);
            return indexes;
        }
        return [];
    }

    scrollToRow(index) {
        let container = document.getElementsByClassName("body-container")[0];
        let row = document.getElementsByClassName("table-row-box")[0];
        let height = row.clientHeight;
        container.scroll({
            top: height * index,
            behavior: "smooth"
        })
    }

    rowClassName = props => {
        if (props.highlighted) return "highlight";
        return null;
    }

    onCloseClick = () => {
        this.setState({
            show: false
        })
    }

    getError() {
        const {t} = this.props;
        const {error, errorTag} = this.state;
        switch (error) {
            case 1:
                return t("rfidApp.notFoundOnList", {errorTag});
            case 2:
                return t("rfidApp.animalWithNumberNotFound", {errorTag});
            default:
                return "";
        }
    }

    render() {
        const {show, error} = this.state;
        const {name, data, headers} = this.props
        return (
            <>
                <h4>{name}</h4>
                <TableGrid data={data} headers={headers} shouldIndex rowClassName={this.rowClassName}/>
                <ErrorComponent onCloseClick={this.onCloseClick} error={this.getError()} show={show}
                                type={error === 1 ? "error" : "warning"}/>
            </>
        );
    }
}

RfidHistoryEvent.propTypes = {
    data: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    headers: PropTypes.array.isRequired,
    onDataChange: PropTypes.func.isRequired,
    predicate: PropTypes.func,
    findDead: PropTypes.bool,
}

RfidHistoryEvent.defaultProps = {
    predicate: (o, animal) => o.AnmID === animal.AnmID,
    findDead: false
}

export default compose(
    withTranslation(),
    withTagReader(props => ({
        getDeadAnimals: props.findDead
    })),
    connect(mapStateToProps)
)(RfidHistoryEvent);