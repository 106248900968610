import React, {Component} from 'react';
import {connect} from 'react-redux';
import EventMainView from "../EventMainView";
import MobileAppLink from "../../../../components/basics/mobile-app-link/MobileAppLink";
import {AnimalTypes} from "../../../../constans/animalTypes";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RfidParturition extends Component {
    render() {
        console.log(this.props);
        const {farm, t} = this.props;
        return (
            <EventMainView breadcrumbs={[{name: t("parturition")}]}>
                <MobileAppLink name={t("rfidApp.start")} href={`/${farm}/mobile/scanner`} state={{
                    eventType: "parturition",
                    status: "start",
                    breadcrumbs: [{name: t("parturition"), link: `/${farm}/mobile/events/parturition`}, {name: t("rfidApp.start")}],
                    animalType: [AnimalTypes.SOW]
                }}/>
                <MobileAppLink name={t("rfidApp.status")} href={`/${farm}/mobile/scanner`} state={{
                    eventType: "parturition",
                    status: "status",
                    breadcrumbs: [{name: t("parturition"), link: `/${farm}/mobile/events/parturition`}, {name: t("rfidApp.status")}],
                    animalType: [AnimalTypes.SOW],
                    singleSelect: true
                }}/>
                <MobileAppLink name={t("rfidApp.end")} href={`/${farm}/mobile/scanner`} state={{
                    eventType: "parturition",
                    status: "end",
                    breadcrumbs: [{name: t("parturition"), link: `/${farm}/mobile/events/parturition`}, {name: t("rfidApp.end")}],
                    animalType: [AnimalTypes.SOW]
                }}/>
            </EventMainView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RfidParturition);