import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {Col, Row} from "react-bootstrap";
import {Field, reduxForm} from "redux-form";
import BigDataInput from "../../data-input/BigDataInput";
import EventStatusView from "../EventStatusView";
import {submit} from "./RFIDSeparationToPigletHouseAmountSingleSubmit";
import {getEventsForAnimal} from "../../../../actions/eventsActions";
import LoadingComponent from "../../../../components/loading/LoadingComponent";
import {getPigBalance} from "../../../../utils/EventUtils";

function mapStateToProps(state, props) {
    if (props.location.state) {
        let animal = props.location.state.animals[0];
        return {
            farm: state.location.farm,
            events: state.events.eventsForAnimals.get(animal.AnmID),
            loading: state.events.eventsForAnimalsLoading.get(animal.AnmID)
        };
    }
    return {
        farm: state.location.farm,
        events: [],
        loading: false
    }
}

const formName = "separationToPigletHouseSingle";

class RfidSeparationToPigletHouseAmountSingle extends Component {

    constructor(props) {
        super(props);

        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            const {animals} = state;
            this.props.dispatch(getEventsForAnimal(animals[0]));
            this.props.initialize({
                animal: animals[0],
                amount: 0
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.loading !== prevProps.loading && !this.props.loading) {
            let balance = getPigBalance(this.props.events);
            this.props.change("amount", balance);
        }
    }

    render() {
        const {handleSubmit, submitFailed, error, submitting, location: {state}, farm, submitSucceeded, t, loading} = this.props;
        if (!state) return null;
        const separationBreadcrumb = {
            name: t("rfidApp.separation"),
            link: `/${farm}/mobile/events/separationToPigletHouse`
        };
        const animalBreadcrumb = {
            name: "Pojedyńczo",
            link: {
                pathname: `/${farm}/mobile/scanner`,
                state: {
                    ...state,
                    breadcrumbs: [separationBreadcrumb, {name: "Pojedyńczo"}],
                    locations: null,
                    locationNextPath: `/${farm}/mobile/groupScanner`
                }
            }
        };
        return (
            <EventStatusView handleSubmit={handleSubmit}
                             breadcrumbs={[
                                 separationBreadcrumb,
                                 animalBreadcrumb,
                                 {
                                     name: t("group"),
                                     link: {
                                         pathname: `/${farm}/mobile/groupScanner`, state: {
                                             ...state,
                                             breadcrumbs: [separationBreadcrumb, animalBreadcrumb]
                                         }
                                     }
                                 },
                                 {
                                     name: t("rfidApp.toPlace"),
                                     link: {pathname: `/${farm}/mobile/events/separationToPigletHouse/location`, state}
                                 },
                                 {name: t("pigletsAmount")}
                             ]}
                             submitFailed={submitFailed} error={error} formName={formName} submitting={submitting}
                             status={state.status} eventType={state.eventType}
                             timesPath={`/${farm}/mobile/events/separationToPigletHouse`}
                             submitSucceeded={submitSucceeded}>
                <LoadingComponent isLoading={loading}/>
                <Row className="justify-content-center h-100">
                    <Col xs={8}>
                        <Field
                            name="amount"
                            component={BigDataInput}
                            label={t("planTypes.amountOfSeparatedPiglets") + ":"}
                        />
                    </Col>
                </Row>
            </EventStatusView>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    reduxForm({
        form: formName,
        onSubmit: submit
    })
)(RfidSeparationToPigletHouseAmountSingle);