import React, {Component} from 'react';
import {connect} from 'react-redux';
import RFIDHeader from "../../rfid-header/RFIDHeader";
import InfoBox from "../../../../components/basics/info-box/InfoBox";
import Carousel from "../../carousel/Carousel";
import CarouselItem from "../../carousel/CarouselItem";
import "../../../../components/basics/mobile-app-link/_mobile-app-link.scss"
import "./_treatment.scss"
import MobileAppLink from "../../../../components/basics/mobile-app-link/MobileAppLink";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        graftingReasons: state.dictionary.graftingReason.WData[state.language.lang.lang]
    };
}

class RfidTreatmentMedicine extends Component {

    state = {
        page: 0,
    };

    componentDidMount() {
        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        }
    }

    onPageChange = page => {
        this.setState({
            page
        })
    };

    splitReasons(medicines) {
        let slicedMedicines = [];
        for (let i = 0; i < medicines.length; i += 3) {
            slicedMedicines.push(medicines.slice(i, i + 3));
        }
        return slicedMedicines;
    }

    render() {
        const {graftingReasons, farm, location: {state}, t} = this.props;
        if (!state) return null;
        const {page} = this.state;
        return (
            <div className="event treatment">
                <RFIDHeader breadcrumbs={[
                    {
                        name: t("scheduleOfTreatment"),
                        link: {
                            pathname: `/${farm}/mobile/scanner`,
                            state: {
                                breadcrumbs: [
                                    {
                                        name: t("scheduleOfTreatment")
                                    }
                                ],
                                eventType: "treatment",
                                status: "medicine"
                            }
                        }
                    },
                    {
                        name: t("medicine"),
                        link: {
                            pathname: `/${farm}/mobile/events/treatment/medicine`,
                            state: state
                        }
                    },
                    {
                        name: t("reason")
                    }
                ]} home/>
                {
                    graftingReasons.length === 0 &&
                    <InfoBox boxColor={"warning"}>{t("rfidApp.medicinesNotFound")}</InfoBox>
                }
                {
                    graftingReasons.length > 0 &&
                    <Carousel page={page} onPageChange={this.onPageChange} title={t("rfidApp.chooseReason") + ":"}>
                        {
                            this.splitReasons(graftingReasons).map((reasons, index) =>
                                <CarouselItem key={index}>
                                    {
                                        reasons.map((reason, index2) =>
                                            <MobileAppLink name={reason.Value}
                                                           href={`/${farm}/mobile/events/treatment/add`}
                                                           key={index2}
                                                           state={{
                                                               ...state,
                                                               reason: reason.ID
                                                           }}/>
                                        )
                                    }
                                </CarouselItem>
                            )
                        }
                    </Carousel>
                }
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RfidTreatmentMedicine);