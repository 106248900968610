import React, {Component} from 'react';
import {connect} from 'react-redux';
import "../_event-status-view.scss"
import {Col, Row} from "react-bootstrap";
import {Field, reduxForm} from "redux-form";
import DataInput from "../../data-input/DataInput";
import EventStatusView from "../EventStatusView";
import {submit} from "./RFIDFallPigletsEndSubmit"
import {getEventsForAnimals} from "../../../../actions/eventsActions";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        fallReasons: state.dictionary.fallReasons.WData[state.language.lang.lang],
        user: state.user.user,
    };
}

const formName = "fallPiglets";

class RfidFallPigletsEnd extends Component {

    constructor(props) {
        super(props);

        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            const {animals} = state;
            this.props.dispatch(getEventsForAnimals(animals.map(item => item.AnmID)));
            this.props.initialize({
                animals,
                amount: 1
            })
        }
    }

    getReason() {
        const {match: {params: {reasonID}}, fallReasons} = this.props;
        return fallReasons.find(item => item.ID === reasonID);
    }

    render() {
        const {location: {state}, handleSubmit, submitting, submitFailed, error, farm, submitSucceeded, t} = this.props;
        let reason = this.getReason();
        if (!reason || !state) return null;
        const {animals} = state;
        return (
            <EventStatusView breadcrumbs={[{
                name: t("fallPiglets"),
                link: `/${farm}/mobile/events/fallPiglets`
            }, {name: reason.Value}]} handleSubmit={handleSubmit}
                             submitting={submitting} submitFailed={submitFailed} error={error} formName={formName}
                             status={state.status} eventType={state.eventType} submitSucceeded={submitSucceeded}>
                <Row className="justify-content-center h-100">
                    <Col xs={8} className="d-flex flex-column justify-content-center align-items-center w-100">
                        <div>{t("rfidApp.fallenPiglets")}:</div>
                        <Field
                            name="amount"
                            component={DataInput}
                            label={t("rfidApp.amount")}
                        />
                    </Col>
                </Row>
                {
                    animals.length === 1 &&
                    <div className="animal-info">
                        {t("rfidApp.animalNumber")}: <span>{animals[0].AnmNo1}</span>
                    </div>
                }
            </EventStatusView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation(),
    reduxForm({
        form: formName,
        onSubmit: submit
    })
)(RfidFallPigletsEnd);