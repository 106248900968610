import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import RFIDHeader from "../rfid-header/RFIDHeader";
import "./_history.scss";
import {EventTypes} from "../../../constans/eventTypes";
import RFIDHistoryActiveNipples from "./events/active-nipples/RFIDHistoryActiveNipples";
import RFIDHistoryFall from "./events/fall/RFIDHistoryFall";
import RFIDHistoryFallPiglets from "./events/fall-piglets/RFIDHistoryFallPiglets";
import RFIDHistoryGrafting from "./events/grafting/RFIDHistoryGrafting";
import RFIDHistoryGraftingPiglets from "./events/grafting-piglets/RFIDHistoryGraftingPiglets";
import RFIDHistoryHeat from "./events/heat/RFIDHistoryHeat";
import RFIDHistoryInsemination from "./events/insemination/RFIDHistoryInsemination";
import RFIDHistoryMommyAdd from "./events/mommy/add/RFIDHistoryMommyAdd";
import RFIDHistoryMommyGet from "./events/mommy/get/RFIDHistoryMommyGet";
import RFIDHistoryNoPregnancy from "./events/no-pregnancy/RFIDHistoryNoPregnancy";
import RFIDHistoryParturition from "./events/parturition/RFIDHistoryParturition";
import RFIDHistorySelection from "./events/selection/RFIDHistorySelection";
import RFIDHistorySeparationToPiglethouse from "./events/separation-to-piglethouse/RFIDHistorySeparationToPiglethouse";
import RFIDHistorySell from "./events/sell/RFIDHistorySell";
import RFIDHistoryTransfer from "./events/transfer/RFIDHistoryTransfer";
import RFIDHistoryUSG from "./events/usg/RFIDHistoryUSG";
import RFIDHistoryWeighting from "./events/weighting/RFIDHistoryWeighting";
import RFIDHistoryCastration from "./events/castration/RFIDHistoryCastration";
import RFIDHistoryToInsemination from "./events/to-insemination/RFIDHistoryToInsemination";
import RFIDHistoryToRepeat from "./events/to-repeat/RFIDHistoryToRepeat";
import RFIDHistoryInsertion from "./events/insertion/RFIDHistoryInsertion";
import RFIDHistoryTreatment from "./events/treatment/RFIDHistoryTreatment";
import RFIDHistoryReclassify from "./events/reclassify/RFIDHistoryReclassify";

function mapStateToProps(state) {
    return {
        history: state.eventCounter.history
    };
}

class RfidHistory extends Component {

    getClass() {
        const {history} = this.props;
        let item = history[0];
        switch (item.type) {
            case EventTypes.ACTIVE_NIPPLES: return RFIDHistoryActiveNipples;
            case EventTypes.FALL: return RFIDHistoryFall;
            case EventTypes.FALL_PIGLETS: return RFIDHistoryFallPiglets;
            case EventTypes.GRAFTING: return RFIDHistoryGrafting;
            case EventTypes.PIGLETS_TREATMENT: return RFIDHistoryGraftingPiglets;
            case EventTypes.HEAT: return RFIDHistoryHeat;
            case EventTypes.INSEMINATION: return RFIDHistoryInsemination;
            case EventTypes.MOMMY: return RFIDHistoryMommyAdd;
            case EventTypes.SEPARATION_TO_MOMMY: return RFIDHistoryMommyGet;
            case EventTypes.NO_PREGNANCY: return RFIDHistoryNoPregnancy;
            case EventTypes.PARTURITION: return RFIDHistoryParturition;
            case EventTypes.SELECTION: return RFIDHistorySelection;
            case EventTypes.SELL: return RFIDHistorySell;
            case EventTypes.SEPARATION: return RFIDHistorySeparationToPiglethouse;
            case EventTypes.TRANSFER: return RFIDHistoryTransfer;
            case EventTypes.USG: return RFIDHistoryUSG;
            case EventTypes.WEIGHTING: return RFIDHistoryWeighting;
            case EventTypes.CASTRATION: return RFIDHistoryCastration;
            case EventTypes.TO_INSEMINATION: return RFIDHistoryToInsemination;
            case EventTypes.TO_REPEAT: return RFIDHistoryToRepeat;
            case "INSERTION": return RFIDHistoryInsertion;
            case EventTypes.TREATMENT: return RFIDHistoryTreatment;
            case EventTypes.RECLASSIFY: return RFIDHistoryReclassify;
            default: return "div";
        }
    }

    render() {
        const {history, t} = this.props;
        return (
            <div>
                <RFIDHeader breadcrumbs={[{name: t("history")}]} back/>
                <div className="history-container">
                    {
                        history.length === 0 &&
                        <h4>{t("noEventsFound")}</h4>
                    }
                    {
                        history.length > 0 && React.createElement(this.getClass())
                    }
                </div>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(RfidHistory);