import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import PropTypes from "prop-types"
import RFIDHistoryEvent from "../RFIDHistoryEvent";
import animalsDB from "../../../../../database/animalsDB";
import moment from "moment";
import {EventTypes} from "../../../../../constans/eventTypes";
import RFIDHistoryScannedComponent from "../RFIDHistoryScannedComponent";

function mapStateToProps(state) {
    return {
        history: state.eventCounter.history,
        medicines: state.dictionary.medicine
    };
}

class RFIDHistoryInsemination extends Component {

    state = {
        data: this.getData()
    }

    getData() {
        const {history} = this.props;
        let data = [];
        for (let event of history) {
            console.log("Iwent:",event);
            if (event.type !== EventTypes.TREATMENT) break;
            let medicines = [];
            let dates = [];
            for (let event_data of event.data) {
                for (let treatment of event_data.treatment) {
                    medicines = [...medicines, ...treatment.medicines];
                    dates = [...dates, ...treatment.dates];
                }

                dates.sort((a, b) => a - b);
                for (let animal of event_data.animals) {
                    data.push({
                        AnmID: animal.AnmID,
                        medicines,
                        dates
                    })
                }
            }
        }
        return data.sort((a, b) => b.EvTime - a.EvTime);
    }

    animalValueFormatter = value => {
        let animal = animalsDB.getAnimalById(value);
        if (animal) return animal.AnmNo1;
        return null;
    }

    dateFormatter = value => {
        let texts = value.map(timestamp => moment(timestamp).format("DD.MM.YYYY"));
        return texts.join(", ");
    }

    medicinesValueFormatter = value => {
        const {medicines} = this.props;
        let texts = value.map(medicineID => {
            let medicine = medicines.find(item => item.WordID === medicineID);
            return medicine ? medicine.WData.Name : "-";
        }).filter(item => item);
        return texts.join(", ");
    }

    onDataChange = data => {
        this.setState({
            data
        })
    }

    render() {
        const {t} = this.props;
        const {data} = this.state;
        const headers = [
            {
                name: "",
                field: "scanned",
                headerClassName: "scanned",
                itemClassName: "scanned",
                component: <RFIDHistoryScannedComponent/>
            },
            {
                name: t("animal"),
                field: "AnmID",
                valueFormatter: this.animalValueFormatter
            },
            {
                name: t("date"),
                field: "dates",
                valueFormatter: this.dateFormatter
            },
            {
                name: t("time"),
                field: "medicines",
                valueFormatter: this.medicinesValueFormatter
            }
        ]
        return (
            <RFIDHistoryEvent data={data} name={t("scheduleOfTreatment")} headers={headers}
                              onDataChange={this.onDataChange}/>
        );
    }
}

RFIDHistoryInsemination.propTypes = {
    item: PropTypes.object,
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(RFIDHistoryInsemination);