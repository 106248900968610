import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./_rfid-header.scss"
import {Link, withRouter} from "react-router-dom"
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RfidHeader extends Component {

    headerRef = React.createRef();
    breadcrumbRefs = [];

    state = {
        shouldTrim: []
    };

    constructor(props) {
        super(props);

        const {breadcrumbs} = this.props;
        let shouldTrim = [];
        for (let i = 0; i < breadcrumbs.length; i++) {
            this.breadcrumbRefs[i] = React.createRef();
            shouldTrim[i] = false;
        }

        this.state = {
            shouldTrim
        }
    }

    componentDidMount() {
        let headerWidth = this.headerRef.current.clientWidth;
        let breadcrumbsWidth = this.breadcrumbRefs.reduce((a, b) => a + b.current.scrollWidth, 0);
        if (breadcrumbsWidth > headerWidth) {
            let shouldTrim = this.state.shouldTrim.slice(0);
            let diff = breadcrumbsWidth - headerWidth;
            for (let i = 0; i < this.breadcrumbRefs.length; i++) {
                let breadcrumb = this.breadcrumbRefs[i];
                let width = breadcrumb.current.scrollWidth;
                let shouldHaveWidth = width - diff;
                shouldTrim[i] = true;
                if (shouldHaveWidth > 100) break;
            }
            this.setState({
                shouldTrim
            });
        }
    }

    onBackClick = () => {
        const {history, backPathname} = this.props;
        if (backPathname) {
            history.push(backPathname);
        } else {
            history.goBack();
        }
    };

    renderBreadcrumb(breadcrumb, index) {
        const {shouldTrim} = this.state;
        return (
            <span key={index} ref={this.breadcrumbRefs[index]} className={shouldTrim[index] ? "trim" : ""}>
                {index !== 0 && <i className="far fa-chevron-right"/>}
                {breadcrumb.name}
            </span>
        )
    }

    render() {
        const {breadcrumbs, back, home, farm} = this.props;
        return (
            <>
                <h5 className="rfid-header-title" ref={this.headerRef}>
                    {
                        breadcrumbs.map((breadcrumb, index) =>
                            index !== breadcrumbs.length - 1 ?
                                breadcrumb.onClick ?
                                    <span
                                        onClick={breadcrumb.onClick}>{this.renderBreadcrumb(breadcrumb, index)}</span> :
                                    <Link to={breadcrumb.link}>
                                        {this.renderBreadcrumb(breadcrumb, index)}
                                    </Link> : this.renderBreadcrumb(breadcrumb, index)
                        )
                    }
                </h5>
                {
                    back &&
                    <a className="rfid-header-icon" onClick={this.onBackClick}>
                        <i className="far fa-arrow-circle-left"/>
                    </a>
                }
                {
                    home &&
                    <Link className="rfid-header-icon home" to={`/${farm}/mobile`}>
                        <i className="far fa-home"/>
                    </Link>
                }
            </>
        );
    }
}

RfidHeader.propTypes = {
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        link: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    })).isRequired,
    back: PropTypes.bool,
    home: PropTypes.bool
};

RfidHeader = withRouter(RfidHeader);

export default connect(
    mapStateToProps,
)(RfidHeader);