import React, {Component} from 'react';
import {connect} from 'react-redux';
import EventStatusView from "../../EventStatusView";
import {Field, reduxForm} from "redux-form";
import DataInput from "../../../data-input/DataInput";
import {Col, Row} from "react-bootstrap";
import {submit} from "./RFIDMommyGetSubmit"
import {getEventsForAnimals} from "../../../../../actions/eventsActions";
import _ from "lodash";
import LoadingComponent from "../../../../../components/loading/LoadingComponent";
import {EventTypes} from "../../../../../constans/eventTypes";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {getUnit} from "../../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../../constans/unitTypes";

const formName = "mommyGet";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        events: state.events.eventsForAnimals,
        loading: state.events.eventsForAnimalsLoading,
    };
}

class RfidMommyGet extends Component {

    state = {
        loading: this.props.location.state && this.props.location.state.animals && this.props.location.state.animals.length === 1 || false,
        loadingState: this.props.loading
    };

    constructor(props) {
        super(props);
        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            const {animals} = state;
            if (animals.length === 1) {
                this.props.dispatch(getEventsForAnimals(animals.map(animal => animal.AnmID), {FarmID: farm}));
            }
            this.props.initialize({
                weight: 0,
                amount: 0,
                animals
            })
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {location: {state}, loading} = nextProps;
        if (state && state.animals.length === 1 && !_.isEqual(prevState.loadingState, nextProps.loadingState)) {
            const {animals} = state;
            // jeżeli zachodzi ta zmiana to znaczy ze wybrano tylko jedno zwierze
            let animal = animals[0];
            let doneLoading = animals.length;
            let isLoading = loading.get(animal.AnmID);
            if (isLoading !== undefined && !isLoading) doneLoading--;
            return {
                loading: doneLoading !== 0,
                loadingState: loading
            }
        }
        return {
            loadingState: loading
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.loading && !this.state.loading) {
            const {location: {state}} = this.props;
            const {events} = this.props;
            let animal = state.animals[0];
            let eventz = (events.get(animal.AnmID) || []).sort((a, b) => b.EvTime - a.EvTime);
            let activeNipples = eventz.find(item => item.EvCode === EventTypes.ACTIVE_NIPPLES);
            if (activeNipples) {
                let parturition = eventz.find(item => item.EvCode === EventTypes.PARTURITION);
                if (parturition && +parturition.EvData.HealthyCnt > activeNipples.EvData.Nipples) {
                    this.props.change("amount", +parturition.EvData.HealthyCnt - activeNipples.EvData.Nipples);
                }
            }
        }
    }

    render() {
        const {handleSubmit, submitting, location: {state}, error, submitFailed, farm, submitSucceeded, t} = this.props;
        if (!state) return null;
        const {animals} = state;
        const {loading} = this.state;
        return (
            <EventStatusView
                breadcrumbs={[{name: t("mommy"), link: `/${farm}/mobile/events/mommy`}, {name: t("rfidApp.getFromMommy")}]}
                handleSubmit={handleSubmit}
                submitting={submitting} submitFailed={submitFailed} error={error} formName={formName}
                status={state.status} eventType={state.eventType} counterName="mommyGet"
                submitSucceeded={submitSucceeded}>
                <LoadingComponent isLoading={loading}/>
                {
                    animals.length === 1 &&
                    <div className="animal-info">
                        {t("rfidApp.animalNumber")}: <span>{animals[0].AnmNo1}</span>
                    </div>
                }
                <Row className="justify-content-center h-100">
                    <Col xs={8} className="d-flex flex-column justify-content-center">
                        <div className="text-center">{t("rfidApp.pigletsTakenFromChamber")}:</div>
                        <Field
                            name="weight"
                            component={DataInput}
                            label={t("weightWithUnit", {unit: getUnit("weight", UnitTypes.MEDIUM)})}
                        />
                        <Field
                            name="amount"
                            component={DataInput}
                            label={t("rfidApp.amount")}
                        />
                    </Col>
                </Row>
            </EventStatusView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation(),
    reduxForm({
        form: formName,
        onSubmit: submit
    })
)(RfidMommyGet);