import React, {Component} from 'react';
import PropTypes from 'prop-types';
import RfidFeedingModal from "./RFIDFeedingModal";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import Device from "../../../../beans/Device";
import "../../choosers/_animal-chooser.scss"
import {DevType} from "../../../../constans/devices";
import Animal from "../../../../beans/Animal";
import {setAnimal} from "../../../../IOT/device-functions/DispenserNRFFunctions";
import {CurveDayShowingType} from "../../../../constans/feedingTypes";
import {getCurveDayShowingType, prepareFeeding} from "../../../../utils/FeedingUtils";
import {get} from "lodash";
import {Col, Row} from "react-bootstrap";
import moment from "moment";
import CurveDay from "./CurveDay";
import {isUsingFakeData} from "../../../../utils/SettingsUtils";

class RFIDChangeCurveDay extends Component {

    constructor(props) {
        super(props);
        const {curve, stage} = this.props;
        let curveStart = moment(this.props.curveStart);
        const isEventReported = !!stage;
        const curveSize = get(curve, "SetData.Days.length", 0);
        const repetitionDay = get(curve, "SetData.InseminationJumpTp", 0);
        const showingType = getCurveDayShowingType(curve);
        let value;
        switch (showingType) {
            case CurveDayShowingType.BEFORE: {
                value = curveStart.clone().add(curveSize, "days");
                break;
            }
            case CurveDayShowingType.AFTER: {
                value = curveStart;
                break;
            }
            case CurveDayShowingType.BOTH: {
                value = curveStart.clone().subtract((isEventReported ? 0 : Math.max(repetitionDay - 1, 0)), "day");
                break;
            }
            case CurveDayShowingType.NORMAL:
            default:
                value = Math.min(curveSize, Math.max(1, moment().startOf("day").diff(curveStart.clone().startOf("day"), "days") + 1));
                break;
        }
        this.state = {
            value
        }
    }


    onChangeDay = value => {
        this.setState({
            value
        })
    }

    sendData(gatewayId, data) {
        return new Promise((resolve, reject) => {
            setAnimal(gatewayId, [data], {onSuccess: resolve, onFailure: reject, showNotification: false})
        })
    }

    onSubmitClick = () => {
        const {value} = this.state;
        const {devices, workType, animal, locationID, curve} = this.props;
        let device = devices[0];
        const _data = prepareFeeding({repetitionDate: value, curve: curve, curveDay: value});
        const data = {
            PlcmntID: locationID,
            AnmID: animal.AnmID,
            AnmNo1: animal.AnmNo1,
            RFID: animal.RFID,
            feeding: {
                startTime: +_data.startTime,
                number: device.DevType === DevType.DISPENSER ? (
                    get(device.PlcmntID.find(item => item.PlcmntID === locationID), "Adr", -1) + 1 || undefined
                ) : undefined
            }
        }
        if (isUsingFakeData()) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                    this.props.onSuccess({
                        curveDay: moment().startOf("day").diff(device.DevType === DevType.DISPENSER ? data[0].curveStart : workType === "R" ? data.animals[0].curveCfg.startTime : data.startTime, "days") + 1,
                        startTime: device.DevType === DevType.DISPENSER ? data[0].curveStart : workType === "R" ? data.animals[0].curveCfg.startTime : data.startTime
                    });
                }, 1500)
            })
        }
        return this.sendData(device.GatewayID, data);
    }

    render() {
        const {onCloseClick, onSuccess, curve, t} = this.props;
        const {value} = this.state;
        return (
            <RfidFeedingModal breadcrumbs={[{name: t("rfidApp.changeCurveDay")}]} onCloseClick={onCloseClick}
                              onSubmit={this.onSubmitClick} onSuccess={onSuccess}>
                <Row className="justify-content-center h-100">
                    <Col xs={8} className="d-flex flex-column justify-content-center">
                        <CurveDay curve={curve} onChange={this.onChangeDay} value={value}/>
                    </Col>
                </Row>
            </RfidFeedingModal>
        );
    }
}

RFIDChangeCurveDay.propTypes = {
    devices: PropTypes.arrayOf(PropTypes.instanceOf(Device)),
    onCloseClick: PropTypes.func.isRequired,
    workType: PropTypes.string,
    animal: PropTypes.instanceOf(Animal),
    locationID: PropTypes.string,
    onSuccess: PropTypes.func,
    curve: PropTypes.object,
    stage: PropTypes.number,
    curveStart: PropTypes.number,
};

export default compose(
    withTranslation()
)(RFIDChangeCurveDay);
