import {SubmissionError} from "redux-form";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import {createActiveNipples} from "../../../../api/events/ActiveNipples";

export function submit(values, dispatch, props) {
    let errors = validate(values, props);
    if (errors._error) {
        throw new SubmissionError(errors);
    }
    const {animals, amount} = values;
    const {farm, history, t} = props;
    let data = animals.map(animal => ({
        AnmID: animal.AnmID,
        EvTime: new Date().getTime(),
        GrID: getGrIDByAnimal(animal, new Date().getTime()),
        Nipples: amount
    }))
    return createActiveNipples(data).then(res => {
        setTimeout(() => {
            history.push({
                pathname: `/${farm}/mobile/scanner`,
                state: {
                    breadcrumbs: [{name: t("activeNipples")}],
                    eventType: "activeNipples",
                    status: "add"
                }
            });
        }, 1500)
        dispatch({type: "ADD_ACTIVE_NIPPLES", payload: data});
    }).catch(e => {
        throw new SubmissionError({_error: t("rfidApp.activeNipplesGeneralError")});
    })
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;

    if (values.amount === 0) {
        errors._error = t("rfidApp.amountOfActiveNipplesError", {error: t("errors.lessVal", {count: 1})});
    }

    return errors;
}