import React, {Component} from 'react';
import {connect} from 'react-redux';
import EventStatusView from "../../EventStatusView";
import {Col, Row} from "react-bootstrap";
import {Field, reduxForm} from "redux-form";
import DataInput from "../../../data-input/DataInput";
import {submit} from "./RFIDMommyAddSubmit"
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
    };
}

const formName = "mommyAdd";

class RfidMommyAdd extends Component {

    constructor(props) {
        super(props);

        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            const {animals} = state;
            this.props.initialize({
                amount: 0,
                animals
            })
        }
    }

    render() {
        const {handleSubmit, submitting, location: {state}, submitFailed, error, farm, submitSucceeded, t} = this.props;
        if (!state) return null;
        const {animals} = state;
        return (
            <EventStatusView
                breadcrumbs={[{name: t("mommy"), link: `/${farm}/mobile/events/mommy`}, {name: t("rfidApp.addToMommy")}]}
                handleSubmit={handleSubmit}
                submitting={submitting} submitFailed={submitFailed} error={error} formName={formName}
                status={state.status} eventType={state.eventType} counterName="mommyAdd" submitSucceeded={submitSucceeded}>
                {
                    animals.length === 1 &&
                    <div className="animal-info">
                        {t("rfidApp.animalNumber")}: <span>{animals[0].AnmNo1}</span>
                    </div>
                }
                <Row className="justify-content-center h-100">
                    <Col xs={8} className="d-flex flex-column justify-content-center">
                        <div className="text-center">{t("rfidApp.pigletsAddedToChamber")}:</div>
                        <Field
                            name="amount"
                            component={DataInput}
                            label={t("rfidApp.amount")}
                        />
                    </Col>
                </Row>
            </EventStatusView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation(),
    reduxForm({
        form: formName,
        onSubmit: submit
    })
)(RfidMommyAdd);