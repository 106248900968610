import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTagReader} from "../tag-reader-hoc/withTagReader";
import "./_rfid-scanner.scss"
import RfidHeader from "../rfid-header/RFIDHeader";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import _ from "lodash";
import animalsDB from "../../../database/animalsDB";
import ErrorComponent from "../error/ErrorComponent";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {getLocationID} from "../../../utils/BuildingUtils";
import buildingsDB from "../../../database/buildingsDB";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RfidLocationScanner extends Component {

    state = {
        show: false,
        tag: null,
        error: null
    };

    componentDidMount() {
        const {location: {state}, history, farm} = this.props;
        if (!state) {
            history.push(`/${farm}/mobile`)
        }
    }

    checkAnimalsLocation(location, animals) {
        let locationID = getLocationID(location);
        for (let animal of animals) {
            if (Array.isArray(animal.PlcmntID)) {
                if (animal.PlcmntID.find(item => item.PlcmntID === locationID)) return true;
            }
            if (locationID === animal.PlcmntID) return true;
        }
        return false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(this.props.tagReader, prevProps.tagReader) && this.props.tagReader.tag) {
            const {farm, tagReader: {location, animal}, location: {state}} = this.props;
            const {onlyLocationScanner} = state;
            let loc = location;
            if (!onlyLocationScanner && !loc && animal) {
                let locations = animalsDB.getAnimalLocationsByPlcmntID(animal.PlcmntID);
                console.log(locations);
                if (locations.length > 0) {
                    try {
                        loc = locations[0].location;
                    } catch (e) {
                        loc = null;
                    }
                }
            }
            console.log(loc);
            if (loc) {
                const {eventType, status, locationNextPath, standsChooser, chamberChooser, locationScannerOnlyChambers, blockLocationFromScannedAnimal, animals, sectorTypes} = state;
                let error = false;
                console.log(sectorTypes);
                if (locationScannerOnlyChambers && !loc.CID && !loc.BID) {
                    error = true;
                    this.setState({
                        tag: this.props.tagReader.tag,
                        show: true,
                        error: 4
                    })
                } else if (standsChooser && !loc.BID) {
                    error = true;
                    this.setState({
                        tag: this.props.tagReader.tag,
                        show: true,
                        error: 2
                    })
                } else if (chamberChooser && !loc.CID) {
                    error = true;
                    this.setState({
                        tag: this.props.tagReader.tag,
                        show: true,
                        error: 3
                    })
                } else if (blockLocationFromScannedAnimal && this.checkAnimalsLocation(location, animals)) {
                    error = true;
                    this.setState({
                        tag: this.props.tagReader.tag,
                        show: true,
                        error: 5
                    })
                } else if (sectorTypes && location) {
                    let tree = buildingsDB.getTreeByLocationID(getLocationID(location));
                    if (!tree.sector || !sectorTypes.includes(tree.sector.SType)) {
                        error = true;
                        this.setState({
                            tag: this.props.tagReader.tag,
                            show: true,
                            error: 6
                        })
                    }
                }
                if (!error) {
                    this.props.history.push({
                        pathname: locationNextPath || `/${farm}/mobile/events/${eventType}/${status}`,
                        state: {
                            ...state,
                            location: loc,
                            stand: loc
                        }
                    })
                }
            } else {
                this.setState({
                    tag: this.props.tagReader.tag,
                    show: true,
                    error: 1
                })
            }
        }
    }

    onCloseClick = () => {
        this.setState({
            show: false
        });
        this.props.tagReader.clearTag();
    };

    getError() {
        const {t} = this.props;
        const {error, tag} = this.state;
        switch (error) {
            case 1:
                return t("rfidApp.locationNotFound", {errorTag: tag});
            case 2:
                return t("rfidApp.isNotStand");
            case 3:
                return t("rfidApp.isNotChamber");
            case 4:
                return t("rfidApp.isNotStandOrChamber");
            case 5:
                return t("rfidApp.scannedAnimalInScannedLocation");
            case 6:
                const {location: {state: {sectorTypes}}} = this.props;
                return t("rfidApp.wrongSectorType", {sectors: sectorTypes.map(sector => t("SType." + (sector - 1))).join(", ")});
            default:
                return ""
        }
    }

    render() {
        const {farm, location: {state}, onChange, t} = this.props;
        if (!state) return null;
        let {breadcrumbs, onlyLocationScanner, locationBackPath} = state;
        const {show} = this.state;
        return (
            <div className="rfid-scanner location">
                <RfidHeader breadcrumbs={breadcrumbs} back backPathname={locationBackPath}/>
                <Row className="h-100">
                    <Col xs={6} className="d-flex align-items-center">
                        <Link to={{
                            pathname: `/${farm}/mobile/locationChooser`,
                            state: {
                                ...state,
                                onChange
                            }
                        }} className="rfid-animal-chooser">
                            <h4>{t("rfidApp.chooseLocationFromList")}</h4>
                            <div className="rfid-animal-chooser-button">
                                <div>
                                    <i className="far fa-list-ul fa-fw"/>
                                    <i className="far fa-slash"/>
                                    <i className="far fa-search fa-fw"/>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center">
                        <div className="rfid-scanner-sensor-info">
                            <div className="location-icon">
                                <i className="far fa-map-marked-alt"/>
                                <i className="far fa-wifi"/>
                            </div>
                            {
                                onlyLocationScanner &&
                                <h4>{t("rfidApp.scanLocation")}</h4>
                            }
                            {
                                !onlyLocationScanner &&
                                <h4>{t("rfidApp.scanAnimalOrLocation")}</h4>
                            }
                            <div>{t("rfidApp.readerToTag")}</div>
                        </div>
                    </Col>
                </Row>
                <ErrorComponent show={show} error={this.getError()}
                                onCloseClick={this.onCloseClick} capitalizeFirstLetter={false} type={"warning"}/>
            </div>
        );
    }
}

RfidLocationScanner = withTagReader()(RfidLocationScanner);

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(RfidLocationScanner);