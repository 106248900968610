import {SubmissionError} from "redux-form";
import {getGrIDByAnimal} from "../../../../../utils/EventUtils";
import {castrationTypes} from "../../../../../constans/medicine";
import {convertWeightToBaseUnit} from "../../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../../constans/unitTypes";
import {createCastration} from "../../../../../api/events/Castration";

export function submit(values, dispatch, props) {
    let errors = validate(values, props);
    if (errors._error) {
        throw new SubmissionError(errors);
    }
    const {animals, weight} = values;
    const {location: {state: {selectedTypes}, state}, history, farm, t} = props;
    let data = animals.map(animal => ({
        AnmID: animal.AnmID,
        EvTime: new Date().getTime(),
        GrID: getGrIDByAnimal(animal, new Date().getTime()),
        Castrate: selectedTypes.includes(castrationTypes.CASTRATE),
        Tails: selectedTypes.includes(castrationTypes.TAILS),
        Teeth: selectedTypes.includes(castrationTypes.TEETH),
        Weighting: selectedTypes.includes(castrationTypes.WEIGHTING),
        Weight: weight ? convertWeightToBaseUnit(weight, {fromUnit: UnitTypes.MEDIUM}) : undefined
    }))
    return createCastration(data).then(res => {
        setTimeout(() => {
            history.push({
                pathname: `/${farm}/mobile/scanner`,
                state
            });
        }, 1500)
        dispatch({type: "ADD_CASTRATION", payload: data});
    }).catch(e => {
        throw new SubmissionError({_errors: t("rfidApp.castrationGeneralError")});
    })
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;

    if (values.weight === 0) {
        errors._error = t("rfidApp.weightError", {error: t("errors.lessVal", {count: 1})});
    }

    return errors;
}