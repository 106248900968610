import React, {Component} from 'react';
import {connect} from 'react-redux';
import EventMainView from "../EventMainView";
import MobileAppLink from "../../../../components/basics/mobile-app-link/MobileAppLink";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {SectorType} from "../../../../constans/sectorTypes";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RFIDSellMainView extends Component {
    render() {
        const {farm, t} = this.props;
        return (
            <EventMainView breadcrumbs={[{name: t("sale")}]}>
                <MobileAppLink name={t("animal")} href={`/${farm}/mobile/scanner`} state={{
                    eventType: "sell",
                    status: "animal",
                    breadcrumbs: [{name: t("sale"), link: `/${farm}/mobile/events/sell`}, {name: t("animal")}]
                }}/>
                <MobileAppLink name={t("rfidApp.room")} href={`/${farm}/mobile/locationScanner`} state={{
                    eventType: "sell",
                    status: "location",
                    breadcrumbs: [{name: t("sale"), link: `/${farm}/mobile/events/sell`}, {name: t("rfidApp.room")}],
                    sectorTypes: [SectorType.PIGLET_HOUSE, SectorType.PORK_HOUSE]
                }}/>
            </EventMainView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RFIDSellMainView);