import {SubmissionError} from "redux-form";
import {getGrIDByAnimal} from "../../../../../utils/EventUtils";
import {createParturition} from "../../../../../api/events/Parturition";
import {AnimalTypes} from "../../../../../constans/animalTypes";

export function submit(values, dispatch, props) {
    console.log(values);
    let errors = validate(values, props);
    if (errors._error) {
        throw new SubmissionError(errors);
    }
    const {animals, alive, dead, mummy, temperature, falls, type} = values;
    const {history, farm, t} = props;
    let data = animals.map(animal => ({
        AnmID: animal.AnmID,
        EvTime: new Date().getTime(),
        GrID: getGrIDByAnimal(animal, new Date().getTime()),
        DeadCnt: dead,
        HealthyCnt: alive,
        MummyCnt: mummy,
        Weight: 0, // TODO wymagane w evencie brak na widoku
        Temperature: temperature,
        Falls: falls,
        Type: type
    }));
    return createParturition(data).then(res => {
        setTimeout(() => {
            history.push({
                pathname: `/${farm}/mobile/scanner`,
                state: {
                    eventType: "parturition",
                    status: "status",
                    breadcrumbs: [{
                        name: t("parturition"),
                        link: `/${farm}/mobile/events/parturition`
                    }, {name: t("rfidApp.status")}],
                    animalType: [AnimalTypes.SOW],
                    singleSelect: true
                }
            });
        }, 1500)
        dispatch({type: "ADD_PARTURITION", payload: data});
    }).catch(e => {
        throw new SubmissionError({_errors: t("rfidApp.parturitionGeneralError")});
    })
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;

    if (values.alive === 0) {
        errors._error = t("rfidApp.aliveError", {error: t("errors.lessVal", {count: 1})});
    }

    return errors;
}