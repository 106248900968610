import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import MobileAppLink from "../../../../components/basics/mobile-app-link/MobileAppLink";
import {AnimalTypes} from "../../../../constans/animalTypes";
import EventMainView from "../EventMainView";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RfidSeparationToPiglethouseMainView extends Component {
    render() {
        const {t, farm} = this.props;
        const singleState = {
            animalType: [AnimalTypes.SOW],
            singleSelect: true,
            animalScannerNextPath: `/${farm}/mobile/groupScanner`,
            groupsNextPath: `/${farm}/mobile/events/separationToPigletHouse/location`
        };
        const chamberState = {
            animalType: [AnimalTypes.SOW],
            locationNextPath: `/${farm}/mobile/groupScanner`,
            groupsNextPath: `/${farm}/mobile/events/separationToPigletHouse/location`
        }
        return (
            <EventMainView breadcrumbs={[{name: t("rfidApp.separation")}]}>
                <MobileAppLink name={"Pojedyńczo"} href={`/${farm}/mobile/scanner`} state={{
                    eventType: "separationToPigletHouse",
                    status: "single",
                    breadcrumbs: [{
                        name: t("rfidApp.separation"),
                        link: `/${farm}/mobile/events/separationToPigletHouse`
                    }, {name: "Pojedyńczo", link: {pathname: `/${farm}/mobile/scanner`, state: singleState}}],
                    ...singleState
                }}/>
                <MobileAppLink name={"Komora"} href={`/${farm}/mobile/locationScanner`} state={{
                    eventType: "separationToPigletHouse",
                    status: "chamber",
                    breadcrumbs: [{
                        name: t("rfidApp.separation"),
                        link: `/${farm}/mobile/events/separationToPigletHouse`
                    }, {name: "Komora", link: {pathname: `/${farm}/mobile/locationScanner`, state: chamberState}}],
                    ...chamberState
                }}/>
            </EventMainView>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(RfidSeparationToPiglethouseMainView);