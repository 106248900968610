import React, {Component} from 'react';
import {connect} from 'react-redux';
import EventStatusView from "../../EventStatusView";
import {Field, formValueSelector, reduxForm} from "redux-form";
import CarouselDataInput from "../../../data-input/CarouselDataInput";
import {submit} from "./RFIDGraftingStartSubmit"
import {medicineTypes} from "../../../../../constans/medicine";
import animalsDB from "../../../../../database/animalsDB";
import {Level} from "../../../../../constans/levelTypes";
import {getEventsForAnimals} from "../../../../../actions/eventsActions";
import LoadingComponent from "../../../../../components/loading/LoadingComponent";
import {isEqual} from "lodash";
import {EventTypes} from "../../../../../constans/eventTypes";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {getLocationName} from "../../../../../utils/BuildingUtils";

const formName = "graftingAdd";
const selector = formValueSelector(formName);

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        reasons: state.dictionary.graftingReason.WData[state.language.lang.lang],
        events: state.events.eventsForAnimals,
        eventsLoading: state.events.eventsForAnimalsLoading,
        animals: selector(state, "animals")
    };
}

class RFIDGraftingStart extends Component {

    state = {
        reasons: RFIDGraftingStart.getReasons(this.props),
        loading: true,
        animals: []
    };

    constructor(props) {
        super(props);

        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            let {location, animals} = state;
            console.log(state);
            if (location) {
                animals = animalsDB.getAllAnimalsForLocation(location, this.getLevel(location), {joinEvents: false});
            }
            this.state.animals = animals;
            this.props.dispatch(getEventsForAnimals(animals.map(animal => animal.AnmID)));
            this.props.initialize({
                animals
            })
        }
    }

    static getReasons(props) {
        let array = [];
        const {reasons} = props;
        for (let i = 0; i < reasons.length; i += 3) {
            array.push(reasons.slice(i, i + 3).map(reason => ({
                name: reason.Value,
                value: reason.ID
            })));
        }
        return array;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            reasons: RFIDGraftingStart.getReasons(nextProps)
        }
    }

    getLevel(loc) {
        if (loc.FarmID) return Level.FARM;
        if (loc.BgID) return Level.BUILDING;
        if (loc.SID) return Level.SECTOR;
        if (loc.CID) return Level.CHAMBER;
        if (loc.BID) return Level.BOX;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(this.props.eventsLoading, prevProps.eventsLoading)) {
            const {animals, eventsLoading, events, match: {params: {medicineType}}, location: {state, state: {medicine, location}}, farm} = this.props;
            let loading = false;
            for (let animal of animals) {
                let loadingStatus = eventsLoading.get(animal.AnmID);
                if (loadingStatus) loading = true;
            }
            if (!loading) {
                let unfinished = [];
                for (let animal of animals) {
                    let evs = events.get(animal.AnmID);
                    unfinished = [...unfinished, ...evs.filter(item => item.EvCode ===
                        EventTypes.GRAFTING &&
                        item.EvData.TreatmentType === +medicineType &&
                        item.EvData.Medicine === medicine.WordID &&
                        item.EvData.StartTime &&
                        !item.EvData.EndTime
                    )]
                }
                if (unfinished.length > 0) {
                    this.props.history.push({
                        pathname: `/${farm}/mobile/events/grafting/${medicineType}/unfinished`,
                        state: {
                            ...state,
                            events: unfinished,
                            location
                        }
                    })
                } else {
                    this.setState({
                        loading
                    })
                }
            }
        }
    }

    getName() {
        const {match: {params: {medicineType}}, t} = this.props;
        switch (+medicineType) {
            case medicineTypes.DOSATRON:
                return t("dosatron");
            case medicineTypes.VACCINE:
                return t("grafting");
            case medicineTypes.FORAGE:
                return t("medicalFeed");
            default:
                return "";
        }
    }

    getLink() {
        const {match: {params: {medicineType}}} = this.props;
        switch (+medicineType) {
            case medicineTypes.DOSATRON:
                return "dosatron";
            case medicineTypes.VACCINE:
                return "vaccine";
            case medicineTypes.FORAGE:
                return "forage";
            default:
                return "";
        }
    }

    getBreadcrumbs() {
        const {match: {params: {medicineType}}, location: {state}, farm, t} = this.props;
        const {location, medicine} = state;
        let array = [{name: t("treatment"), link: `/${farm}/mobile/events/grafting`}, {
            name: this.getName(),
            link: `/${farm}/mobile/events/grafting/${this.getLink()}`
        }];
        if (+medicineType !== medicineTypes.VACCINE) {
            array = [...array, {name: getLocationName(location)}];
        } else {
            array = [...array, {name: medicine.WData.Name}];
        }
        return array;
    }

    render() {
        console.log(this.props);
        const {handleSubmit, location: {state}, submitting, submitFailed, error, farm, match: {params: {medicineType}}, submitSucceeded, t} = this.props;
        if (!state) return null;
        const {reasons, loading, animals} = this.state;
        return (
            <EventStatusView handleSubmit={handleSubmit} breadcrumbs={this.getBreadcrumbs()} submitting={submitting}
                             status={state.status} eventType={state.eventType} counterName="grafting"
                             submitFailed={submitFailed} error={error} formName={formName}
                             disabledSubmit={animals.length === 0}
                             additionalState={{
                                 medicine: state.medicine,
                                 breadcrumbs: [{
                                     name: t("treatment"),
                                     link: `/${farm}/mobile/events/grafting`
                                 }, {name: this.getName()}],
                                 locationNextPath: `/${farm}/mobile/medicineChooser`,
                                 medicineType: +medicineType
                             }}
                             timesPath={+medicineType !== medicineTypes.VACCINE ? `/${farm}/mobile/locationScanner` : null}
                             submitSucceeded={submitSucceeded}>
                <LoadingComponent isLoading={loading}/>
                {
                    animals.length === 0 &&
                    <InfoBox boxColor={"error"}>{t("rfidApp.noAnimalsInCurrentLocation")}</InfoBox>
                }
                {
                    animals.length > 0 &&
                    <Field
                        name="reason"
                        component={CarouselDataInput}
                        data={reasons}
                        label={t("rfidApp.chooseReason") + ":"}
                    />
                }
            </EventStatusView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation(),
    reduxForm({
        form: formName,
        onSubmit: submit
    })
)(RFIDGraftingStart);