import React, {Component} from 'react';
import {connect} from 'react-redux';
import LastView from "../../../last-view/LastView";
import {withTagReader} from "../../../tag-reader-hoc/withTagReader";
import {AnimalTypes} from "../../../../../constans/animalTypes";
import {getGrIDByAnimal} from "../../../../../utils/EventUtils";
import {endParturition} from "../../../../../api/events/Parturition";
import {compose} from "redux";
import {Trans, withTranslation} from "react-i18next";
import devicesDB from "../../../../../database/devicesDB";
import {DevType} from "../../../../../constans/devices";

function mapStateToProps(state) {
    return {};
}

class RfidParturitionEnd extends Component {

    state = {
        animals: this.props.location.state ? this.props.location.state.animals : [],
        animalsToSave: [],
        error: null,
        show: false,
        errorTag: null,
        errorAnimal: null
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.tagReader.tag && this.props.tagReader.tag !== prevProps.tagReader.tag) {
            const {tagReader: {animal, tag}} = this.props;
            if (animal) {
                if (!this.state.animals.find(item => item.AnmID === animal.AnmID)) {
                    this.setState({
                        animals: [animal],
                        animalsToSave: this.state.animals,
                        show: false
                    })
                } else {
                    this.setState({
                        show: true,
                        error: 2,
                        errorAnimal: animal
                    })
                }
            } else {
                this.setState({
                    show: true,
                    error: 1,
                    errorTag: tag
                })
            }
        }
    }

    componentDidMount() {
        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        }
    }

    onSubmit = (startTime, clickedButton) => {
        const {animalsToSave, animals} = this.state;
        let anms = clickedButton ? animals : animalsToSave;
        let data = anms.map(animal => {
            let devices = devicesDB.getDevicesInPlcmntID(Array.isArray(animal.PlcmntID) ? animal.PlcmntID[0].PlcmntID : animal.PlcmntID, {showDevicesInChildren: false});
            let nrf = devices.find(item => item.DevType === DevType.DISPENSER_NRF);
            return {
                AnmID: animal.AnmID,
                EvTime: startTime.getTime(),
                GrID: getGrIDByAnimal(animal, startTime.getTime()),
                device: nrf ? {DevID: nrf.DevID, GwID: nrf.GatewayID} : undefined
            }
        });
        return endParturition(data).then(res => {
        }).catch(e => {
            this.setState({
                show: true,
                error: 0
            })
            throw e;
        })
    };

    onCloseClick = () => {
        this.setState({
            show: false
        })
    }

    getError() {
        const {t} = this.props;
        const {error, errorTag, errorAnimal} = this.state;
        switch (error) {
            case 0:
                return t("rfidApp.endParturitionGeneralError");
            case 1:
                return t("rfidApp.animalWithNumberNotFound", {errorTag});
            case 2:
                return t("rfidApp.scannedCurrentlySelected", {AnmNo1: errorAnimal.AnmNo1});
            default:
                return "";
        }
    }

    render() {
        const {location: {state}, farm, t} = this.props;
        if (!state) return null;
        const {status} = state;
        const {animals, show, error} = this.state;
        return (
            <LastView breadcrumbs={[{
                name: t("parturition"),
                link: `/${farm}/mobile/events/parturition`
            }, {name: t("rfidApp.end")}]}
                      eventType={"parturition"}
                      text={<Trans i18nKey="rfidApp.endParturitionText" count={animals.length}
                                   values={{AnmNo1: animals[0].AnmNo1}} components={{bold: <strong/>}}/>}
                      status={status}
                      additionalText={t("rfidApp.endParturitionAdditionalText")}
                      animals={animals} showDate
                      additionalState={{
                          animalType: [AnimalTypes.SOW]
                      }} onSubmit={this.onSubmit} showError={show} error={this.getError()}
                      closeError={this.onCloseClick} errorType={error === 0 ? "error" : "warning"}/>
        );
    }
}

RfidParturitionEnd = withTagReader({
    findAnimalInLocation: true
})(RfidParturitionEnd);

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RfidParturitionEnd);