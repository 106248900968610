import React, {Component} from 'react';
import {connect} from 'react-redux';
import {castrationTypes} from "../../../../../constans/medicine";
import LastView from "../../../last-view/LastView";
import {withTagReader} from "../../../tag-reader-hoc/withTagReader";
import EventStatusView from "../../EventStatusView";
import {Field, reduxForm} from "redux-form";
import {Col, Row} from "react-bootstrap";
import BigDataInput from "../../../data-input/BigDataInput";
import {submit} from "./RFIDGraftingPigletsCastrationSubmit"
import {AnimalTypes} from "../../../../../constans/animalTypes";
import {compose} from "redux";
import {Trans, withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
    };
}

const formName = "graftingPigletsCastrationWeighting";

class RfidGraftingPigletsCastration extends Component {

    state = {
        animals: this.props.location.state ? this.props.location.state.animals : [],
        animalsToSave: []
    };

    constructor(props) {
        super(props);

        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            const {animals} = state;
            this.props.initialize({
                weight: 0,
                animals
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.tagReader.animal && prevState.animals.filter(item => item.AnmID === nextProps.tagReader.animal.AnmID).length === 0) {
            return {
                animals: [nextProps.tagReader.animal],
                animalsToSave: prevState.animals
            }
        }
        return null;
    }

    componentDidMount() {
        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        }
    }

    onSubmit = (startTime, clickedButton) => {
        const {animalsToSave, animals} = this.state;
        let anms = clickedButton ? animals : animalsToSave;
        return submit({animals: anms}, this.props.dispatch, this.props);
    };

    render() {
        const {location: {state}, handleSubmit, submitting, submitFailed, error, submitSucceeded, t} = this.props;
        if (!state) return null;
        const {selectedTypes, breadcrumbs, status} = state;
        const {animals} = this.state;
        if (selectedTypes.includes(castrationTypes.WEIGHTING)) return (
            <EventStatusView breadcrumbs={breadcrumbs} handleSubmit={handleSubmit}
                             submitting={submitting} submitFailed={submitFailed} error={error} formName={formName}
                             status={state.status} eventType={state.eventType}
                             additionalState={{
                                 selectedTypes,
                                 animalType: [AnimalTypes.SOW]
                             }} counterName="graftingPiglets" submitSucceeded={submitSucceeded}>
                <Row className="justify-content-center h-100">
                    <Col xs={6} className="d-flex flex-column justify-content-center align-items-center w-100">
                        <Field
                            name="weight"
                            component={BigDataInput}
                            columns={3}
                            label={t("rfidApp.weightInKilograms") + ":"}
                        />
                    </Col>
                </Row>
            </EventStatusView>
        );
        return (
            <LastView breadcrumbs={breadcrumbs}
                      text={<Trans i18nKey={"rfidApp.castrationText"} count={animals.length}
                                   values={{AnmNo1: animals[0].AnmNo1}} components={{bold: <strong/>}}/>}
                      additionalText={t("rfidApp.castrationAdditionalText", {count: animals.length})}
                      animals={animals} eventType={"graftingPiglets"} status={status}
                      additionalState={{
                          selectedTypes,
                          animalType: [AnimalTypes.SOW]
                      }} onSubmit={this.onSubmit} counterName="castration"/>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTagReader({
        findAnimalInLocation: true
    }),
    withTranslation(),
    reduxForm({
        form: formName,
        onSubmit: submit
    })
)(RfidGraftingPigletsCastration);