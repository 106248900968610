import React, {Component} from 'react';
import {connect} from 'react-redux';
import RfidHeader from "../rfid-header/RFIDHeader";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {withTagReader} from "../tag-reader-hoc/withTagReader";
import _ from "lodash";
import groupsDB from "../../../database/groupsDB";
import ErrorComponent from "../error/ErrorComponent";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RfidGroupScanner extends Component {

    state = {
        show: false,
        tag: null,
        error: null,
        animalError: null
    };

    componentDidMount() {
        const {location: {state}, history, farm} = this.props;
        if (!state) {
            history.push(`/${farm}/mobile`)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.tagReader.tag !== prevProps.tagReader.tag && this.props.tagReader.tag) {
            if (this.props.tagReader.notFoundAnimal && !this.state.show) {
                this.setState({
                    tag: this.props.tagReader.tag,
                    show: true,
                    error: 1
                })
            }
            if (this.props.tagReader.animal && !_.isEqual(this.props.tagReader.animal, prevProps.tagReader.animal)) {
                const {farm, tagReader: {animal}, location: {state}} = this.props;
                let group = groupsDB.getGroupWithAnimal(animal.AnmID, animal.FarmID)[0];
                if (group) {
                    const {eventType, status, groupsNextPath} = state;
                    this.props.history.push({
                        pathname: groupsNextPath || `/${farm}/mobile/events/${eventType}/${status}`,
                        state: {
                            ...state,
                            group
                        }
                    })
                } else {
                    this.setState({
                        animalError: animal,
                        show: true,
                        error: 2
                    })
                }
            }
        }
    }

    onCloseClick = () => {
        this.setState({
            show: false
        });
        this.props.tagReader.clearTag();
    };

    getError() {
        const {t} = this.props;
        const {error, tag, animalError} = this.state;
        switch (error) {
            case 1: return t("rfidApp.animalWithNumberNotFound", {errorTag: tag});
            case 2: return t("rfidApp.animalIsNotInGroup", {number: animalError.AnmNo1});
            default: return "";
        }
    }

    render() {
        const {location: {state}, farm, t} = this.props;
        if (!state) return null;
        const {breadcrumbs, groupsBackPath} = state;
        const {show} = this.state;
        return (
            <div className="rfid-scanner">
                <RfidHeader breadcrumbs={[...breadcrumbs.map(item => ({
                    ...item,
                    link: typeof item.link === "string" ? item.link : {...item.link, state: {...item.state, ...state}}
                })), {name: "Grupa"}]} back backPathname={groupsBackPath}/>
                <Row className="h-100">
                    <Col xs={6} className="d-flex align-items-center">
                        <Link to={{
                            pathname: `/${farm}/mobile/groupChooser`,
                            state
                        }} className="rfid-animal-chooser">
                            <h4>{t("rfidApp.chooseGroupFromList")}</h4>
                            <div className="rfid-animal-chooser-button">
                                <div>
                                    <i className="far fa-list-ul fa-fw"/>
                                    <i className="far fa-slash"/>
                                    <i className="far fa-search fa-fw"/>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center">
                        <div className={"rfid-scanner-sensor-info"}>
                            <i className="far fa-wifi"/>
                            <h4>{t("rfidApp.scanAnimalFromGroup")}</h4>
                            <div>{t("rfidApp.readerToTag")}</div>
                        </div>
                    </Col>
                </Row>
                <ErrorComponent show={show} error={this.getError()}
                                onCloseClick={this.onCloseClick} capitalizeFirstLetter={false}/>
            </div>
        );
    }
}

RfidGroupScanner = withTagReader()(RfidGroupScanner);

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(RfidGroupScanner);