import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import PropTypes from "prop-types"
import RFIDHistoryEvent from "../RFIDHistoryEvent";
import {AnimalTypes} from "../../../../../constans/animalTypes";
import RFIDHistoryScannedComponent from "../RFIDHistoryScannedComponent";

function mapStateToProps(state) {
    return {
        history: state.eventCounter.history
    };
}

class RFIDHistoryInsertion extends Component {

    state = {
        data: this.getData()
    }

    getData() {
        const {history} = this.props;
        let data = [];
        for (let event of history) {
            if (event.type !== "INSERTION") break;
            data = [...data, ...event.data]
        }
        return data.sort((a, b) => b.EvTime - a.EvTime);
    }

    typeFormatter = value => {
        const {t} = this.props;
        switch (value) {
            case AnimalTypes.BOAR:
                return t("animalKind.4");
            case AnimalTypes.PIGLET:
                return t("animalKind.2");
            case AnimalTypes.PORKER:
                return t("animalKind.3");
            case AnimalTypes.SOW:
                return t("animalKind.0");
            case AnimalTypes.RENOVATION_SOW:
                return t("animalKind.5");
            default:
                return "";
        }
    }

    onDataChange = data => {
        this.setState({
            data
        })
    }

    predicateScan = (o, animal, tag) => o.additionalData.RFID === tag

    render() {
        const {t} = this.props;
        const {data} = this.state;
        const headers = [
            {
                name: "",
                field: "scanned",
                headerClassName: "scanned",
                itemClassName: "scanned",
                component: <RFIDHistoryScannedComponent/>
            },
            {
                name: t("animalNumber"),
                field: "AnmNo1"
            },
            {
                name: t("type"),
                field: "AnimalKind",
                valueFormatter: this.typeFormatter
            },
            {
                name: "RFID",
                field: "additionalData.RFID"
            }
        ]
        return (
            <RFIDHistoryEvent data={data} name={t("insertion")} headers={headers} onDataChange={this.onDataChange}
                              predicate={this.predicateScan}/>
        );
    }
}

RFIDHistoryInsertion.propTypes = {
    item: PropTypes.object,
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(RFIDHistoryInsertion);