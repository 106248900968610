import React, {Component} from 'react';
import {connect} from 'react-redux';
import MobileAppLink from "../../../../components/basics/mobile-app-link/MobileAppLink";
import EventMainView from "../EventMainView";
import {AnimalTypes} from "../../../../constans/animalTypes";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RFIDHeatMainView extends Component {
    render() {
        const {farm, t} = this.props;
        return (
            <EventMainView breadcrumbs={[{name: t("heat")}]}>
                <MobileAppLink name={t("toInsemination")} href={`/${farm}/mobile/scanner`} state={{
                    breadcrumbs: [{
                        name: t("heat"),
                        link: `/${farm}/mobile/events/heat`
                    }, {name: t("toInsemination")}],
                    eventType: "heat",
                    status: "toInsemination",
                    animalType: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW]
                }}/>
                <MobileAppLink name={t("heatExamination")} href={`/${farm}/mobile/scanner`} state={{
                    breadcrumbs: [{
                        name: t("heat"),
                        link: `/${farm}/mobile/events/heat`
                    }, {name: t("heatExamination")}],
                    eventType: "heat",
                    status: "heatExamination",
                    animalType: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW]
                }}/>
            </EventMainView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RFIDHeatMainView);