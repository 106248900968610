import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./_feeding-history.scss"
import {getColorForPercentage} from "../../../utils/ColorUtils";

class FeedingHistoryItem extends Component {
    render() {
        const {usage, max, text} = this.props;
        let percent = usage !== null ? +((usage / max) * 100).toFixed(0) : "-";
        return (
            <div className="feeding-history">
                <div className={`feeding-history-indicator ${getColorForPercentage(percent)}`}>
                    {percent}%
                </div>
                <div className="feeding-history-text">{text}</div>
            </div>
        );
    }
}

FeedingHistoryItem.propTypes = {
    usage: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
};

export default FeedingHistoryItem;