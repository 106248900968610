import React, {Component} from 'react';
import {connect} from 'react-redux';
import RFIDHeader from "../rfid-header/RFIDHeader";
import PropTypes from "prop-types";
import Animal from "../../../beans/Animal";
import "./_last-view.scss";
import moment from "moment";
import {Col, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import _ from "lodash";
import Button from "../../../components/basics/button/Button";
import ErrorComponent from "../error/ErrorComponent";
import Loader from "../loader/Loader";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        counters: state.eventCounter
    };
}

class LastView extends Component {

    state = {
        startDate: new Date(),
        submitting: false,
        submitted: false,
        blockButton: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(this.props.animals, prevProps.animals)) {
            if (!this.props.submitOnlyOnButton && !this.props.disableSubmit) {
                this.onSaveClick(null, false);
            }
            this.setState({
                startDate: new Date()
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    getClassName() {
        const {animals, className} = this.props;
        let cName = "last-view";
        if (animals.length === 1) cName += " cropped";
        if (className) cName += " " + className;
        return cName;
    }

    onSaveClick = (e, ret = true) => {
        console.log("onSaveClick");
        const {breadcrumbs, farm, eventType, status, additionalState, onSubmit, submitPath} = this.props;
        const {startDate} = this.state;
        this.setState({
            submitting: true,
            submitted: false
        });
        let promise = onSubmit(startDate, ret);
        Promise.resolve(promise).then(() => {
            if (ret) {
                this.setState({
                    submitted: true,
                    submitting: false,
                    blockButton: true
                }, () => {
                    setTimeout(() => {
                        this.props.history.replace({
                            pathname: submitPath || `/${farm}/mobile/scanner`,
                            state: {
                                breadcrumbs,
                                eventType,
                                status,
                                ...additionalState
                            }
                        })
                    }, 1500)
                })
            } else {
                this.setState({
                    submitted: true,
                    submitting: false
                });
            }
        }).catch(() => {
            this.setState({
                submitting: false
            });
        })
    };

    onCloseErrorClick = () => {
        this.props.closeError();
    }

    render() {
        const {
            text, additionalText, breadcrumbs, showDate, farm, eventType, status, additionalState, counterName,
            counters, closePath, error, showError, errorType, disableSubmit
        } = this.props;
        const {startDate, submitting, submitted, blockButton} = this.state;
        return (
            <div className={this.getClassName()}>
                <RFIDHeader breadcrumbs={breadcrumbs}/>
                <Row className="justify-content-center h-100">
                    <Col md={10} className="d-flex flex-column justify-content-center">
                        <h4 className="main-text">{text}</h4>
                        {
                            showDate &&
                            <h4>{moment(startDate).format("DD.MM.YYYY HH:mm")}</h4>
                        }
                        {
                            additionalText &&
                            <div className="additional-text">
                                <i className="far fa-wifi"/>
                                <small>{additionalText}</small>
                            </div>
                        }
                    </Col>
                </Row>
                <Link className="last-view-close" to={{
                    pathname: closePath || `/${farm}/mobile/scanner`,
                    state: {
                        breadcrumbs,
                        eventType,
                        status,
                        ...additionalState
                    }
                }} replace>
                    <i className="far fa-times-circle"/>
                </Link>
                <Button className="last-view-save" buttonStyle={"text"}
                        disabled={submitting || blockButton || disableSubmit}
                        onClick={this.onSaveClick}>
                    <Loader loading={submitting} success={submitted}/>
                </Button>
                {
                    counterName &&
                    <div className="last-view-counter">
                        <i className="fa fa-wifi fa-fw"/> {counters[counterName]}
                    </div>
                }
                <ErrorComponent onCloseClick={this.onCloseErrorClick} error={error} show={showError} type={errorType}
                                capitalizeFirstLetter={false}/>
            </div>
        );
    }
}

LastView.propTypes = {
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        link: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    })).isRequired,
    animals: PropTypes.arrayOf(PropTypes.instanceOf(Animal)).isRequired,
    text: PropTypes.node.isRequired,
    additionalText: PropTypes.node,
    eventType: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    showDate: PropTypes.bool,
    additionalState: PropTypes.object,
    className: PropTypes.string,
    submitting: PropTypes.bool,
    onSubmit: PropTypes.func,
    submitOnlyOnButton: PropTypes.bool,
    counterName: PropTypes.string,
    submitPath: PropTypes.string,
    closePath: PropTypes.string,
    error: PropTypes.string,
    showError: PropTypes.bool,
    closeError: PropTypes.func,
    errorType: PropTypes.string,
    disableSubmit: PropTypes.bool,
};

LastView.defaultProps = {
    additionalState: {}
};

LastView = withRouter(LastView);

export default connect(
    mapStateToProps,
)(LastView);