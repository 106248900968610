import devicesDB from "../../../../database/devicesDB";
import buildingsDB from "../../../../database/buildingsDB";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import {createTransfer} from "../../../../api/events/Transfer";
import {SubmissionError} from "redux-form";
import animalsDB from "../../../../database/animalsDB";
import {prepareFeeding} from "../../../../utils/FeedingUtils";
import {DevType} from "../../../../constans/devices";

function getAnimalInStandData(stand, devs, time) {
    let currentAnimal = animalsDB.getAllAnimalsByPlcmntID(stand.BID, {joinEvents: false})[0];
    if (currentAnimal) {
        let tree = buildingsDB.getTreeByLocationID(stand.BID);
        let devicesNewPlcmnt = devicesDB.getDevicesInPlcmnt(tree.chamber, {showDevicesInChildren: false});
        return {
            AnmID: currentAnimal.AnmID,
            transferTo: tree.chamber.CID,
            DevIDsNewPlcmnt: devicesNewPlcmnt.map(dev => ({
                DevID: dev.DevID,
                GwID: dev.GatewayID
            })),
            DevIDsOldPlcmnt: devs,
            GrID: getGrIDByAnimal(currentAnimal, time)
        }
    }
    return undefined;
}

export function submit(values, dispatch, props) {
    const {animals, stand, curve} = values;
    const {history, farm, curves, t} = props;
    let devicesInNewPlcmnt = devicesDB.getDevicesInPlcmnt(stand, {showDevicesInChildren: false}).map(dev => ({
        DevID: dev.DevID,
        GwID: dev.GatewayID
    }));
    let feeding;
    if (curve !== undefined) {
        let c = curves[curve];
        let dataNRF = prepareFeeding({...values, curve: c}, DevType.DISPENSER_NRF);
        feeding = {
            curveNumber: dataNRF.curveNr,
            doseCorrection: dataNRF.percentCorrection,
            curveStart: dataNRF.startTime,
            punishment: dataNRF.punishment,
            stage: dataNRF.stage
        }
    }
    let data = animals.map(animal => {
        let currentLocation = Array.isArray(animal.PlcmntID) ? animal.PlcmntID[0].PlcmntID : animal.PlcmntID;
        let location = buildingsDB.getLocationByID(currentLocation);
        let oldDevices = devicesDB.getDevicesInPlcmnt(location, {showDevicesInChildren: false}).map(dev => ({
            DevID: dev.DevID,
            GwID: dev.GatewayID
        }));
        return {
            AnmID: animal.AnmID,
            transferTo: stand.BID,
            from: Array.isArray(animal.PlcmntID) ? animal.PlcmntID[0].PlcmntID : animal.PlcmntID,
            amount: 1,
            stand: true,
            DevIDsNewPlcmnt: devicesInNewPlcmnt,
            DevIDsOldPlcmnt: oldDevices,
            GrID: getGrIDByAnimal(animal, new Date().getTime()),
            EvTime: new Date().getTime(),
            clearFeeding: true,
            animalInStand: getAnimalInStandData(stand, devicesInNewPlcmnt, new Date().getTime()),
            feeding
        }
    });
    return createTransfer(data).then(res => {
        setTimeout(() => {
            history.push({
                pathname: `/${farm}/mobile/scanner`,
                state: {
                    eventType: "transfer",
                    status: "stand",
                    breadcrumbs: [{
                        name: t("transfer"),
                        link: `/${farm}/mobile/events/transfer`
                    }, {name: t("standing")}],
                    onlyLocationScanner: true,
                    standsChooser: true,
                    onlyAnimalScanner: true,
                    animalScannerNextPath: `/${farm}/mobile/locationScanner`,
                    singleSelect: true
                }
            });
        }, 1500)
        dispatch({type: "ADD_TRANSFER", payload: data});
    }).catch(e => {
        throw new SubmissionError({_errors: t("transferFailure")});
    })
}