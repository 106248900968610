import React, {Component} from 'react';
import {connect} from 'react-redux';
import EventMainView from "../EventMainView";
import MobileAppLink from "../../../../components/basics/mobile-app-link/MobileAppLink";
import {medicineTypes} from "../../../../constans/medicine";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RFIDGraftingMainViewDosatron extends Component {
    render() {
        const {farm, t} = this.props;
        return (
            <EventMainView
                breadcrumbs={[{name: t("treatment"), link: `/${farm}/mobile/events/grafting`}, {name: t("dosatron")}]}
                additionalData={
                    <>
                        <MobileAppLink name={t("rfidApp.start")} href={`/${farm}/mobile/locationScanner`} state={{
                            eventType: "grafting",
                            status: `${medicineTypes.DOSATRON}/add`,
                            breadcrumbs: [{
                                name: t("treatment"),
                                link: `/${farm}/mobile/events/grafting`
                            }, {name: t("dosatron")}],
                            medicineType: medicineTypes.DOSATRON,
                            locationNextPath: `/${farm}/mobile/medicineChooser`,
                            locationScannerOnlyChambers: true
                        }}/>
                        <MobileAppLink name={t("rfidApp.end")} href={`/${farm}/mobile/locationScanner`} state={{
                            eventType: "grafting",
                            status: `${medicineTypes.DOSATRON}/end`,
                            breadcrumbs: [{
                                name: t("treatment"),
                                link: `/${farm}/mobile/events/grafting`
                            }, {name: t("dosatron")}],
                            medicineType: medicineTypes.DOSATRON,
                            locationScannerOnlyChambers: true
                        }}/>
                    </>
                }>
                <MobileAppLink name={t("dosatron")} href={`/${farm}/mobile/events/grafting/dosatron`} selected/>
                <MobileAppLink name={t("grafting")} href={`/${farm}/mobile/events/grafting/vaccine`}/>
                <MobileAppLink name={t("medicalFeed")} href={`/${farm}/mobile/events/grafting/forage`}/>
            </EventMainView>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(RFIDGraftingMainViewDosatron);