import React, {Component} from 'react';
import {connect} from 'react-redux';
import {medicineTypes} from "../../../../../constans/medicine";
import {Field, formValueSelector, reduxForm} from "redux-form";
import EventStatusView from "../../EventStatusView";
import animalsDB from "../../../../../database/animalsDB";
import {Level} from "../../../../../constans/levelTypes";
import {submit} from "./RFIDGraftingEndSubmit";
import {getEventsForAnimals} from "../../../../../actions/eventsActions";
import LoadingComponent from "../../../../../components/loading/LoadingComponent";
import {isEqual} from "lodash";
import {EventTypes} from "../../../../../constans/eventTypes";
import CarouselDataInput from "../../../data-input/CarouselDataInput";
import moment from "moment";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

const formName = "graftingEnd";
const selector = formValueSelector(formName);

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        events: state.events.eventsForAnimals,
        eventsLoading: state.events.eventsForAnimalsLoading,
        animals: selector(state, "animals"),
        medicines: state.dictionary.medicine,
    };
}

class RFIDGraftingEnd extends Component {

    state = {
        loading: true,
        graftings: []
    }

    constructor(props) {
        super(props);

        const {location: {state}, farm} = this.props;
        if (!state) {
            this.props.history.push(`/${farm}/mobile`);
        } else {
            let {location, animals} = state;
            console.log(state);
            if (location) {
                animals = animalsDB.getAllAnimalsForLocation(location, Level.CHAMBER, {joinEvents: false});
            }
            this.props.dispatch(getEventsForAnimals(animals.map(animal => animal.AnmID)));
            this.props.initialize({
                animals
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(this.props.eventsLoading, prevProps.eventsLoading)) {
            const {animals, eventsLoading, events, match: {params: {medicineType}}, medicines} = this.props;
            let loading = false;
            for (let animal of animals) {
                let loadingStatus = eventsLoading.get(animal.AnmID);
                if (loadingStatus) loading = true;
            }
            if (!loading) {
                let graftings = new Map();
                for (let animal of animals) {
                    let evs = events.get(animal.AnmID);
                    let unfinished = evs.filter(item => item.EvCode ===
                        EventTypes.GRAFTING &&
                        item.EvData.TreatmentType === +medicineType &&
                        item.EvData.StartTime &&
                        !item.EvData.EndTime
                    );
                    for (let event of unfinished) {
                        console.log(event);
                        let data = graftings.get(event.EvData.Medicine + event.EvData.StartTime) || {
                            EvID: [],
                            StartTime: event.EvData.StartTime,
                            Medicine: event.EvData.Medicine
                        };
                        data.EvID.push(event.EvID);
                        graftings.set(event.EvData.Medicine + event.EvData.StartTime, data);
                    }
                }
                let array = [];
                let tmp = [...graftings.values()];
                for (let i = 0; i < tmp.length; i += 3) {
                    array.push(tmp.slice(i, i + 3).map(data => {
                        let medicine = medicines.find(item => item.WordID === data.Medicine);
                        return {
                            name: `${medicine ? medicine.WData.Name : "?"} - ${moment(data.StartTime).format("DD.MM.YYYY")}`,
                            value: data
                        }
                    }));
                }
                this.setState({
                    loading,
                    graftings: array
                })
            }
        }
    }

    getName() {
        const {match: {params: {medicineType}}, t} = this.props;
        switch (+medicineType) {
            case medicineTypes.DOSATRON:
                return t("dosatron");
            case medicineTypes.FORAGE:
                return t("medicalFeed");
            default:
                return "";
        }
    }

    getLink() {
        const {match: {params: {medicineType}}} = this.props;
        switch (+medicineType) {
            case medicineTypes.DOSATRON:
                return "dosatron";
            case medicineTypes.FORAGE:
                return "forage";
            default:
                return "";
        }
    }

    getBreadcrumbs() {
        const {location: {state}, farm, t} = this.props;
        const {location} = state;
        let array = [{name: t("treatment"), link: `/${farm}/mobile/events/grafting`}, {
            name: this.getName(),
            link: `/${farm}/mobile/events/grafting/${this.getLink()}`
        }, {name: location.CName}];
        return array;
    }

    render() {
        const {handleSubmit, location: {state}, submitting, submitFailed, error, farm, match: {params: {medicineType}}, submitSucceeded, t} = this.props;
        if (!state) return null;
        const {loading, graftings} = this.state;
        return (
            <EventStatusView handleSubmit={handleSubmit} breadcrumbs={this.getBreadcrumbs()} submitting={submitting}
                             status={state.status} eventType={state.eventType} counterName="grafting"
                             submitFailed={submitFailed} error={error} formName={formName}
                             additionalState={{
                                 breadcrumbs: [{
                                     name: t("treatment"),
                                     link: `/${farm}/mobile/events/grafting`
                                 }, {name: this.getName()}],
                                 medicineType: +medicineType
                             }}
                             timesPath={`/${farm}/mobile/locationScanner`} submitSucceeded={submitSucceeded}
                             disabledSubmit={graftings.length === 0}>
                <LoadingComponent isLoading={loading}/>
                {
                    graftings.length === 0 &&
                    <InfoBox boxColor={"warning"}>{t("rfidApp.noStartedTreatments")}</InfoBox>
                }
                {
                    graftings.length > 0 &&
                    <Field
                        name="grafting"
                        component={CarouselDataInput}
                        data={graftings}
                    />
                }
            </EventStatusView>
        )
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation(),
    reduxForm({
        form: formName,
        onSubmit: submit
    })
)(RFIDGraftingEnd);
