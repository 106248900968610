import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import RfidFeedingModal from "./RFIDFeedingModal";
import DataInput from "../../data-input/DataInput";
import {convertWeightUnitTo} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import {DevType} from "../../../../constans/devices";
import {setForceFeeding as setForceFeedingNRF} from "../../../../IOT/device-functions/DispenserNRFFunctions";
import {setForceFeedings as setForceFeedingWST} from "../../../../IOT/device-functions/DispenserDriverFunctions";
import {isUsingFakeData} from "../../../../utils/SettingsUtils";
import sinon from "sinon";
import NewIOT from "../../../../IOT/NewIOT"
import {fakeCreateAndSendMessageObject} from "../../../../demo/feeding/fakeFunctions";
import {withTranslation} from "react-i18next";

class RfidAdditionalFeeding extends Component {

    state = {
        value: 100
    }

    constructor(props) {
        super(props);

        if (isUsingFakeData()) {
            sinon.stub(NewIOT, "createAndSendMessageObject").callsFake(fakeCreateAndSendMessageObject);
        }
    }

    componentWillUnmount() {
        if (isUsingFakeData()) {
            sinon.restore();
        }
    }

    onChange = value => {
        this.setState({
            value
        })
    }

    sendData(data, device) {
        return new Promise((resolve, reject) => {
            if (device.DevType === DevType.DISPENSER) {
                setForceFeedingWST(device, data, resolve, reject, true)
            } else {
                setForceFeedingNRF(device, data, resolve, reject, true)
            }
        })
    }

    onSubmitClick = () => {
        const {value} = this.state;
        const {devices, locationID} = this.props;
        let data = {dose: value, type: 0};
        let device = devices[0];
        if (device.DevType === DevType.DISPENSER) {
            let plcmnt = device.PlcmntID.find(item => item.PlcmntID === locationID);
            data = {...data, number: plcmnt.Adr + 1};
        }
        return this.sendData(data, device);
    }

    render() {
        const {onCloseClick, onSuccess, t} = this.props;
        const {value} = this.state;
        return (
            <RfidFeedingModal breadcrumbs={[{name: t("extraFeeding")}]} onCloseClick={onCloseClick}
                              onSubmit={this.onSubmitClick} onSuccess={onSuccess}>
                <Row className="justify-content-center h-100">
                    <Col xs={8} className="d-flex flex-column justify-content-center">
                        <DataInput
                            label={t("forageAmount")}
                            input={{
                                onChange: this.onChange,
                                value
                            }}
                            min={100}
                            max={2500}
                            step={100}
                            valueFormatter={val => convertWeightUnitTo(val, {
                                unit: UnitTypes.MEDIUM,
                                showUnit: true,
                                fixed: 1
                            })}
                        />
                    </Col>
                </Row>
            </RfidFeedingModal>
        );
    }
}

export default withTranslation()(RfidAdditionalFeeding);