import React, {Component} from 'react';
import {connect} from 'react-redux';
import "./_rfid-scanner-left-bar.scss"
import PropTypes from "prop-types";
import Button from "../../../components/basics/button/Button";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        hasAccess: state.view.showBreeding,
    };
}

class RfidScannerLeftBar extends Component {

    state = {
        opened: false
    };
    container = React.createRef();

    componentDidMount() {
        document.addEventListener('mousedown', this.onOutsideClick)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.onOutsideClick);
    }

    onOutsideClick = e => {
        const {opened} = this.state;
        if (opened && !this.container.current.contains(e.target)) {
            this.onOpenMenuClick();
        }
    };

    onOpenMenuClick = () => {
        this.setState(state => ({
            opened: !state.opened
        }), () => {
            this.props.onOpenedChange(this.state.opened);
        });
    };

    onLogoutClick = () => {
        const {farm, history, dispatch} = this.props;
        dispatch({type: "SHOW_NAVIGATION"});
        history.push(`/${farm}/farm/general`);
        let body = document.getElementsByTagName("body")[0];
        body.classList.remove("rfid-app");
    }

    onHistoryClick = () => {
        const {farm, history} = this.props;
        history.push(`/${farm}/mobile/history`);
    }

    render() {
        const {opened} = this.state;
        const {onEditClick, t} = this.props;
        return (
            <div className={opened ? "rfid-scanner-left-bar opened" : "rfid-scanner-left-bar"} ref={this.container}>
                <div className="left-bar-header">
                    <i className="far fa-bars" onClick={this.onOpenMenuClick}/>
                </div>
                <div className="left-bar-container">
                    <div className="left-bar-icons">
                        {/*<div className="left-bar-item">*/}
                        {/*    <i className="far fa-pig fa-fw"/>*/}
                        {/*</div>*/}
                        {/*<div className="left-bar-item">*/}
                        {/*    <i className="far fa-bell fa-fw"/>*/}
                        {/*</div>*/}
                        {/*<div className="left-bar-item">*/}
                        {/*    <i className="far fa-comment fa-fw"/>*/}
                        {/*</div>*/}
                        {/*<div className="left-bar-item">*/}
                        {/*    <i className="far fa-calendar fa-fw"/>*/}
                        {/*</div>*/}
                        <div className="left-bar-item" onClick={this.onHistoryClick}>
                            <i className="far fa-history fa-fw"/>
                        </div>
                        <div className="left-bar-item" onClick={onEditClick}>
                            <i className="far fa-cog fa-fw"/>
                        </div>
                    </div>
                    <div className="left-bar-opened-info">
                        <Button buttonStyle={"text"} className="logout" icon={<i className="far fa-power-off"/>}
                                onClick={this.onLogoutClick}/>
                        {/*<div className="left-bar-item">*/}
                        {/*    <div>Akcje</div>*/}
                        {/*</div>*/}
                        {/*<div className="left-bar-item">*/}
                        {/*    <div>Alarmy</div>*/}
                        {/*</div>*/}
                        {/*<div className="left-bar-item">*/}
                        {/*    <div>Komunikator</div>*/}
                        {/*</div>*/}
                        {/*<div className="left-bar-item">*/}
                        {/*    <div>Lista zadań</div>*/}
                        {/*</div>*/}
                        <div className="left-bar-item" onClick={this.onHistoryClick}>
                            <div>{t("history")}</div>
                        </div>
                        <div className="left-bar-item" onClick={onEditClick}>
                            <div>{t("rfidApp.editMainView")}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

RfidScannerLeftBar.propTypes = {
    onOpenedChange: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired
};

export default compose(
    connect(mapStateToProps),
    withRouter,
    withTranslation()
)(RfidScannerLeftBar);
