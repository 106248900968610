import React, {Component} from 'react';
import PropTypes from 'prop-types';
import RfidFeedingModal from "./RFIDFeedingModal";
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import Device from "../../../../beans/Device";
import "../../choosers/_animal-chooser.scss"
import {DevType} from "../../../../constans/devices";
import {setSkipDoses as setSkipDosesNRF} from "../../../../IOT/device-functions/DispenserNRFFunctions";
import {setSkipDoses as setSkipDosesWST} from "../../../../IOT/device-functions/DispenserDriverFunctions";
import {Col, Row} from "react-bootstrap";
import Checkbox from "../../../../components/basics/checkbox/Checkbox";
import moment from "moment";
import {isUsingFakeData} from "../../../../utils/SettingsUtils";

function mapStateToProps(state) {
    return {};
}

class RFIDChangeCorrection extends Component {

    state = {
        value: this.props.skipDoses
    }

    sendData(data, device) {
        return new Promise((resolve, reject) => {
            if (device.DevType === DevType.DISPENSER) {
                setSkipDosesWST(device, data, resolve, reject, true)
            } else {
                setSkipDosesNRF(device, data, resolve, reject, true)
            }
        })
    }

    onSubmitClick = () => {
        const {value} = this.state;
        const {devices, locationID} = this.props;
        let device = devices[0];
        let data;
        if (device.DevType === DevType.DISPENSER) {
            let plcmnt = device.PlcmntID.find(item => item.PlcmntID === locationID);
            data = [{dosesToSkip: value, number: plcmnt.Adr + 1}];
        } else {
            data = {dosesToSkip: value};
        }
        if (isUsingFakeData()) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                    this.props.onSuccess({
                        skipDoses: value
                    });
                }, 1500)
            })
        }
        return this.sendData(data, device);
    }

    formatDose(dose) {
        return moment(dose.Start).format("HH:mm") + " - " + moment(dose.Stop).format("HH:mm");
    }

    onCheckboxClick(index, checked) {
        const value = this.state.value.slice(0);
        value[index] = +checked;
        this.setState({
            value
        })
    }

    render() {
        const {onCloseClick, onSuccess, schedule, t} = this.props;
        const {value} = this.state;
        return (
            <RfidFeedingModal breadcrumbs={[{name: t("modals.feedingSkipDosesModal.titleSkipDoses")}]}
                              onCloseClick={onCloseClick}
                              onSubmit={this.onSubmitClick} onSuccess={onSuccess}>
                <div className="d-flex h-100 align-items-center skip-doses">
                    <Row className="justify-content-center w-100">
                        {
                            schedule.SetData.Doses.map((dose, index) =>
                                <Col xs={4} key={index}>
                                    <Checkbox label={this.formatDose(dose)} id={index} checked={!!value[index]}
                                              onChange={val => this.onCheckboxClick(index, val)}/>
                                </Col>
                            )
                        }
                    </Row>
                </div>
            </RfidFeedingModal>
        );
    }
}

RFIDChangeCorrection.propTypes = {
    devices: PropTypes.arrayOf(PropTypes.instanceOf(Device)),
    onCloseClick: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    schedule: PropTypes.object,
    locationID: PropTypes.string,
    skipDoses: PropTypes.arrayOf(PropTypes.number),
};

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(RFIDChangeCorrection);